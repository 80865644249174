import { GET_USER, LOGIN, LOGOUT } from "../ActionsTypes";

const initialState = {
  isConnected: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        isConnected: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        isConnected: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case LOGIN.FAILED:
      return {
        ...state,

        error: action.payload,
        isLoading: false,
        isError: true,
        isLogout: false,
      };
    default:
      return { ...state };
  }
};

export const LogoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT.REQUEST:
      return {
        ...state,
        isConnected: null,
        error: null,
        isLoading: true,
        isError: false,
        isLogout: false,
      };
    case LOGOUT.SUCCESS:
      return {
        ...state,

        isLogout: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case LOGOUT.FAILED:
      return {
        ...state,
        isLogout: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};

const userInitialState = {
  user: null,
  error: null,
  isLoading: false,
  isError: false,
};
export function GetUserReducer(state = userInitialState, action) {
  switch (action.type) {
    case GET_USER.REQUEST:
      return {
        ...state,
        user: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case GET_USER.SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case GET_USER.FAILED:
      return {
        ...state,
        user: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
}
