import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { authentication } from "../../utils/redux/action_creator/authenticationlogin-creators";
import { Typography, Button, Stack } from "@mui/material";

const HomePage = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { authState, oktaAuth } = useOktaAuth();
    const { user, error, isLoading, isError } = useSelector((state) => state.GetUserReducer);

    useEffect(() => {
        dispatch(authentication(authState));
    }, []);

    const refreshPage = () => {
        navigate("/");
        window.location.reload();
    };

    if (user != null) navigate("/dashboard");
    if (isError)
        return (
            <Stack spacing={2} direction={"column"} alignItems={"center"} textAlign={"center"}>
                <Typography variant="h6">System Down!</Typography>
                <Typography variant="body1">We apologize for the inconvenience.</Typography>
                <Button
                    onClick={refreshPage}
                    color="primary"
                    variant="outlined"
                    style={{
                        borderColor: "#bfbfbf",
                    }}
                >
                    Refresh
                </Button>
            </Stack>
        );
    return <div>Loading...</div>;
};

export default HomePage;
