import React, { useEffect, useState } from "react";
import "react-funnel-pipeline/dist/index.css";
import Widget from "../../Widget/Widget";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Grid, CircularProgress } from "@mui/material";
import Select from "react-select";

export default function OppStatusBreakdown(props) {
    const { FullData } = props;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isDropDownLoading, setIsDropDownLoading] = useState(true);
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [dropDownSelectedOption, setDropDownSelectedOption] = useState(null);
    const [YAxisValues, setYAxisValues] = useState([]);

    useEffect(() => {
        if (FullData === null || FullData === undefined) {
            setData([]);
            return;
        }
        setIsDropDownLoading(false);
        // fetch drop down options
        let dropDownOptions = [];
        for (const [key, value] of Object.entries(FullData.metrics)) {
            dropDownOptions.push({
                value: key,
                label: key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
            });
        }
        // sort dropDownOptions alphabetically based on the label but bring "Probability of Conversion" to the top
        dropDownOptions.sort((a, b) => (a.label === "Probability of Conversion" ? -1 : b.label === "Probability of Conversion" ? 1 : a.label.localeCompare(b.label)));

        let processedData = {};
        for (const [key, value] of Object.entries(FullData.metrics)) {
            dropDownOptions.push({
                value: key,
                label: key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
            });
            if (key !== "probability_of_conversion") {
                let dataMetric = [];
                for (const [innerKey, innerValue] of Object.entries(FullData.metrics[key])) {
                    dataMetric.push({
                        name: innerKey.replace("Approved", "").replaceAll("_", " "),
                        value: innerValue,
                    });
                }

                dataMetric.sort((a, b) => {
                    if (a.name === "Prospecting") {
                        return -1;
                    }
                    if (a.name === "Qualified") {
                        return b.name === "Prospecting" ? 1 : -1;
                    }
                    if (a.name === "Approved_C_Code") {
                        return b.name === "Approved_C_Code" ? 0 : 1;
                    }
                    if (a.name === "Approved_Investment") {
                        return b.name === "Approved_Investment" ? 0 : 1;
                    }
                });
                processedData[key] = dataMetric;
            } else {
                // Special handling for 'probability_of_conversion'
                let probConversionData = { High: 0, Medium: 0, Low: 0, Closed: 0 };
                for (const [status, probValues] of Object.entries(value)) {
                    for (const [probKey, probValue] of Object.entries(probValues)) {
                        probConversionData[probKey] += probValue;
                    }
                }
                processedData[key] = [probConversionData];
            }
        }
        // Sort dropDownOptions and add 'Probability of Conversion' to the top if it exists
        const probConversionIndex = dropDownOptions.findIndex((opt) => opt.value === "probability_of_conversion");
        if (probConversionIndex !== -1) {
            const [probConversionOption] = dropDownOptions.splice(probConversionIndex, 1);
            dropDownOptions.unshift(probConversionOption);
        }
        setDropDownOptions(dropDownOptions);
        setDropDownSelectedOption(dropDownOptions[0]);
        setData(processedData);
    }, [FullData]);

    const onSelectUpdated = (selectedOption) => {
        setDropDownSelectedOption(selectedOption);
        setYAxisValues(data[dropDownSelectedOption?.value]);
    };

    return (
        <Widget
            title="Opportunity Status Breakdown"
            upperTitle
            noBodyPadding
            infoText={
                "This graph shows the breakdown of opportunities by status. The data is generated from the data present in this platform only. The total amount has been computed by multiplying an opportunitt's number of weeks by the opportunity's weekly rate."
            }
            style={{ width: "100%", height: "100%" }}
            opportunities={FullData?.opportunities}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Select
                        options={dropDownOptions}
                        placeholder={"Metric"}
                        value={dropDownSelectedOption}
                        onChange={onSelectUpdated}
                        isLoading={isDropDownLoading}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                width: "100%",
                                minWidth: "200px",
                            }),
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "500px",
                        width: "500px",
                    }}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        {!isDropDownLoading ? (
                            dropDownSelectedOption?.value !== "probability_of_conversion" ? (
                                <BarChart
                                    data={data[dropDownSelectedOption?.value]}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    barSize={20}
                                >
                                    <XAxis dataKey="name" scale="point" padding={{ left: 60, right: 20, top: 20 }} />
                                    <YAxis
                                        label={{
                                            value: dropDownSelectedOption?.label,
                                            angle: -90,
                                            position: "center",
                                            dx: -30,
                                        }}
                                        tickInterval={1}
                                        domain={
                                            data[dropDownSelectedOption?.value]?.length > 0
                                                ? [0, Math.ceil(Math.max(...data[dropDownSelectedOption?.value].map((d) => d.value)))]
                                                : [0, 0]
                                        }
                                        // ticks={
                                        //   data[dropDownSelectedOption?.value] &&
                                        //   data[dropDownSelectedOption?.value].length > 0
                                        //     ? Array.from(
                                        //         {
                                        //           length:
                                        //             Math.ceil(
                                        //               Math.max(
                                        //                 ...data[dropDownSelectedOption?.value].map(
                                        //                   (d) => d.value
                                        //                 )
                                        //               )
                                        //             ) + 2,
                                        //         },
                                        //         (_, i) => i
                                        //       )
                                        //     : []
                                        // }
                                    />

                                    <Tooltip />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Bar dataKey="value" fill="#457B3B" background={{ fill: "#eee" }} radius={[20, 20, 0, 0]} barSize={30} />
                                </BarChart>
                            ) : (
                                <BarChart
                                    data={
                                        data[dropDownSelectedOption?.value] && data[dropDownSelectedOption?.value][0]
                                            ? Object.keys(data[dropDownSelectedOption?.value][0]).map((prob) => ({
                                                  name: prob,
                                                  value: data[dropDownSelectedOption?.value][0][prob],
                                              }))
                                            : []
                                    }
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    barSize={20}
                                >
                                    <XAxis dataKey="name" scale="point" padding={{ left: 60, right: 20, top: 20 }} />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Bar dataKey="value" fill="#457B3B" background={{ fill: "#eee" }} radius={[20, 20, 0, 0]} barSize={30} />
                                </BarChart>
                            )
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Widget>
    );
}
