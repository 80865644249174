import React, { useEffect, useState, useContext } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@mui/material/IconButton";
import { Box, Collapse, Typography, Button } from "@mui/material";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import PropTypes from "prop-types";

import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { CustomTableCell } from "./CustomTableCell";
import { LoadingPanel } from "../../components/loading-panel";
import TableUpdateContext from "../../utils/context/table-update-context";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

RowComponent.propTypes = {
  onDoubleClickOnRow: PropTypes.func,
};

export default function RowComponent(props) {
  const {
    rowData,
    tableColumns,
    detailsFields,
    isLoading,
    isModified,
    detailsExist,
    //------for-recursion----------//
    otherDetailsData,
    otherDetailsTitle,
    otherDetailsFields,
    otherDetailsExist,

    //----------details compoent-------//
    ComponentDetails,
    onDoubleClickOnRow,
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [previous, setPrevious] = useState({});
  const [row, setRow] = useState({ isEditMode: false });
  const tableUpdateContext = useContext(TableUpdateContext);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [anchorElDetailMenu, setAnchorElDetailMenu] = React.useState(null);
  const openDetailMenu = Boolean(anchorElDetailMenu);
  const handleClickDetailMenu = (event) => {
    setAnchorElDetailMenu(event.currentTarget);
  };
  const handleCloseDetailMenu = () => {
    setAnchorElDetailMenu(null);
  };

  const onToggleEditMode = (id) => {
    setIsEditModeActive(!isEditModeActive);
    setRow((state) => {
      return { ...row, isEditMode: !row.isEditMode };
    });
    if (Object.keys(previous).length !== 0) {
      setPrevious({});
      tableUpdateContext.updateTable(row);
    }
    setOpen(detailsExist);
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const newRows = { ...row, [name]: value };
    setRow(newRows);
  };

  const onRevert = (id) => {
    const newRows = previous[id] ? previous[id] : row;
    setRow(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
    setIsEditModeActive(false);
    setAnchorElDetailMenu(null);
  };
  const handleDoubleClick = () => {
    setOpen(!open && detailsExist);
  };

  useEffect(() => {
    setRow({ ...rowData, isEditMode: false });
  }, []);

  if (isLoading) {
    return <LoadingPanel />;
  }

  const handlewOnClickOnCell = () => {
    if (!isEditModeActive) {
      if (onDoubleClickOnRow != null && !open) props.onDoubleClickOnRow(rowData);
      return handleDoubleClick();
    }
  };
  return (
    <React.Fragment>
      <TableRow>
        {isModified && (
          <TableCell
            style={{
              //      height: 70,
              display: "flex",
              alignItems: "center",

              borderBottom: "none",
              width: 40,
            }}
          >
            <div>
              {row.isEditMode ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <DoneIcon
                    size="small"
                    onClick={() => {
                      onToggleEditMode(rowData.id);
                      setAnchorElDetailMenu(null);
                    }}
                  />

                  <RevertIcon size="small" onClick={() => onRevert(rowData.id)} />
                </div>
              ) : (
                <>
                  <IconButton aria-label="more" id="long-button" aria-controls={openDetailMenu ? "long-menu" : undefined} aria-expanded={openDetailMenu ? "true" : undefined} aria-haspopup="true" onClick={handleClickDetailMenu}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorElDetailMenu}
                    open={openDetailMenu}
                    onClose={handleCloseDetailMenu}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: "15ch",
                      },
                    }}
                  >
                    <MenuItem onClick={() => onToggleEditMode(rowData.id)}>
                      <EditIcon size="small" style={{ marginRight: "10px" }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => navigate(`/opportunities/${rowData.id}`)}>
                      <AssignmentIcon size="small" style={{ marginRight: "10px" }} /> Details
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
          </TableCell>
        )}
        {tableColumns.map((rowDataValue, index) => {
          return (
            <CustomTableCell
              key={index}
              {...{
                handleOnClick: handlewOnClickOnCell,
                row: row,
                fieldID: rowDataValue.field,
                isEditable: rowDataValue.isEditable,
                fieldType: rowDataValue.fieldType,
                fieldItemsValues: rowDataValue.values,
                onChange,
              }}
              style={{ ...props.style }}
            />
          );
        })}
      </TableRow>

      <TableRow style={{ backgroundColor: "white" }}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            height: 4,
          }}
          colSpan={tableColumns.length + 1}
        >
          <Collapse in={open} unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow onDoubleClick={handleDoubleClick}>
                    {detailsFields.map((rowDataDetailsValue, index) => {
                      return (
                        <TableCell
                          key={index}
                          align="left"
                          style={{
                            fontWeight: "bold",
                            background: "#DEE7C0",
                            textAlign: "center",
                          }}
                        >
                          {rowDataDetailsValue.title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsFields.map((rowDataDetailsValue, index) => (
                    <CustomTableCell
                      key={index}
                      {...{
                        row: row,
                        fieldID: rowDataDetailsValue.field,
                        isEditable: rowDataDetailsValue.isEditable,
                        fieldType: rowDataDetailsValue.fieldType,
                        fieldItemsValues: rowDataDetailsValue.values,
                        onChange,
                      }}
                      style={{ ...props.style }}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {ComponentDetails && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              width: "100%",
            }}
            colSpan={tableColumns.length + 1}
          >
            <Collapse in={open} unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  {ComponentDetails}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {otherDetailsExist && otherDetailsData ? (
        <TableRow
          style={{
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              width: "100%",
            }}
            colSpan={10}
          >
            <Collapse in={open} unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {otherDetailsTitle}
                </Typography>

                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow onDoubleClick={handleDoubleClick}>
                      {otherDetailsFields.map((rowDataDetailsValue, index) => {
                        return (
                          <TableCell
                            align="left"
                            key={index}
                            style={{
                              fontWeight: "bold",
                              background: "#DEE7C0",
                              textAlign: "center",
                            }}
                          >
                            {rowDataDetailsValue.title}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {otherDetailsData
                      ? otherDetailsData.map((line, index) => {
                          return (
                            <RowComponent
                              style={
                                // index % 2
                                //   ? { background: "#ededf5" }
                                //   :
                                { background: "white", textAlign: "center" }
                              }
                              rowData={line}
                              tableColumns={otherDetailsFields ? otherDetailsFields : []}
                              detailsFields={[]}
                              isLoading={isLoading}
                            />
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}
