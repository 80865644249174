import { format, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function formatMonthYearDate(date) {
    if (!isValidDate(date)) return date;
    return format(new Date(date), " MMMM yyyy");
}
export function fDate(date) {
    if (!isValidDate(date)) return date;
    return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function formatApiDate(date) {
    return format(new Date(date), "dd MMM yyyy");
}
export function fDateTimeSuffix(date) {
    return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    });
}

export function isValidDate(date) {
    if (date === null || date === undefined) return false;
    try {
        new Date(date);
        return true;
    } catch (err) {
        return false;
    }
}

export function getWeekNumberFromDate(d) {
    d = new Date(+d);
    d.setHours(0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    var yearStart = new Date(d.getFullYear(), 0, 1);
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return [d.getFullYear(), weekNo];
}

export function getDateFromWeekNumber(year, week) {
    var d = new Date(year, 0, 1);
    var day = d.getDay() || 7;
    d.setDate(d.getDate() + 7 * week - day + 1);
    return d;
}

export const formatDate = (dateString) => {
    const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
};
