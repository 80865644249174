import React from "react";
import { Grid, Typography, Card, CardContent, Box } from "@mui/material";

const PlainNumberCard = ({ data, cardTitle, subTitle, icon, textVariant = "h3", variant = "primary" }) => {
    const isSecondary = variant === "secondary";

    const cardStyle = isSecondary
        ? { backgroundColor: "#457B3B", color: "white", display: "flex", flexDirection: "column", height: "100%" }
        : { display: "flex", flexDirection: "column", height: "100%" };

    const textStyle = isSecondary ? { color: "white" } : {};
    const subTitleStyle = isSecondary ? { color: "lightgrey" } : {};

    return (
        <>
            <Card style={cardStyle}>
                <CardContent style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h5" style={{ marginBottom: "20px", ...textStyle }}>
                        {cardTitle}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Box display="flex" alignItems="center" style={{ animation: "popIn 0.5s ease-out", justifyContent: "center" }}>
                                    <Typography variant={textVariant} style={textStyle}>
                                        {data}
                                    </Typography>
                                    {icon}
                                </Box>
                                <Typography variant="subtitle2" style={subTitleStyle}>
                                    {subTitle}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <style jsx>{`
                @keyframes popIn {
                    0% {
                        opacity: 0;
                        transform: scale(0.5);
                    }
                    100% {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            `}</style>
        </>
    );
};

export default PlainNumberCard;
