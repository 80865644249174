import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormControl } from "@mui/material";
import Select from "react-select";
import { COUNTRIES } from "../../utils/static/Constants";

// ----------------------------------------------------------------------
const CustomCountryOption = (props) => {
  return (
    <button
      onClick={() => {
        props.selectOption(props.data);
      }}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        backgroundColor: "transparent",
        color: "black",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#E0EBFD";
        e.currentTarget.style.color = "black";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "transparent";
        e.currentTarget.style.color = "black";
      }}
    >
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${COUNTRIES.find(
          (country) => country.label === props.data.label
        ).code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${COUNTRIES.find(
          (country) => country.label === props.data.label
        ).code.toLowerCase()}.png 2x`}
        alt=""
        style={{ margin: "15px" }}
      />
      {props.label}
    </button>
  );
};

export default function RHFSelectField({
  label,
  name,
  menuItems,
  isMulti,
  defaultValue,
  isLoading,
  onValueChange,
  setVariableChange = () => {},
  isCountryField = false,
  style,
  emptyError,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => (
        <FormControl style={{ width: "100%", ...style }}>
          <Select
            style={{ ...style }}
            options={
              !isCountryField
                ? menuItems
                : COUNTRIES.map((country) => {
                    return { value: country.label, label: country.label };
                  })
            }
            placeholder={label}
            menuPlacement="auto"
            defaultValue={isMulti ? [] : defaultValue ? defaultValue : ""}
            multiple
            onChange={(item) => {
              if (item === null) {
                setVariableChange("");
                onChange("")
                return null;
              }
              const value = item.value;
              onChange(value);
              setVariableChange(value);
              //    onValueChange(value);
            }}
            isMulti={isMulti}
            isClearable={true}
            isLoading={isLoading}
            components={isCountryField ? { Option: CustomCountryOption } : {}}
            className={ emptyError ? 'border-red-500 border-2 rounded-md': ''}
          />
        </FormControl>
      )}
    />
  );
}
