import { SEARCH_FILTER_ACTION_TYPE } from "../ActionsTypes";
const initialState = {
  searchFieldsData: null,
  searchAction: false,
};

export const SearchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FILTER_ACTION_TYPE.START:
      return {
        ...state,
        searchFieldsData: action.payload,
        searchAction: true,
      };

    case SEARCH_FILTER_ACTION_TYPE.STOP:
      return {
        ...state,
        searchFieldsData: null,
        searchAction: false,
      };
    default:
      return { ...state };
  }
};
