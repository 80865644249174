import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Container, Typography, MenuItem, InputBase, Modal } from "@mui/material";
import Table from "../../components/table/TableComponent.jsx";
import ReactPaginate from "react-paginate";
import { stallOpportunitiesAction } from "../../utils/redux/action_creator/Opportunities_creators";
import { formatApiDate } from "../../utils/formatTime";
import Select from "react-select";
import { REGIONS, CASE_STATUS, BG_STATUS, PROBABILITY_CONVERSION, CAPABILITIES_PROFILES } from "../../utils/static/Constants";
import Widget from "../../components/Widget/Widget";
import StallCapabilitiesGraph from "../../components/dashboard/graphs/StallCapabilitiesGraph.jsx";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

const getDatePrevious60daysFromTodays = (months) => {
    let previous2Month = new Date();
    previous2Month.setMonth(new Date().getMonth() - months);
    return formatApiDate(previous2Month);
};

const StallOpportunities = (props) => {
    const { searchFieldsData, searchAction } = useSelector((state) => state.SearchFilterReducer);
    const [parameters, setParameters] = useState({
        last_updated_start: getDatePrevious60daysFromTodays(1),
        page_size: 8,
        page: 1,
    });

    const [displayModeValues, setDisplayModeValues] = useState(["Table", "Chart"]);
    const [displayModeSelectedValue, setDisplayModeSelectedValue] = useState(displayModeValues[0]);

    const { data, error, isLoading, isError } = useSelector(
        // @ts-ignore
        (state) => state.StallOpportunitiesReducer,
    );
    const metaData = data ? data.meta : { page: 1 };
    const dispatch = useDispatch();

    useEffect(() => {
        const newPArams = { ...searchFieldsData, parameters };
        if (searchAction) dispatch(stallOpportunitiesAction(newPArams));
    }, [searchAction]);

    const handlePageChange = (event) => {
        setParameters({
            ...parameters,
            ...searchFieldsData,
            page: event.selected + 1,
        });
    };

    useEffect(() => {
        setParameters({ ...parameters, ...searchFieldsData });
    }, [searchFieldsData]);

    const columns = [
        { field: "id", headerName: "Owner", flex: 1, hide: true },
        { field: "manager_full_name", headerName: "Owner", flex: 1 },
        { field: "client_name", headerName: "Client name", flex: 1 },
        { field: "case_status", headerName: "Case Status", flex: 1 },
        {
            field: "principal_full_name",
            headerName: "Partner / Principal",
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            type: "number",
            width: 225,
            flex: 1,
        },
    ];

    return (
        <Widget
            title={"Stall Opportunities"}
            upperTitle
            infoText={"infoText"}
            displayModeValues={displayModeValues}
            displayModeSelectedValue={displayModeSelectedValue}
            setDisplayModeSelectedValue={setDisplayModeSelectedValue}
        >
            <div style={{ height: 550, width: "100%", backgroundColor: "white" }}>
                {displayModeSelectedValue === displayModeValues[0] ? ( // table mode
                    <DataGrid
                        rows={data && data?.data?.opportunities && data?.data?.opportunities.length > 0 ? data.data.opportunities : []}
                        columns={columns}
                        pageSize={80}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                        onRowDoubleClick={(params, event) => {
                            window.open(`/opportunities/${params.row.id}`, "_blank");
                        }}
                    />
                ) : (
                    <StallCapabilitiesGraph data={data?.data?.opportunities} />
                )}
            </div>
        </Widget>
    );
};

export default StallOpportunities;
