import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    TextField,
    InputAdornment,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    LinearProgress,
    CircularProgress,
    Stack,
    ListItemText,
    Checkbox,
    Snackbar,
    OutlinedInput,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KpiIndicator from "./KPIIndicator";
import NoKpiIndicator from "./NoKPIIndicator";
import CapabilityTable from "./CapabilityTable";
import Widget from "../../../components/Widget/Widget";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOpportunityDetails, editOpportunityAction } from "../../../utils/redux/action_creator/Opportunities_creators";
import { update_employee_exclusion_action } from "../../../utils/redux/action_creator/employee_exclusions_creators";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid, LoadingOverlay } from "@mui/x-data-grid";
import {
    OPPORTUNITY_FUNDING_STAGE,
    CASE_STATUS,
    REGIONS,
    PROBABILITY_CONVERSION,
    COUNTRIES,
    CAPABILITIES_PROFILES,
    SENIORITY,
    OPPORTUNITY_SOURCE_MENUITEMS,
    FINANCE_DATA_GRID_OPP_DETAIL,
    EMPLOYEE_EXCLUSION_COLUMNS,
} from "../../../utils/static/Constants";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

function CustomLoadingOverlay() {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
            }}
        >
            <CircularProgress color="success" />
        </div>
    );
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomCountryOption = (props) => {
    return (
        <button
            onClick={() => {
                props.selectOption(props.data);
            }}
            style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                backgroundColor: "transparent",
                color: "black",
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#E0EBFD";
                e.currentTarget.style.color = "black";
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
                e.currentTarget.style.color = "black";
            }}
        >
            <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${COUNTRIES.find((country) => country.label === props.data.label).code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${COUNTRIES.find((country) => country.label === props.data.label).code.toLowerCase()}.png 2x`}
                alt=""
                style={{ margin: "15px" }}
            />
            {props.label}
        </button>
    );
};

const HISTORY_DATA_GRID = [
    { field: "action", headerName: "Action", flex: 1 },
    { field: "field_name", headerName: "Field", flex: 1 },
    { field: "old_value", headerName: "Old Value", flex: 1 },
    { field: "new_value", headerName: "New Value", flex: 1 },
    { field: "created_at", headerName: "Creation Date", flex: 1 },
];

const OpportunityDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = React.useState(0);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [editedData, setEditedData] = React.useState({});
    const [financePageSize, setFinancePageSize] = React.useState(50);
    const [excludedEmployeesPageSize, setExcludedEmployeesPageSize] = React.useState(10);
    const [capabilitiesPageSize, setCapabilitiesPageSize] = React.useState(10);
    const [historyPageSize, setHistoryPageSize] = React.useState(10);
    const [selectedCapabilities, setSelectedCapabilities] = React.useState([]);
    const [isSaveLoading, setIsSaveLoading] = React.useState(false);

    const [selectedEmployeesToExclude, setSelectedEmployeesToExclude] = useState([]);

    const [uniqueEmployees, setUniqueEmployees] = useState([]);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const { data, error, isLoading, isError } = useSelector((state) => state.OpportunityDetailsReducer);

    const { message, status, isLoadingEdit, isErrorEdit } = useSelector((state) => state.editOpportunityReducer);

    const handleBackClick = () => {
        // Navigate back in the history stack
        navigate(-1);
    };

    useEffect(() => {
        if (status === "success") {
            setIsSaveLoading(false);
            setOpenSnackbar(true);
        }
    }, [message]);

    const handleFundingStageStepNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setEditedData({
            ...editedData,
            bg_status: OPPORTUNITY_FUNDING_STAGE[activeStep + 1],
        });
    };

    const handleFundingStageStepBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setEditedData({
            ...editedData,
            bg_status: OPPORTUNITY_FUNDING_STAGE[activeStep - 1],
        });
    };

    useEffect(() => {
        if (params != null && params.id != null) {
            dispatch(getOpportunityDetails(params.id));
        }
    }, [params]);

    const CAPABILITY_DATA_GRID = [
        {
            field: "it_profile_field",
            headerName: "Profile",
            flex: 2,
            editable: isEditMode,
            type: "singleSelect",
            valueOptions: CAPABILITIES_PROFILES,
        },
        {
            field: "it_profile_level",
            headerName: "Level",
            flex: 1,
            editable: isEditMode,
            type: "singleSelect",
            valueOptions: SENIORITY,
        },
        {
            field: "num_profile",
            headerName: "Staffing",
            flex: 1,
            type: "number",
            editable: isEditMode,
        },
        {
            field: "num_weeks",
            headerName: "#Weeks",
            flex: 1,
            type: "number",
            editable: isEditMode,
        },
    ];

    const handleEmployeeExclusionChange = (event) => {
        const {
            target: { name, value },
        } = event;

        if (value.includes("all")) {
            if (selectedEmployeesToExclude.length === uniqueEmployees.length + editedData["employee_exclusion"].length) {
                setSelectedEmployeesToExclude([]);
            } else setSelectedEmployeesToExclude([...uniqueEmployees.map((e) => e.value), ...editedData["employee_exclusion"].map((e) => e.employee_id_tb)]);
        } else setSelectedEmployeesToExclude([...value]);
    };

    useEffect(() => {
        if (data != null) {
            let tempData = data;
            setActiveStep(OPPORTUNITY_FUNDING_STAGE.indexOf(data.bg_status));
            setEditedData(tempData);
            if (data["finance"] != null) {
                setUniqueEmployees(
                    data["finance"]
                        .map((item) => ({
                            value: item.employee_id_tb.replace(".0", ""),
                            label: item.employee_name_tb,
                        }))
                        .filter((employee, index, self) => index === self.findIndex((e) => e.value === employee.value)),
                );
                setSelectedEmployeesToExclude(data["employee_exclusion"].map((item) => item.employee_id_tb));
            }
        }
    }, [data]);

    if (data && Object.keys(data).length !== 0) {
        return (
            <div style={{ margin: "20px" }}>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
                        {message}
                    </Alert>
                </Snackbar>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                    }}
                >
                    <Button color="success" onClick={handleBackClick} disabled={window.history.length <= 1}>
                        <ArrowBackIcon />
                        <Typography>Back</Typography>
                    </Button>
                    {!isEditMode ? (
                        <Button
                            color="success"
                            variant="outlined"
                            onClick={() => {
                                setIsEditMode(true);
                                setOpenSnackbar(false);
                            }}
                        >
                            <ModeEditOutlinedIcon style={{ marginRight: "10px" }} />
                            <Typography>Edit</Typography>
                        </Button>
                    ) : (
                        <Button
                            color="success"
                            variant="contained"
                            onClick={() => {
                                setIsEditMode(false);
                                setIsSaveLoading(true);
                                // check if editedData.entry_date.add(à) exists
                                if (editedData.entry_date.add) {
                                    editedData.entry_date = editedData.entry_date.add(1, "days");
                                }
                                dispatch(editOpportunityAction(params.id, editedData));
                                if (editedData.entry_date.add) {
                                    editedData.entry_date = editedData.entry_date.add(-1, "days");
                                }
                                dispatch(
                                    update_employee_exclusion_action({
                                        employee_id_tb: selectedEmployeesToExclude,
                                        opportunity_id: params.id,
                                        user_email: localStorage.getItem("email"),
                                    }),
                                );
                                setEditedData((prevData) => ({
                                    ...prevData,
                                    employee_exclusion: uniqueEmployees
                                        .filter((employee) => selectedEmployeesToExclude.includes(employee.value))
                                        .map((employee) => ({
                                            employee_id_tb: employee.value,
                                            employee_name: employee.label,
                                            id: `employeeExclusion_${Math.random().toString(36).substring(7)}`, // Generate a random ID
                                            opportunity_id: prevData.opportunity_id,
                                            user_id: prevData.user_id,
                                        })),
                                }));

                                navigate(`/opportunities/${params.id}`);
                            }}
                        >
                            <SaveIcon style={{ marginRight: "10px" }} />
                            {isSaveLoading ? <CircularProgress size={20} color="success" /> : <Typography>Save</Typography>}
                        </Button>
                    )}
                </div>

                <Grid container spacing={2}>
                    <Grid item sx={12} xl={12} md={12}>
                        {data && data["finance"] && data["finance"].length > 0 ? <KpiIndicator data={data} /> : <NoKpiIndicator data={data} />}
                    </Grid>

                    <Grid item sx={7} xl={7} md={7} lg={7} style={{ height: "50%" }}>
                        <Grid container spacing={2}>
                            <Grid item sx={12} xl={12} md={12} lg={12}>
                                <Widget title={"Funding Stage"} upperTitle>
                                    <Stepper activeStep={activeStep}>
                                        {OPPORTUNITY_FUNDING_STAGE.map((label, index) => {
                                            return (
                                                <Step
                                                    key={label}
                                                    sx={{
                                                        "& .MuiStepLabel-root .Mui-completed": {
                                                            color: "success.dark", // circle color (COMPLETED)
                                                        },
                                                        "& .MuiStepLabel-root .Mui-active": {
                                                            color: "success.light", // circle color (ACTIVE)
                                                        },
                                                    }}
                                                >
                                                    <StepLabel>{label.replaceAll("_", " ")}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {isEditMode ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Button disabled={activeStep === 0} onClick={handleFundingStageStepBack} variant="contained" color="success">
                                                <ChevronLeftIcon />
                                            </Button>
                                            {/* <Typography>
                        Current Stage: {getStepContent(activeStep)}
                      </Typography> */}
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={handleFundingStageStepNext}
                                                disabled={activeStep === OPPORTUNITY_FUNDING_STAGE.length - 1}
                                            >
                                                <NavigateNextIcon />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </Widget>
                            </Grid>
                            <Grid item sx={12} xl={12} md={12} lg={12}>
                                <Widget title={"Information"} upperTitle>
                                    <Grid container spacing={2}>
                                        <Grid item sx={12} md={6} xl={6}>
                                            <TextField
                                                color="success"
                                                id="client_name"
                                                label="Client Name"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={editedData["client_name"]}
                                                fullWidth
                                                disabled={!isEditMode}
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        client_name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} xl={6}>
                                            <TextField
                                                color="success"
                                                id="project_code"
                                                label="Project Code"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={editedData["project_code"]}
                                                disabled={!isEditMode}
                                                fullWidth
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        project_code: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} xl={6}>
                                            <TextField
                                                color="success"
                                                id="partner_full_name"
                                                label="Partner / Principal"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={editedData["principal_full_name"]}
                                                disabled={!isEditMode}
                                                fullWidth
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        principal_full_name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                                <Select
                                                    color="success"
                                                    labelId="demo-simple-select-label"
                                                    id="source"
                                                    value={editedData["source"] !== undefined ? editedData["source"] : ""}
                                                    label="Source"
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={!isEditMode}
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            source: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {OPPORTUNITY_SOURCE_MENUITEMS.map((source) => {
                                                        return (
                                                            <MenuItem key={source.value} value={source.value}>
                                                                {source.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    id="entry_date"
                                                    label="Entry Date"
                                                    inputFormat="DD/MM/YYYY"
                                                    disabled={!isEditMode}
                                                    value={editedData["entry_date"]}
                                                    renderInput={(params) => <TextField color="success" fullWidth {...params} />}
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            entry_date: e,
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    id="expected_start_date"
                                                    label="Expected Start Date"
                                                    inputFormat="DD/MM/YYYY"
                                                    disabled={!isEditMode}
                                                    value={editedData["expected_start_date"]}
                                                    renderInput={(params) => <TextField color="success" fullWidth {...params} />}
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            expected_start_date: e,
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                                <Select
                                                    color="success"
                                                    labelId="demo-simple-select-label"
                                                    id="region_staffing"
                                                    value={editedData["region_staffing"] !== undefined ? editedData["region_staffing"] : ""}
                                                    label="Region"
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={!isEditMode}
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            region_staffing: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {REGIONS.map((region) => {
                                                        return (
                                                            <MenuItem key={region.value} value={region.value}>
                                                                {region.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                                <Select
                                                    color="success"
                                                    labelId="demo-simple-select-label"
                                                    id="country"
                                                    value={editedData["country"] !== undefined ? editedData["country"] : ""}
                                                    label="Country"
                                                    fullWidth
                                                    disabled={!isEditMode}
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            country: e.target.value,
                                                        });
                                                    }}
                                                    // components={{ Option: CustomCountryOption }}
                                                    // options={COUNTRIES.map((country) => {
                                                    //   return {
                                                    //     value: country.label,
                                                    //     label: country.label,
                                                    //   };
                                                    // })}
                                                >
                                                    {COUNTRIES.map((country) => {
                                                        return (
                                                            <MenuItem key={country.label} value={country.label}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${COUNTRIES.find((c) => c.label === country.label).code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${COUNTRIES.find(
                                                                            (c) => c.label === country.label,
                                                                        ).code.toLowerCase()}.png 2x`}
                                                                        alt={country.label + " flag"}
                                                                        style={{ marginRight: "15px" }}
                                                                    />
                                                                    {country.label}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Case Status</InputLabel>
                                                <Select
                                                    color="success"
                                                    id="case_status"
                                                    value={editedData["case_status"] !== undefined ? editedData["case_status"] : ""}
                                                    label="Case Status"
                                                    fullWidth
                                                    disabled={!isEditMode}
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            case_status: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {CASE_STATUS.map((status) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Conversion Probability</InputLabel>
                                                <Select
                                                    color="success"
                                                    labelId="demo-simple-select-label"
                                                    id="conversion_probability"
                                                    value={editedData["probability_conversion"] !== undefined ? editedData["probability_conversion"] : ""}
                                                    label="Conversion Probability"
                                                    fullWidth
                                                    disabled={!isEditMode}
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        setEditedData({
                                                            ...editedData,
                                                            probability_conversion: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {PROBABILITY_CONVERSION.map((status) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="number_of_weeks"
                                                label="Number of Weeks"
                                                variant="outlined"
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                                disabled={!isEditMode}
                                                value={editedData["weeks_number"]}
                                                fullWidth
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        weeks_number: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="average_weekly_rate"
                                                label="Average Weekly Rate (USD)"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                disabled={!isEditMode}
                                                value={editedData["avg_weekly_run_rate"]}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                fullWidth
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        avg_weekly_run_rate: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={12} lg={12}>
                                            <TextField
                                                color="success"
                                                id="description"
                                                label="Description"
                                                variant="outlined"
                                                value={editedData["description"]}
                                                fullWidth
                                                disabled={!isEditMode}
                                                multiline
                                                InputLabelProps={{ shrink: true }}
                                                maxRows={4}
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        description: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sx={12} md={12} lg={12}>
                                            <TextField
                                                color="success"
                                                id="comments"
                                                label="Comments"
                                                variant="outlined"
                                                value={editedData["comments"]}
                                                fullWidth
                                                disabled={!isEditMode}
                                                multiline
                                                InputLabelProps={{ shrink: true }}
                                                maxRows={4}
                                                onChange={(e) => {
                                                    setEditedData({
                                                        ...editedData,
                                                        comments: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Widget>
                            </Grid>
                            <Grid item sx={12} xl={12} md={12} lg={12}>
                                <Widget title={"Owner Details"} upperTitle>
                                    <Grid container spacing={2}>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Owner Email"
                                                variant="outlined"
                                                value={data["owner"]?.email_id}
                                                fullWidth
                                                multiline
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                                maxRows={4}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Owner Phone Number"
                                                variant="outlined"
                                                value={data["owner"]?.phone_number}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                multiline
                                                disabled
                                                maxRows={4}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Owner Region"
                                                variant="outlined"
                                                value={data["owner"]?.region}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                multiline
                                                disabled
                                                maxRows={4}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Owner Manager"
                                                variant="outlined"
                                                value={data["owner"]?.manage_full_name}
                                                fullWidth
                                                multiline
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                                maxRows={4}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Created At"
                                                variant="outlined"
                                                value={data["created_at"]}
                                                fullWidth
                                                multiline
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                                maxRows={4}
                                            />
                                        </Grid>
                                        <Grid item sx={12} md={6} lg={6}>
                                            <TextField
                                                color="success"
                                                id="outlined-basic"
                                                label="Last Updated At"
                                                variant="outlined"
                                                value={data["last_update_date"]}
                                                fullWidth
                                                multiline
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                                maxRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Widget>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={5} xl={5} md={5} lg={5}>
                        <Grid container spacing={2}>
                            <Grid item sx={12} xl={12} md={12} lg={12}>
                                <Widget title={"Capabilities"} upperTitle>
                                    <div
                                        style={{
                                            height: isEditMode ? 475 : 420,
                                            width: "100%",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        {isEditMode ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={() => {
                                                        setEditedData({
                                                            ...editedData,
                                                            capabilities: editedData["capabilities"].filter(
                                                                (capability) => !selectedCapabilities.map((selectedCapability) => selectedCapability.id).includes(capability.id),
                                                            ),
                                                        });
                                                        setSelectedCapabilities([]);
                                                    }}
                                                    disabled={selectedCapabilities.length === 0}
                                                >
                                                    <DeleteOutlineOutlinedIcon />
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={() => {
                                                        setEditedData({
                                                            ...editedData,
                                                            capabilities: [
                                                                ...editedData["capabilities"],
                                                                {
                                                                    id: "NEW_" + Math.floor(Math.random() * 1000000),
                                                                    it_profile_field: "manager",
                                                                    it_profile_level: "Senior",
                                                                    num_profile: "0",
                                                                    num_weeks: "0",
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                >
                                                    <AddCircleOutlineOutlinedIcon />
                                                </Button>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <DataGrid
                                            rows={editedData["capabilities"] ? editedData["capabilities"] : []}
                                            columns={CAPABILITY_DATA_GRID}
                                            pageSize={capabilitiesPageSize}
                                            components={{
                                                LoadingOverlay: CustomLoadingOverlay,
                                            }}
                                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                            loading={isLoading}
                                            checkboxSelection={isEditMode}
                                            style={{
                                                maxHeight: isEditMode ? 420 : 400,
                                            }}
                                            onPageSizeChange={(newPageSize) => {
                                                setCapabilitiesPageSize(newPageSize);
                                            }}
                                            onSelectionModelChange={(newSelection) => {
                                                setSelectedCapabilities(
                                                    editedData["capabilities"].filter((capability) => {
                                                        return newSelection.includes(capability.id);
                                                    }),
                                                );
                                            }}
                                            onCellEditCommit={(params) => {
                                                const { id, field, value } = params;
                                                setEditedData((prevState) => ({
                                                    ...prevState,
                                                    capabilities: prevState.capabilities.map((capability) => {
                                                        if (capability.id === id) {
                                                            return {
                                                                ...capability,
                                                                [field]: value,
                                                            };
                                                        }
                                                        return capability;
                                                    }),
                                                }));
                                            }}
                                        />
                                    </div>
                                </Widget>
                            </Grid>
                            <Grid item sx={12} xl={12} md={12} lg={12}>
                                <Widget title={"History"} upperTitle>
                                    <div
                                        style={{
                                            height: 475,
                                            width: "100%",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <DataGrid
                                            rows={data["history"] ? data["history"] : []}
                                            columns={HISTORY_DATA_GRID}
                                            pageSize={historyPageSize}
                                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                            loading={isLoading}
                                            components={{
                                                LoadingOverlay: CustomLoadingOverlay,
                                            }}
                                            onPageSizeChange={(newPageSize) => {
                                                setHistoryPageSize(newPageSize);
                                            }}
                                        />
                                    </div>
                                </Widget>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={12} xl={8} md={8} lg={8}>
                        <Widget title={"Finance"} upperTitle>
                            <div
                                style={{
                                    height: 550,
                                    width: "100%",
                                    backgroundColor: "white",
                                }}
                            >
                                <DataGrid
                                    rows={data["finance"] ? data["finance"] : []}
                                    columns={FINANCE_DATA_GRID_OPP_DETAIL}
                                    pageSize={financePageSize}
                                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                    loading={isLoading}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                    }}
                                    onPageSizeChange={(newPageSize) => {
                                        setFinancePageSize(newPageSize);
                                    }}
                                />
                            </div>
                        </Widget>
                    </Grid>
                    <Grid item sx={12} xl={4} md={4} lg={4}>
                        <Widget title={"Exclusions"} upperTitle infoText={"Note that exclusions are also applied to the dashboard view"}>
                            <div
                                style={{
                                    height: 550,
                                    width: "100%",
                                    backgroundColor: "white",
                                }}
                            >
                                {isEditMode ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <FormControl sx={{ m: 1, width: "95%" }}>
                                            <InputLabel id="squad-multiple-checkbox-label" style={{ width: "70%" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    Employess
                                                </div>
                                            </InputLabel>
                                            <Select
                                                labelId="employees-multiple-checkbox-label"
                                                id="employees-multiple-checkbox"
                                                multiple
                                                value={selectedEmployeesToExclude}
                                                label="Employees"
                                                name="employees"
                                                onChange={handleEmployeeExclusionChange}
                                                input={<OutlinedInput label="Employees" name="employeesInput" />}
                                                renderValue={(selected) => selected.join(", ")}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value={"all"}>
                                                    <Checkbox checked={selectedEmployeesToExclude.length === uniqueEmployees.length + editedData["employee_exclusion"].length} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>

                                                {[
                                                    ...uniqueEmployees,
                                                    ...editedData["employee_exclusion"].map((employee) => {
                                                        return {
                                                            value: employee.employee_id_tb,
                                                            label: employee.employee_name,
                                                        };
                                                    }),
                                                ].map((employeeName) => (
                                                    <MenuItem key={employeeName.value} value={employeeName.value}>
                                                        <Checkbox checked={selectedEmployeesToExclude.indexOf(employeeName.value) > -1} />
                                                        <ListItemText primary={employeeName.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <DataGrid
                                    rows={editedData["employee_exclusion"] ? editedData["employee_exclusion"] : []}
                                    columns={EMPLOYEE_EXCLUSION_COLUMNS}
                                    pageSize={excludedEmployeesPageSize}
                                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                    // checkboxSelection={isEditMode}
                                    loading={isLoading}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                    }}
                                    onPageSizeChange={(newPageSize) => {
                                        setExcludedEmployeesPageSize(newPageSize);
                                    }}
                                    style={{
                                        maxHeight: isEditMode ? 480 : "100%",
                                    }}
                                />
                            </div>
                        </Widget>
                    </Grid>
                </Grid>
            </div>
        );
    } else
        return (
            <div
                style={{
                    top: 100,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                }}
            >
                <LinearProgress color="success" style={{ width: "100%", marginRight: "20px" }} />
            </div>
        );
};
export default OpportunityDetails;
