import { combineReducers, configureStore } from "@reduxjs/toolkit";
// @ts-ignore
//import logger from "redux-logger";
import RoutReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOGOUT } from "./ActionsTypes";

const { GetUserReducer, LoginReducer, ...reducer } = RoutReducer;
const persistConfig = {
  key: "root",
  storage: storage,
  whiteList: ["GetUserReducer"],
};
const loginPersistConfig = {
  key: "login",
  storage: storage,
  whiteList: ["LoginReducer"],
};

const appReducer = combineReducers({
  ...reducer,
  // @ts-ignore
  GetUserReducer: persistReducer(persistConfig, GetUserReducer),
  LoginReducer: persistReducer(loginPersistConfig, LoginReducer),
});

// Empty  store when logout
const rootReducer = (state, action) => {
  if (action.type === LOGOUT.SUCCESS) {
    // delete local storage.
    storage.removeItem("persist:root");
    storage.removeItem("root");

    storage.removeItem("persist:login");
    storage.removeItem("login");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  // @ts-ignore
  devTools: "development", //process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
