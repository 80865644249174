export const GET_FINANCES_ACTIONS = {
    REQUEST: "LIST_FINANCES_REQUEST",
    SUCCESS: "LIST_FINANCES_SUCCESS",
    FAILED: "LIST_FINANCES_FAILED",
};

export const GET_EMPLOYEES_ACTIONS = {
    REQUEST: "LIST_EMPLOYEES_REQUEST",
    SUCCESS: "LIST_EMPLOYEES_SUCCESS",
    FAILED: "LIST_EMPLOYEES_FAILED",
};

export const GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS = {
    REQUEST: "FUNNEL_OPPORTUNITY_CHART_REQUEST",
    SUCCESS: "FUNNEL_OPPORTUNITY_CHART_SUCCESS",
    FAILED: "FUNNEL_OPPORTUNITY_CHART_FAILED",
};

export const GET_CAPABILITIESY_CHART_ACTIONS = {
    REQUEST: "CAPABILITIES_CHART_REQUEST",
    SUCCESS: "CAPABILITIES_CHART_SUCCESS",
    FAILED: "CAPABILITIES_CHART_FAILED",
};

export const GET_OPPORTUNITY_METRICS = {
    REQUEST: "OPPORTUNITY_METRICS_REQUEST",
    SUCCESS: "OPPORTUNITY_METRICS_SUCCESS",
    FAILED: "OPPORTUNITY_METRICS_FAILED",
};

export const GET_NCC_TABLE_ACTIONS = {
    REQUEST: "NCC_TABLE_REQUEST",
    SUCCESS: "NCC_TABLE_SUCCESS",
    FAILED: "NCC_TABLE_FAILED",
};

export const IMPORT_FINANCES_ACTIONS = {
    REQUEST: "IMPORT_FINANCES_REQUEST",
    SUCCESS: "IMPORT_FINANCES_SUCCESS",
    FAILED: "IMPORT_FINANCES_FAILED",
};

export const IMPORT_EMPLOYEES_ACTIONS = {
    REQUEST: "IMPORT_EMPLOYEES_REQUEST",
    SUCCESS: "IMPORT_EMPLOYEES_SUCCESS",
    FAILED: "IMPORT_EMPLOYEES_FAILED",
};

export const PREPARE_EMPLOYEES_IMPORT_ACTIONS = {
    REQUEST: "PREPARE_EMPLOYEES_IMPORT_REQUEST",
    SUCCESS: "PREPARE_EMPLOYEES_IMPORT_SUCCESS",
    FAILED: "PREPARE_EMPLOYEES_IMPORT_FAILED",
};

export const WIPE_IMPORT_FINANCES_ACTIONS = {
    REQUEST: "WIPE_IMPORT_FINANCES_REQUEST",
    SUCCESS: "WIPE_IMPORT_FINANCES_SUCCESS",
    FAILED: "WIPE_IMPORT_FINANCES_FAILED",
};

export const SEARCH_OPPORTUNITIES_ACTIONS = {
    REQUEST: "LIST_OP_REQUEST",
    SUCCESS: "LIST_OP_SUCCESS",
    FAILED: "LIST_OP_FAILED",
};

export const CASE_STATUS_CHART_ACTIONS = {
    REQUEST: "CASE_REQUEST",
    SUCCESS: "CASE_SUCCESS",
    FAILED: "CASA_FAILED",
};
export const PROJECTS_WORKING_WEEKS_ACTIONS = {
    REQUEST: "PRH_REQUEST",
    SUCCESS: "PRH_SUCCESS",
    FAILED: "PRH_FAILED",
};

export const AVG_WEEKLY_RATE_ACTIONS = {
    REQUEST: "AVG_WEEK_REQUEST",
    SUCCESS: "AVG_WEEK_SUCCESS",
    FAILED: "AVG_WEEK_FAILED",
};

export const TOTAL_DURATION_IN_WEEKS = {
    REQUEST: "TOTAL_DURATION_IN_WEEKS_REQUEST",
    SUCCESS: "TOTAL_DURATION_IN_WEEKS_SUCCESS",
    FAILED: "TOTAL_DURATION_IN_WEEKS_FAILED",
};

export const GRT_OPPORTUNITY_DETAILS_ACTIONS = {
    REQUEST: "GET_OPPORTUNITY_DETAILS_REQUEST",
    SUCCESS: "GET_OPPORTUNITY_DETAILS_SUCCESS",
    FAILED: "GET_OPPORTUNITY_DETAILS_FAILED",
};

export const STALL_OPPORTUNITIES_ACTIONS = {
    REQUEST: "STALL_OPP_REQUEST",
    SUCCESS: "STALL_OPP_SUCCESS",
    FAILED: "STALL_OPP_FAILED",
};
export const SEARCH_CONTACTS_ACTIONS = {
    REQUEST: "SEARCH_CONT_REQUEST",
    SUCCESS: "SEARCH_CONT_SUCCESS",
    FAILED: "SEARCH_CONT_FAILED",
};
export const CREATE_CONTACT_ACTIONS = {
    DEFAULT: "DEFAULT_STATE_CREATE_CONT_REQUEST",
    REQUEST: "CREATE_CONT_REQUEST",
    SUCCESS: "CREATE_CONT_SUCCESS",
    FAILED: "CREATE_CONT_FAILED",
};

export const UPDATE_CONTACT_ACTIONS = {
    DEFAULT: "UPDATE_DEFAULT_STATE_CREATE_CONT_REQUEST",
    REQUEST: "UPDATE_CONT_REQUEST",
    SUCCESS: "UPDATE_CONT_SUCCESS",
    FAILED: "UPDATE_CONT_FAILED",
};

export const LOGIN = {
    REQUEST: "LOGIN_REQUEST",
    SUCCESS: "LOGIN_SUCCESS",
    FAILED: "LOGIN_FAILED",
};
export const LOGOUT = {
    REQUEST: "LOGOUT_REQUEST",
    SUCCESS: "LOGOUT_SUCCESS",
    FAILED: "LOGOUT_FAILED",
};
export const GET_USER = {
    REQUEST: "GET_USER_REQUEST",
    SUCCESS: "GET_USER_SUCCESS",
    FAILED: "GET_USER_FAILED",
};

export const CREATE_OPPORTUNITY_ACTIONS = {
    DEFAULT: "DEFAULT_CREATE_OPP_REQUEST",
    REQUEST: "CREATE_OPP_REQUEST",
    SUCCESS: "CREATE_OPP_SUCCESS",
    FAILED: "CREATE_OPP_FAILED",
};

export const UPDATE_EMPLOYEE_EXCLUSION = {
    DEFAULT: "DEFAULT_CREATE_EMPLOYEE_ECLUSION",
    REQUEST: "CREATE_EMPLOYEE_ECLUSION_REQUEST",
    SUCCESS: "CREATE_EMPLOYEE_ECLUSION_SUCCESS",
    FAILED: "CREATE_EMPLOYEE_ECLUSION_FAILED",
};

export const DOWNLOAD_OPPORTUNITIES_EXCEL = {
    REQUEST: "DOWNLOAD_OPP_REQUEST",
    SUCCESS: "DOWNLOAD_OPP_SUCCESS",
    FAILED: "DOWNLOAD_OPP_FAILED",
};

export const DOWNLOAD_CONTACTS_EXCEL = {
    REQUEST: "DOWNLOAD_CONTACTS_REQUEST",
    SUCCESS: "DOWNLOAD_CONTACTS_SUCCESS",
    FAILED: "DOWNLOAD_CONTACTS_FAILED",
};

export const UPDATE_OPPORTUNITY_ACTIONS = {
    REQUEST: "UPDATE_OPP_REQUEST",
    SUCCESS: "UPDATE_OPP_SUCCESS",
    FAILED: "UPDATE_OPP_FAILED",
};

export const EDIT_OPPORTUNITY_ACTIONS = {
    REQUEST: "EDIT_OPP_REQUEST",
    SUCCESS: "EDIT_OPP_SUCCESS",
    FAILED: "EDIT_OPP_FAILED",
};

export const ASSIGN_USER_TO_ADMIN_ACTION = {
    REQUEST: "ASSIGN_USER_REQUEST",
    SUCCESS: "ASSIGN_USER_SUCCESS",
    FAILED: "ASSIGN_USER_FAILED",
};

export const DISMISS_USER_TO_ADMIN_ACTION = {
    REQUEST: "DISMISS_USER_REQUEST",
    SUCCESS: "DISMISS_USER_SUCCESS",
    FAILED: "DISMISS_USER_FAILED",
};

export const SEARCH_FILTER_ACTION_TYPE = {
    START: "SEARCH_FILTER_REQUEST",
    STOP: "SEARCH_FILTER_FAILED",
};
//-----------------------People-API---------------------------------//

export const PROPLE_API_ACTIONS_TYPE = {
    REQUEST: "PROPLE_API_REQUEST",
    SUCCESS: "PROPLE_API_SUCCESS",
    FAILED: "PROPLE_API_FAILED",
};
