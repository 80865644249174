import { useRoutes } from "react-router-dom";
import { APP_PAGES } from "../utils/appConstants";

const Router = () => {
  const routes = useRoutes(
    APP_PAGES.map((page) => {
      return {
        path: page.path,
        element: page.element,
      };
    })
  );

  return routes;
};

export default Router;
