import {
  GET_EMPLOYEES_ACTIONS,
  IMPORT_EMPLOYEES_ACTIONS,
  PREPARE_EMPLOYEES_IMPORT_ACTIONS,
} from "../ActionsTypes";

const get_initialState = {
  employees: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const GetEmployeesReducer = (state = get_initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_ACTIONS.REQUEST:
      return {
        ...state,
        employees: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case GET_EMPLOYEES_ACTIONS.SUCCESS:
      return {
        ...state,
        employees: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case GET_EMPLOYEES_ACTIONS.FAILED:
      return {
        ...state,
        employees: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};

const import_initialState = {
  message: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const ImportEmployeesReducer = (state = import_initialState, action) => {
  switch (action.type) {
    case IMPORT_EMPLOYEES_ACTIONS.REQUEST:
      return {
        ...state,
        message: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case IMPORT_EMPLOYEES_ACTIONS.SUCCESS:
      return {
        ...state,
        message: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case IMPORT_EMPLOYEES_ACTIONS.FAILED:
      return {
        ...state,
        message: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};

const prepare_import_initialState = {
  prepareImportMessage: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const PrepareImportEmployeesReducer = (
  state = prepare_import_initialState,
  action
) => {
  switch (action.type) {
    case PREPARE_EMPLOYEES_IMPORT_ACTIONS.REQUEST:
      return {
        ...state,
        prepareImportMessage: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case PREPARE_EMPLOYEES_IMPORT_ACTIONS.SUCCESS:
      return {
        ...state,
        prepareImportMessage: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case PREPARE_EMPLOYEES_IMPORT_ACTIONS.FAILED:
      return {
        ...state,
        prepareImportMessage: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};
