export const calculateAverage = (arr, attribute) => {
    if (arr && arr?.length <= 0) return 0.0;
    // Sum up the values of the attribute using reduce
    const sum = arr?.reduce((acc, obj) => {
        // Parse the attribute to a float rounded to 2 decimal places
        const value = parseFloat(parseFloat(obj[attribute]).toFixed(2));
        return acc + value;
    }, 0);

    // Calculate the average
    const average = sum / arr?.length;

    // Round the average to 2 decimal places
    const roundedAverage = parseFloat(average.toFixed(2));

    return roundedAverage;
};

export const calculateSum = (arr, attribute) => {
    if (arr && arr?.length <= 0) return 0.0;
    // Sum up the values of the attribute using reduce
    const sum = arr?.reduce((acc, obj) => {
        // Parse the attribute to a float rounded to 2 decimal places
        const value = parseFloat(parseFloat(obj[attribute]).toFixed(2));
        return acc + value;
    }, 0);

    return sum;
};
