import React from "react";

import TreeChart from "./three";
// comment
const browser = window.navigator.userAgent;
const isEdge = /Edge/i.test(browser);
const isChrome = Boolean(browser.indexOf("Chrome") > -1 && !isEdge);

const ContactNetworkTree = (props) => {
  return <TreeChart isChrome={isChrome} treeData={props.treeData} />;
};

export default ContactNetworkTree;
