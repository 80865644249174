import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import img from "./avatar-placeholder.svg";
import { formatMonthYearDate } from "../../../utils/formatTime";
class NodeLabel extends React.Component {
  constructor(props) {
    super(props);
    this.renderNodeInfo = this.renderNodeInfo.bind(this);
    this.onNodeClick = this.onNodeClick.bind(this);
  }

  onNodeClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  /* eslint no-underscore-dangle: ["error", { "allow": ["_collapsed", "_children"] }] */
  renderNodeInfo() {
    const { nodeData } = this.props;

    const participants = Number(nodeData.attributes.participants).toLocaleString("en-US");
    const participantText = participants === 1 ? "contact" : "contacts";

    return (
      <div>
        <a href="orgViz" onClick={this.onNodeClick}>
          <img src={img} alt={nodeData.name} />
        </a>
        <div className="node-info">
          <a href="orgViz" onClick={this.onNodeClick}>
            <div className="node-title">
              <LinesEllipsis className="linesEllipsis" title={nodeData.name} text={nodeData.name} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
            </div>
          </a>
          <span className="role">{nodeData.attributes.role}</span>
          {nodeData.attributes.connectedSince ? <span className="participants">{`Connected since ${formatMonthYearDate(nodeData.attributes.connectedSince)}`}</span> : <span className="participants">{`${participants} ${participantText}`}</span>}
        </div>
      </div>
    );
  }

  render() {
    const { nodeData } = this.props;
    return (
      <div className={this.props.className}>
        {this.renderNodeInfo()}
        {nodeData._children ? (
          <div className="toggleContainer">
            <span className="children">{nodeData._children.length}</span>
            <span className="toggle">
              <i className={this.props.nodeData._children ? "fa fa-chevron-down" : ""} />
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default NodeLabel;
