import { ASSIGN_USER_TO_ADMIN_ACTION, DISMISS_USER_TO_ADMIN_ACTION, PROPLE_API_ACTIONS_TYPE } from "../ActionsTypes";

const initialState = {
  assignData: null,
  assignErrorMesg: null,
  assignIsLoading: false,
  assignIsError: false,
};

export const AssignUserAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_USER_TO_ADMIN_ACTION.REQUEST:
      return {
        ...state,
        assignData: null,
        assignIsLoading: true,
        assignIsError: false,
        assignErrorMesg: null,
      };
    case ASSIGN_USER_TO_ADMIN_ACTION.SUCCESS:
      return {
        ...state,
        assignData: action.payload,
        assignIsLoading: false,
        assignIsError: false,
        assignErrorMesg: null,
      };
    case ASSIGN_USER_TO_ADMIN_ACTION.FAILED:
      return {
        ...state,
        assignData: null,
        assignIsLoading: false,
        assignIsError: true,
        assignErrorMesg: action.payload,
      };
    default:
      return { ...state };
  }
};

const initialState_dismiss = {
  dismissData: null,
  dismissErrorMesg: null,
  dismissIsLoading: false,
  dismissIsError: false,
};

export const DismissUserAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISMISS_USER_TO_ADMIN_ACTION.REQUEST:
      return {
        ...state,
        dismissData: null,
        dismissErrorMesg: null,
        dismissIsLoading: true,
        dismissIsError: false,
      };
    case DISMISS_USER_TO_ADMIN_ACTION.SUCCESS:
      return {
        ...state,
        dismissData: action.payload,
        dismissErrorMesg: null,
        dismissIsLoading: false,
        dismissIsError: false,
      };
    case DISMISS_USER_TO_ADMIN_ACTION.FAILED:
      return {
        ...state,
        dismissData: null,
        dismissErrorMesg: action.payload,
        dismissIsLoading: false,
        dismissIsError: true,
      };
    default:
      return { ...state };
  }
};

const people_api_initial_state = {
  peopleData: null,
  peopleIsLoading: null,
  peopleReject: false,
  peopleErrorMessage: null,
};

export const PeopleApiReducer = (state = people_api_initial_state, action) => {
  switch (action.type) {
    case PROPLE_API_ACTIONS_TYPE.REQUEST:
      return {
        ...state,
        peopleData: null,
        peopleIsLoading: true,
        peopleReject: false,
        peopleErrorMessage: null,
      };
    case PROPLE_API_ACTIONS_TYPE.SUCCESS:
      return {
        ...state,
        peopleData: action.payload,
        peopleIsLoading: null,
        peopleReject: false,
        peopleErrorMessage: null,
      };
    case PROPLE_API_ACTIONS_TYPE.FAILED:
      return {
        ...state,
        peopleData: null,
        peopleIsLoading: null,
        peopleReject: false,
        peopleErrorMessage: action.payload,
      };
    default:
      return { ...state };
  }
};
