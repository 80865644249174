import React from "react";
import { Card, Avatar, CardHeader } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CHARTCOLORS } from "../../utils/Colors";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import MenuIcon from "@mui/icons-material/Menu";

export const USER_SETTINGS = [
  // "Profile",
  "Add ",
];

export default function ItemCard(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { isSubuser, user_email, user_full_name, flag } = props;

  const options = [flag === "NEW" ? "ADD" : "Delete"];
  const handleClose = () => {
    // setAnchorEl(null);
    setAnchorEl(null);
  };
  const backgroundColor = isSubuser ? "#E6F9EA" : "white";

  return (
    <Card>
      <CardHeader
        style={{
          backgroundColor: backgroundColor,
        }}
        avatar={
          <Avatar
            sx={{
              bgcolor: props.colorIndex > -1 ? CHARTCOLORS[props.colorIndex % CHARTCOLORS.length] : null,
            }}
            aria-label="recipe"
          >
            {user_full_name ? user_full_name.charAt(0) : ""}
          </Avatar>
        }
        action={
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={isOpen ? "long-menu" : undefined}
              aria-expanded={isOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              size={"small"}
              //    edge={"end"}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "15ch",
                },
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  //    style={{ minWidth: "100%" }}
                  onClick={(e) => {
                    handleClose();
                    return props.handleSelectedValue(
                      {
                        user_email: user_email,
                        user_full_name: user_full_name,
                      },
                      e.target.innerText
                    );
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
        title={user_full_name}
        subheader={user_email}
      />
    </Card>
  );
}
