import React, { Fragment, useState, useEffect } from "react";
import { promoKPIStyles } from "./styles";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Grid,
  Button,
  MenuItem,
  Checkbox,
  Select,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import _ from "lodash";
import { LETTERS } from "../../../utils/static/Constants";
import {
  getWeekNumberFromDate,
  getDateFromWeekNumber,
} from "../../../utils/formatTime";

const NoKPIIndicator = (props) => {
  const classes = promoKPIStyles();
  const { KPIs, data } = props;
  const [filteredData, setFilteredData] = useState([]);
  return (
    <div>
      <Accordion
        elevation={2}
        style={{ borderRadius: "20px", background: "#477969", color: "white" }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Typography>Client Name</Typography>
              <Typography variant="h4">
                {data["client_name"]}{" "}
                {data["project_code"] ? "[" + data["project_code"] + "]" : null}
              </Typography>
            </div>

            <div
              style={{
                marginRight: "20px",
              }}
            >
              <div>
                <Typography>
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Owner:{" "}
                  </span>
                  {data["owner"]?.first_name} {data["owner"]?.last_name}
                </Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Create at:
                  </span>{" "}
                  {data["created_at"]}
                </Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Updated at:{" "}
                  </span>
                  {data["updated_at"]}
                </Typography>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "20px" }}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      Total Timesheet Hours
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          {filteredData
                            ? parseInt(
                                filteredData.reduce(
                                  (acc, cur) => acc + cur["timesheet_hours"],
                                  0
                                )
                              ).toLocaleString()
                            : 0}{" "}
                          Hours
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "20px" }}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      Net Client Charges
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          ${" "}
                          {filteredData
                            ? parseFloat(
                                filteredData.reduce(
                                  (acc, cur) =>
                                    acc + cur["net_client_charges_total"],
                                  0
                                )
                              )
                                // .toFixed(2)
                                .toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      # Employees Charging
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          {filteredData
                            ? filteredData
                                .reduce(
                                  (acc, cur) => acc.add(cur["employee_id_tb"]),
                                  new Set()
                                )
                                .size.toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Card
                variant="outlined"
                style={{ width: "100%", height: "100%" }}
              >
                <CardContent style={{ width: "100%", height: "100%" }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        textAlign: "center",
                      }}
                    >
                      No finance records found.
                    </div>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card
                variant="outlined"
                style={{ width: "100%", height: "100%" }}
              >
                <CardContent style={{ width: "100%", height: "100%" }}>
                  <TimesheetChart chartData={chartData} />
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default NoKPIIndicator;
