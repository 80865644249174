import { Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Contacts from "../pages/contacts/contacts";
import { Opportunities } from "../pages/opportunities/opportunities";
import { NewContact } from "../pages/contacts/new-contact";
import { NewOpportunity } from "../pages/opportunities/new-opportunity";
import RequireAuth from "../router/RequireAuth";
import Login from "../pages/loginPage/Login";
import { LoginCallback } from "@okta/okta-react";
import UserManagement from "../pages/management/UserManagement";
import FinanaceManagement from "../pages/finance/FinanceManagement";
import EmployeeManagement from "../pages/employee/EmployeeManagement";
// import Profile from "../pages/profile/Profile";
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ContactsIcon from "@mui/icons-material/Contacts";
import Groups2Icon from "@mui/icons-material/Groups2";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BadgeIcon from "@mui/icons-material/Badge";
import OpportunityDetails from "../pages/opportunities/opportunityDetails/OpportunityDetails";
import HomePage from "../pages/homePage/HomePage";
export const USER_SETTINGS = [
    // "Profile",
    "Logout",
];

export const APP_PAGES = [
    {
        title: "Home",
        path: "/",
        requireAdmin: false,
        showInSidebar: false,
        element: <Navigate to="/home" />,
        icon: <HomeIcon />,
    },
    {
        title: "Home",
        path: "/home",
        requireAdmin: false,
        showInSidebar: false,
        element: (
            <RequireAuth>
                {" "}
                <HomePage />
            </RequireAuth>
        ),
        icon: null,
    },
    {
        title: "Login",
        path: "/login",
        requireAdmin: false,
        showInSidebar: false,
        element: <Login />,
        icon: null,
    },
    {
        title: "Authorization Code",
        path: "/authorization-code/callback",
        requireAdmin: false,
        showInSidebar: false,
        element: <LoginCallback />,
        icon: null,
    },
    {
        title: "Dashboard",
        path: "/dashboard",
        requireAdmin: false,
        showInSidebar: true,
        element: (
            <RequireAuth>
                <Dashboard />
            </RequireAuth>
        ),
        icon: <DashboardIcon />,
    },
    {
        title: "Opportunities",
        path: "/opportunities",
        requireAdmin: false,
        showInSidebar: true,
        element: (
            <RequireAuth>
                <Opportunities />
            </RequireAuth>
        ),
        icon: <BusinessCenterIcon />,
    },
    {
        title: "New Opportunities",
        path: "/opportunities/new",
        requireAdmin: false,
        showInSidebar: false,
        element: (
            <RequireAuth>
                <NewOpportunity />
            </RequireAuth>
        ),
        icon: null,
    },
    {
        title: "New Opportunities",
        path: "/opportunities/:id",
        requireAdmin: false,
        showInSidebar: false,
        element: (
            <RequireAuth>
                <OpportunityDetails />
            </RequireAuth>
        ),
        icon: null,
    },

    //
    {
        title: "Contacts",
        path: "/contacts",
        requireAdmin: false,
        showInSidebar: true,
        element: (
            <RequireAuth>
                <Contacts />
            </RequireAuth>
        ),
        icon: <ContactsIcon />,
    },
    {
        title: "New Contacts",
        path: "/contacts/new",
        requireAdmin: false,
        showInSidebar: false,
        element: (
            <RequireAuth>
                <NewContact />
            </RequireAuth>
        ),
        icon: null,
    },
    {
        title: "Teams",
        path: "/management",
        requireAdmin: false,
        showInSidebar: true,
        element: (
            <RequireAuth>
                <UserManagement />
            </RequireAuth>
        ),
        icon: <Groups2Icon />,
    },
    {
        title: "Finance",
        path: "/finance",
        requireAdmin: false,
        showInSidebar: true,
        element: (
            <RequireAuth>
                <FinanaceManagement />
            </RequireAuth>
        ),
        icon: <CreditCardIcon />,
    },
    {
        title: "Employees",
        path: "/employees",
        requireAdmin: false,
        showInSidebar: false,
        element: (
            <RequireAuth>
                <EmployeeManagement />
            </RequireAuth>
        ),
        icon: <BadgeIcon />,
    },
    // {
    //   title: "Profile",
    //   path: "/profile",
    //   requireAdmin: false,
    //   showInSidebar: false,
    //   element: <Profile />,
    //   icon: null,
    // },
];

export const REQUIRED_COLUMNS_FINANCE_FILE_IMPORT = [
    "Year",
    "Month",
    "Week Of Year",
    "Client Name",
    "Project Name",
    "Line of Business",
    "RU Region*",
    "Employee ID T&B",
    "Employee Name T&B",
    "Week End Worked Date",
    "Week Posted Date",
    "Net Client Charges (Total)",
    "Timesheet Charges",
    "Timesheet Hours",
    "Theoretical Capacity (Total)",
    "Theo Cap Adjusted",
    "Theo Cap Total 5X",
    "Theo Cap Adjusted 5X",
    "Emp Country",
    "Employee Home Office System",
    "Employee Region",
    "Employee Region 2",
];
