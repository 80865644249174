import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({
  name,
  size,
  inputType,
  onValueChange,
  disabled,
  setVariableChange = () => {},
  label,
  emptyError,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={
            control._defaultValues[name] != undefined
              ? control._defaultValues[name]
              : label
              ? label
              : null
          }
          {...field}
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value
          }
          onChange={(event) => {
            const textValue = event.target.value;
            if (onValueChange) {
              onValueChange(event.target.value);
            }
            setVariableChange(event.target.value);
            field.onChange(event.target.value);
          }}
          error={!!error || emptyError}
          helperText={error?.message}
          {...other}
          size={size ? size : "small"}
          type={inputType ? inputType : "text"}
          disabled={disabled}
        />
      )}
    />
  );
}
