import React, { useEffect, useState } from "react";
// @ts-ignore
import Table from "../../components/table/TableComponent";
import { OpportunityFormFilter } from "./opportunity-form-filter";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";

import { opportunitiesList, downloadOpportunitiesAction } from "../../utils/redux/action_creator/Opportunities_creators";
import TableUpdateContext from "../../utils/context/table-update-context";
import { Button, Collapse, Grid, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { updateOpportunityAction } from "../../utils/redux/action_creator/Opportunities_creators";
import FilterListIcon from "@mui/icons-material/FilterList";
import DropdownButton from "../../components/DropdownButton";
import { NewOpportunity } from "./new-opportunity";
import Modal from "@mui/material/Modal";
import {tableColumns, detailsFields,capabilitiesFields} from './constants'

export function Opportunities() {
     
    const [parameters, setParameters] = useState({ page_size: 10 });
    const [openFilter, setOpenFilter] = useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalClose = () => {
        setModalOpen(false);
    };

    // @ts-ignore
    const { data, error, isLoading, isError } = useSelector((state) => state.OpportunityReducer);

    const { user } = useSelector((state) => state.GetUserReducer);

    const metaData = data ? data.meta : { is_modified: false, pages: 0 };
    const dispatch = useDispatch();

    const handlePageChange = (event) => {
        setParameters({ ...parameters, page: event.selected + 1 });
    };

    useEffect(() => {
        if (user && user.is_admin) {
            if (tableColumns[0].field != "manager_full_name") {
                tableColumns.unshift({
                    field: "manager_full_name",
                    title: "Owner",
                    fieldType: "",
                    isEditable: false,
                });
            }
        }
    }, []);
    useEffect(() => {
        if (!modalOpen) {
            dispatch(opportunitiesList(parameters));
        }
    }, [parameters, modalOpen]);

    const submitSearchFilter = (values) => {
        setParameters({ ...values });
    };

    const handleNewOpportunityClick = () => {
        setModalOpen(true);
    };
    if (isError) {
        //   alert("Error : "+ isError)
    }

    const updateTable = (updatedOpportunity) => {
        dispatch(updateOpportunityAction(updatedOpportunity.id, updatedOpportunity));
    };

    const handleExportCSV = () => {
        dispatch(downloadOpportunitiesAction());
    };

    const clearFormHandler = (defaultValues) => {
        setParameters({ ...parameters, ...defaultValues });
    };

    return (
        <div style={{ margin: "2%" }}>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <NewOpportunity handleModalClose={handleModalClose} />
            </Modal>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography fontSize={40}>Opportunities</Typography>
                <div style={{ float: "right", cursor: "pointer", margin: "1%" }}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleNewOpportunityClick}
                        style={{
                            margin: "10px",
                            textTransform: "none",
                            gap: "10px",
                        }}
                    >
                        <div>New Opportunity</div>
                        <AddCircleIcon />
                    </Button>
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item sx={12} md={12} sm={12}></Grid>

                <Grid item>
                    <Collapse in={openFilter} timeout="auto" unmountOnExit>
                        <OpportunityFormFilter submit={submitSearchFilter} clearForm={clearFormHandler} />
                    </Collapse>
                </Grid>

                <Grid item py={1} sx={12} md={12} sm={12}>
                    <div
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            color="success"
                            variant="outlined"
                            onClick={() => setOpenFilter(!openFilter)}
                            // sx={{ cursor: "pointer" }}
                            style={{
                                margin: "10px",
                                textTransform: "none",
                                gap: "10px",
                            }}
                        >
                            <FilterListIcon /> Filter
                        </Button>
                        <DropdownButton options={[{ label: "CSV", onClick: handleExportCSV }]} />
                    </div>

                    <TableUpdateContext.Provider value={{ updateTable: updateTable }}>
                        <Table
                            data={data ? data.opportunities : []}
                            isLoading={isLoading}
                            tableColumns={tableColumns}
                            detailsFields={detailsFields}
                            showFilter={true}
                            isModified={user && metaData.is_modified}
                            detailsExist={true}
                            otherDetailsExist={true}
                            otherDetailsFieldInDataSet={"capabilities"}
                            otherDetailsTitle={"Capabilities"}
                            otherDetailsFields={capabilitiesFields}
                            user_id={user ? user.id : "-1"}
                        />
                    </TableUpdateContext.Provider>
                    {isLoading && <div>Loading....</div>}

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={3}
                        pageCount={metaData.pages}
                        previousLabel="< previous"
                        containerClassName="pagination justify-content-center"
                        pageClassName={"page-item"}
                        pageLinkClassName="page-link"
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        //     activeClassName="active"
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        renderOnZeroPageCount={null}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
