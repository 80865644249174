import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Layout } from "../components/layout/index.jsx";
import Cookies from "js-cookie";
import { useOktaAuth } from "@okta/okta-react";

export default function RequireAuth({ children }) {
  const { authState, oktaAuth } = useOktaAuth();

  if (
    Cookies.get("isAuthenticated") === undefined &&
    authState &&
    authState.isAuthenticated
  ) {
    const futureDate = new Date();
    futureDate.setMinutes(futureDate.getMinutes() + 60);
    Cookies.set("isAuthenticated", "true", { expires: futureDate });
  }

  return Cookies.get("isAuthenticated") &&
    Cookies.get("isAuthenticated") === "true" ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/login" />
  );
}
