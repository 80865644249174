import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AppConversionRates } from "../../sections/@dashboard/app";
import { useSelector, useDispatch } from "react-redux";
import { getLabelFromData, CAPABILITIES_PROFILES } from "../../utils/static/Constants";
import Widget from "../../components/Widget/Widget";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import CapabilitiesRadarChart from "../../components/dashboard/graphs/CapabilitiesRadarChart";
import CapabilitiesGraph from "../../components/dashboard/graphs/CapabilitiesGraph";

const columns = [
    { field: "id", headerName: "id", hide: true },
    { field: "client_name", headerName: "Client Name", flex: 1 },
    { field: "project_code", headerName: "Project code", flex: 1 },
    { field: "profile", headerName: "Profile", flex: 1 },
    { field: "level", headerName: "Level", flex: 1 },
    {
        field: "num_weeks",
        headerName: "#Weeks",
        flex: 1,
    },
    {
        field: "num_staffing",
        headerName: "Staffing",

        flex: 1,
    },
];

const CapabilitiesChart = (props) => {
    const { data, isLoading } = props;
    const dispatch = useDispatch();
    const barGraphChartTitle = "Capability";
    const [capabilitiesData, setCapabilitiesData] = useState([]);
    const [listProjectId, setListProjectId] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [displayModeValues, setDisplayModeValues] = useState(["Table", "Chart", "Radar"]);
    const [displayModeSelectedValue, setDisplayModeSelectedValue] = useState(displayModeValues[0]);

    const handleSelectedCapability = (items) => {
        const selectedChart = data.filter((da) => da.label === items.value)[0];
    };

    const getLabelFromValue = (value, listItem) => {
        const v = listItem.filter((v) => v.value === value)[0];
        return v.label;
    };
    return (
        <>
            <Widget
                title={"Capabilities"}
                upperTitle
                displayModeValues={displayModeValues}
                displayModeSelectedValue={displayModeSelectedValue}
                setDisplayModeSelectedValue={setDisplayModeSelectedValue}
            >
                <div style={{ height: 550, width: "100%", backgroundColor: "white" }}>
                    {displayModeSelectedValue === displayModeValues[0] ? ( // table mode
                        <DataGrid
                            rows={data ? data : []}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[5]}
                            loading={isLoading}
                            onRowDoubleClick={(params, event) => {
                                window.open(`/opportunities/${params.row.parent_project_id}`, "_blank");
                            }}
                        />
                    ) : displayModeSelectedValue === displayModeValues[1] ? ( // chart mode
                        <CapabilitiesGraph data={data} />
                    ) : displayModeSelectedValue === displayModeValues[2] ? ( // radar mode
                        <CapabilitiesRadarChart data={data} />
                    ) : null}
                </div>
            </Widget>
        </>
    );
};

export default CapabilitiesChart;
