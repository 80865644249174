import { SEARCH_CONTACTS_ACTIONS, CREATE_CONTACT_ACTIONS, UPDATE_CONTACT_ACTIONS } from "../ActionsTypes";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CONTACTS_ACTIONS.REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case SEARCH_CONTACTS_ACTIONS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case SEARCH_CONTACTS_ACTIONS.FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};

const cre_initialState = {
  data: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const CreateContactReducer = (state = cre_initialState, action) => {
  switch (action.type) {
    case CREATE_CONTACT_ACTIONS.REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case CREATE_CONTACT_ACTIONS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case CREATE_CONTACT_ACTIONS.FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    case CREATE_CONTACT_ACTIONS.DEFAULT:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: false,
        isError: false,
      };
    default:
      return { ...state };
  }
};

const update_initialState = {
  up_data: null,
  up_error: null,
  up_isLoading: false,
  up_isError: false,
};

export const UpdateContactReducer = (state = update_initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_ACTIONS.REQUEST:
      return {
        ...state,
        data: null,
        up_data: null,
        up_error: null,
        up_isLoading: true,
        up_isError: false,
      };
    case UPDATE_CONTACT_ACTIONS.SUCCESS:
      return {
        ...state,
        up_data: action.payload,
        up_error: null,
        up_isLoading: false,
        up_isError: false,
      };
    case UPDATE_CONTACT_ACTIONS.FAILED:
      return {
        ...state,
        up_data: null,
        up_error: action.payload,
        up_isLoading: false,
        up_isError: true,
      };
    case UPDATE_CONTACT_ACTIONS.DEFAULT:
      return {
        ...state,
        up_data: null,
        up_error: null,
        up_isLoading: false,
        up_isError: false,
      };
    default:
      return { ...state };
  }
};
