import {
  GET_FINANCES_ACTIONS,
  IMPORT_FINANCES_ACTIONS,
  WIPE_IMPORT_FINANCES_ACTIONS,
  GET_NCC_TABLE_ACTIONS,
} from "../ActionsTypes";
import { get, post } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const financesList = (parameters) => async (dispatch) => {
  dispatch({ type: GET_FINANCES_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    const result = await get(BACKEDN_URL + "/api/v1/finance/get", params);
    dispatch({ type: GET_FINANCES_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: GET_FINANCES_ACTIONS.FAILED, payload: err });
  }
};

// WIPE USING POSTGRES COPY
export const financesWipeImport = (formData) => async (dispatch) => {
  dispatch({ type: WIPE_IMPORT_FINANCES_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    formData.append("email", email);
    const response = await fetch(
      BACKEDN_URL + "/api/thread/finance/wipe-import-postgres-copy",

      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();
    dispatch({ type: WIPE_IMPORT_FINANCES_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: WIPE_IMPORT_FINANCES_ACTIONS.FAILED, payload: err });
  }
};

export const financesImport = (formData) => async (dispatch) => {
  dispatch({ type: IMPORT_FINANCES_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    formData.append("email", email);
    const response = await fetch(BACKEDN_URL + "/api/v1/finance/import", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    dispatch({ type: IMPORT_FINANCES_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: IMPORT_FINANCES_ACTIONS.FAILED, payload: err });
  }
};

export const financesNccTable = (parameters) => async (dispatch) => {
  dispatch({ type: GET_NCC_TABLE_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    const result = await get(BACKEDN_URL + "/api/v1/finance/ncc_table", params);
    dispatch({ type: GET_NCC_TABLE_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: GET_NCC_TABLE_ACTIONS.FAILED, payload: err });
  }
};
