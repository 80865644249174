export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";

export const getListLabelsFromData = (data) => {
  return data.map((v) => v.label);
};

export const getLabelFromData = (data, value) => {
  const lebel = data.filter((d) => d.value === value)[0];
  return lebel != null ? lebel.label : value;
};
export const REGIONS = [
  { label: "EMESA", value: "EMESA" },
  { label: "CEMA", value: "CEMA" },
  { label: "WESA", value: "WESA" },
  { label: "AFRICA", value: "AFRICA" },
  { label: "IGTI", value: "IGTI" },
  { label: "NAMAR", value: "NAMAR" },
  { label: "WW", value: "WW" },
  { label: "ME", value: "ME" },
];
export const CASE_STATUS = [
  { label: "Open - Won", value: "open_won" },
  { label: "Closed - Lost", value: "closed_lost" },
  { label: "Open - On-Hold", value: "open_on_hold" },
  { label: "Closed - Won", value: "closed_won" },
  { label: "Open - Proposal/Scoping", value: "open_proposal_scoping" },
];
export const BG_STATUS = [
  { label: "Prospecting", value: "Prospecting" },
  { label: "Approved C code", value: "Approved_C_code" },
  { label: "Qualified", value: "Qualified" },
  { label: "Approved Investment", value: "Approved_Investment" },
];

export const PROBABILITY_CONVERSION = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
  { label: "Closed", value: "Closed" },
];

export const CAPABILITIES_PROFILES = [
  { label: "Manager", value: "manager" },
  { label: "Consultant", value: "consultant" },
  { label: "Architect", value: "architect" },
  { label: "Full Stack developer", value: "full_stack_developer" },
  { label: "Front-end Developer", value: "front_end_developer" },
  { label: "Back-end Developer", value: "back_end_developer" },
  { label: "Business Modeling", value: "business_modeling" },
  { label: "Optimization", value: "optimization" },
  { label: "Predictive Modeling", value: "predictive_modeling" },
  { label: "Data Engineering", value: "data_engineering" },
  { label: "Data science", value: "data_science" },
  { label: "Scrum Master", value: "scrum_master" },
  { label: "Devops", value: "devops" },
];
export const SENIORITY = [
  {
    label: "Junior",
    value: "Junior",
  },
  {
    label: "Senior",
    value: "Senior",
  },
  {
    label: "Lead",
    value: "Lead",
  },
  // {
  //   label: "Manager",
  //   value: "Manager",
  // },
];
export const OPPORTUNITY_SOURCE_MENUITEMS = [
  {
    label: "Existing relationship - New Case",
    value: "exisiting_relationship_new_case",
  },
  { label: "CT/PO Approached", value: "ct_po_approached" },
  { label: "RMS Extn/additional scope", value: "rms_extn_additional_scope" },
  {
    label: "Existing relationship - Case Extn/New WS",
    value: "exisiting_relatiobship_case_extn_new_ws",
  },
];

export const OUTREACH_TYPE_ITEMS = [
  {
    label: "known",
    value: "known",
  },
  {
    label: "Reference",
    value: "Reference",
  },
  {
    label: "New/Cold call",
    value: "new_cold_call",
  },
];

export const OPPORTUNITY_FUNDING_STAGE = [
  "Prospecting",
  "Qualified",
  "Approved_Investment",
  "Approved_C_code",
];

export const PA_INDUSTRY_LIST_ITEMS = [
  { value: "BT", label: "BCG Transform" },
  { value: "C", label: "Consumer" },
  { value: "E", label: "Energy" },
  { value: "FI", label: "Finiancial Institutions" },
  { value: "HC", label: "Health Care" },
  { value: "IG", label: "Industrial Goods" },
  { value: "I", label: "Insurance" },
  { value: "PIPE", label: "Principal Investors & Private Equity" },
  { value: "PS", label: "Public Sector" },
  { value: "TMT", label: "Technology, Media & Telecom" },
  { value: "CFS", label: "Corporate Finance & Strategy" },
  { value: "GA", label: "Global Advantage" },
  { value: "MSP", label: "Marketing, Sales & Pricing" },
  { value: "O", label: "Operations" },
  { value: "PO", label: "People & Organization" },
  { value: "CS", label: "Climate & Sustainability" },
  { value: "RC", label: "Risk and Compliance" },
  { value: "SI", label: "Social Impact" },
  { value: "TDA", label: "Tech and Digital Advantage" },
  { value: "BX", label: "BCG X" },
];

export const EMPLOYEES_TABLE_COLUMNS_TO_SHOW = [
  "id_tb",
  "name",
  "title_name",
  "letter",
  "cohort_grouping",
  "business_title",
  "region",
  "home_office_name",
  "hire_date",
];
export const EMPLOYEES_TABLE_COLUMNS_TO_HIDE = [
  "Country",
  "Home Office System",
  "Created By",
  "Title Name Historical",
  "Id HRO",
  "Home Office System",
];

export const FINANCE_TABLE_COLUMNS_TO_SHOW = [
  "year",
  "week_of_year",
  "month",
  "week_posted_date",
  "project_id",
  "project_name",
  "client_name",
  "employee_id_tb",
  "employee_name_tb",
  "letter",
  "timesheet_hours",
  "net_client_charges_total",
];

export const FINANCE_TABLE_COLUMNS_TO_HIDE = [
  "boost_non_boost",
  "business_title",
  "category",
  "cohort_grouping",
  "comments",
  "employee_country",
  "employee_home_office_name",
  "employee_home_office_system",
  "employee_id_hro",
  "employee_region",
  "employee_region_2",
  "hire_date",
  "id",
  "initiating_location_description",
  "inv_allocation",
  "ismoonshot",
  "matrix_manager",
  "max_posted_dt",
  "max_week_end_worked_date",
  "ncc_type",
  "omnia_historical",
  "planning_name_new",
  "primary_pa",
  "product_planning_name_id",
  "product_revenue_type",
  "productname",
  "project_attribute_1",
  "project_attribute_2",
  "project_host_office_country",
  "project_host_office_name",
  "project_host_office_region",
  "project_host_office_system",
  "project_type_code_current",
  "revenue_type_description",
  "ringfenced_y_n",
  "ru_name",
  "segmentation",
  "subsegmentation",
  "theo_cap_adjusted",
  "theo_cap_adjusted_5X",
  "theo_cap_total_5X",
  "theoretical_capacity_total",
  "timesheet_charges",
  "title_name",
  "title_name_historical",
  "tribe",
  "tribe_leader",
  "utilization",
  "week_end_worked_date",
];

export const LETTERS = [
  { label: "Analytics & Automation", value: "A" },
  { label: "Build", value: "D" },
];

export const EMPLOYEE_EXCLUSION_COLUMNS = [
  { field: "employee_id_tb", headerName: "Employee ID", flex: 1 },
  { field: "employee_name", headerName: "Employee Name", flex: 1 },
];

export const FINANCE_DATA_GRID_OPP_DETAIL = [
  { field: "year", headerName: "Year", flex: 1, maxWidth: 80 },
  { field: "week_of_year", headerName: "Week Of Year", flex: 1, maxWidth: 150 },
  { field: "letter", headerName: "Squad", flex: 1, maxWidth: 80 },
  {
    field: "employee_id_tb",
    headerName: "Employee ID T&B",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "employee_name_tb",
    headerName: "Employee Name",
    flex: 1,
    maxWidth: 280,
  },
  {
    field: "timesheet_hours",
    headerName: "Timesheet Hours",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "net_client_charges_total",
    headerName: "Net Client Charges",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "theoretical_capacity_total",
    headerName: "Theoretical Capabity Total",
    flex: 1,
    maxWidth: 200,
  },
  // START OF HIDDEN COLUMNS
  {
    field: "boost_non_boost",
    headerName: "Boost/Non Boost",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "business_title",
    headerName: "Business Title",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "client_name",
    headerName: "Client Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "cohort_grouping",
    headerName: "Cohort Group",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "comments",
    headerName: "Comments",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_country",
    headerName: "Employee Country",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_home_office_name",
    headerName: "Employee Home Office Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_home_office_system",
    headerName: "Employee Home Office System",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_id_hro",
    headerName: "Employee ID HRO",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_region",
    headerName: "Employee Region",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "employee_region_2",
    headerName: "Employee Region 2",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "hire_date",
    headerName: "Hire Date",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "initiating_location_description",
    headerName: "Initial Location Description",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "inv_allocation",
    headerName: "Inv. Allocation",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "ismoonshot",
    headerName: "Is Moonshot",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "matrix_manager",
    headerName: "Matrix Manager",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "max_posted_dt",
    headerName: "Max Posted DT",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "max_week_end_worked_date",
    headerName: "Max Week End Worked Date",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "month",
    headerName: "Month",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "ncc_type",
    headerName: "NCC Type",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "omnia_historical",
    headerName: "Omnia Historical",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "planning_name_new",
    headerName: "Planning Name New",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "product_planning_name_id",
    headerName: "Product Planning Name ID",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "product_revenue_type",
    headerName: "Product Revenue Type",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "productname",
    headerName: "Product Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_attribute_1",
    headerName: "Project Attribute 1",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_attribute_2",
    headerName: "Project Attribute 2",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_host_office_country",
    headerName: "Project Host Office Country",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_host_office_name",
    headerName: "Project Host Office Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_host_office_region",
    headerName: "Project Host Office Region",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_name",
    headerName: "Project Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "project_type_code_current",
    headerName: "Project Type Code Current",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "revenue_type_description",
    headerName: "Revenue Type Description",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "ru_name",
    headerName: "RU Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "segmentation",
    headerName: "Segmentation",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "subsegmentation",
    headerName: "Subsegmentation",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "theo_cap_adjusted",
    headerName: "Theo. Cap. Adjusted",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "theo_cap_adjusted_5X",
    headerName: "Theo. Cap. Adjusted 5X",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "theo_cap_total_5X",
    headerName: "Theo. Cap. Total 5X",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "timesheet_charges",
    headerName: "Timesheet Charges",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "title_name",
    headerName: "Title Name",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "title_name_historical",
    headerName: "Title Name Historical",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "tribe",
    headerName: "Tribe",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "tribe_leader",
    headerName: "Tribe Leader",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "utilization",
    headerName: "Utilization",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
  {
    field: "week_posted_date",
    headerName: "Week Posted Date",
    flex: 1,
    maxWidth: 200,
    hide: true,
  },
];

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const COUNTRIES = [
  { code: "AD", label: "Andorra" },
  {
    code: "AE",
    label: "United Arab Emirates",
  },
  { code: "AF", label: "Afghanistan" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
  },
  { code: "AI", label: "Anguilla" },
  { code: "AL", label: "Albania" },
  { code: "AM", label: "Armenia" },
  { code: "AO", label: "Angola" },
  { code: "AQ", label: "Antarctica" },
  { code: "AR", label: "Argentina" },
  { code: "AS", label: "American Samoa" },
  { code: "AT", label: "Austria" },
  {
    code: "AU",
    label: "Australia",
  },
  { code: "AW", label: "Aruba" },
  { code: "AX", label: "Alland Islands" },
  { code: "AZ", label: "Azerbaijan" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
  },
  { code: "BB", label: "Barbados" },
  { code: "BD", label: "Bangladesh" },
  { code: "BE", label: "Belgium" },
  { code: "BF", label: "Burkina Faso" },
  { code: "BG", label: "Bulgaria" },
  { code: "BH", label: "Bahrain" },
  { code: "BI", label: "Burundi" },
  { code: "BJ", label: "Benin" },
  { code: "BL", label: "Saint Barthelemy" },
  { code: "BM", label: "Bermuda" },
  { code: "BN", label: "Brunei Darussalam" },
  { code: "BO", label: "Bolivia" },
  { code: "BR", label: "Brazil" },
  { code: "BS", label: "Bahamas" },
  { code: "BT", label: "Bhutan" },
  { code: "BV", label: "Bouvet Island" },
  { code: "BW", label: "Botswana" },
  { code: "BY", label: "Belarus" },
  { code: "BZ", label: "Belize" },
  {
    code: "CA",
    label: "Canada",
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
  },
  {
    code: "CF",
    label: "Central African Republic",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
  },
  { code: "CH", label: "Switzerland" },
  { code: "CI", label: "Cote d'Ivoire" },
  { code: "CK", label: "Cook Islands" },
  { code: "CL", label: "Chile" },
  { code: "CM", label: "Cameroon" },
  { code: "CN", label: "China" },
  { code: "CO", label: "Colombia" },
  { code: "CR", label: "Costa Rica" },
  { code: "CU", label: "Cuba" },
  { code: "CV", label: "Cape Verde" },
  { code: "CW", label: "Curacao" },
  { code: "CX", label: "Christmas Island" },
  { code: "CY", label: "Cyprus" },
  { code: "CZ", label: "Czech Republic" },
  {
    code: "DE",
    label: "Germany",
  },
  { code: "DJ", label: "Djibouti" },
  { code: "DK", label: "Denmark" },
  { code: "DM", label: "Dominica" },
  {
    code: "DO",
    label: "Dominican Republic",
  },
  { code: "DZ", label: "Algeria" },
  { code: "EC", label: "Ecuador" },
  { code: "EE", label: "Estonia" },
  { code: "EG", label: "Egypt" },
  { code: "ER", label: "Eritrea" },
  { code: "ES", label: "Spain" },
  { code: "ET", label: "Ethiopia" },
  { code: "FI", label: "Finland" },
  { code: "FJ", label: "Fiji" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
  },
  { code: "FO", label: "Faroe Islands" },
  {
    code: "FR",
    label: "France",
  },
  { code: "GA", label: "Gabon" },
  { code: "GB", label: "United Kingdom" },
  { code: "GD", label: "Grenada" },
  { code: "GE", label: "Georgia" },
  { code: "GF", label: "French Guiana" },
  { code: "GG", label: "Guernsey" },
  { code: "GH", label: "Ghana" },
  { code: "GI", label: "Gibraltar" },
  { code: "GL", label: "Greenland" },
  { code: "GM", label: "Gambia" },
  { code: "GN", label: "Guinea" },
  { code: "GP", label: "Guadeloupe" },
  { code: "GQ", label: "Equatorial Guinea" },
  { code: "GR", label: "Greece" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  { code: "GT", label: "Guatemala" },
  { code: "GU", label: "Guam" },
  { code: "GW", label: "Guinea-Bissau" },
  { code: "GY", label: "Guyana" },
  { code: "HK", label: "Hong Kong" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
  },
  { code: "HN", label: "Honduras" },
  { code: "HR", label: "Croatia" },
  { code: "HT", label: "Haiti" },
  { code: "HU", label: "Hungary" },
  { code: "ID", label: "Indonesia" },
  { code: "IE", label: "Ireland" },
  { code: "IL", label: "Israel" },
  { code: "IM", label: "Isle of Man" },
  { code: "IN", label: "India" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
  },
  { code: "IQ", label: "Iraq" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
  },
  { code: "IS", label: "Iceland" },
  { code: "IT", label: "Italy" },
  { code: "JE", label: "Jersey" },
  { code: "JM", label: "Jamaica" },
  { code: "JO", label: "Jordan" },
  {
    code: "JP",
    label: "Japan",
  },
  { code: "KE", label: "Kenya" },
  { code: "KG", label: "Kyrgyzstan" },
  { code: "KH", label: "Cambodia" },
  { code: "KI", label: "Kiribati" },
  { code: "KM", label: "Comoros" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
  },
  { code: "KR", label: "Korea, Republic of" },
  { code: "KW", label: "Kuwait" },
  { code: "KY", label: "Cayman Islands" },
  { code: "KZ", label: "Kazakhstan" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
  },
  { code: "LB", label: "Lebanon" },
  { code: "LC", label: "Saint Lucia" },
  { code: "LI", label: "Liechtenstein" },
  { code: "LK", label: "Sri Lanka" },
  { code: "LR", label: "Liberia" },
  { code: "LS", label: "Lesotho" },
  { code: "LT", label: "Lithuania" },
  { code: "LU", label: "Luxembourg" },
  { code: "LV", label: "Latvia" },
  { code: "LY", label: "Libya" },
  { code: "MA", label: "Morocco" },
  { code: "MC", label: "Monaco" },
  {
    code: "MD",
    label: "Moldova, Republic of",
  },
  { code: "ME", label: "Montenegro" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
  },
  { code: "MG", label: "Madagascar" },
  { code: "MH", label: "Marshall Islands" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  { code: "ML", label: "Mali" },
  { code: "MM", label: "Myanmar" },
  { code: "MN", label: "Mongolia" },
  { code: "MO", label: "Macao" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
  },
  { code: "MQ", label: "Martinique" },
  { code: "MR", label: "Mauritania" },
  { code: "MS", label: "Montserrat" },
  { code: "MT", label: "Malta" },
  { code: "MU", label: "Mauritius" },
  { code: "MV", label: "Maldives" },
  { code: "MW", label: "Malawi" },
  { code: "MX", label: "Mexico" },
  { code: "MY", label: "Malaysia" },
  { code: "MZ", label: "Mozambique" },
  { code: "NA", label: "Namibia" },
  { code: "NC", label: "New Caledonia" },
  { code: "NE", label: "Niger" },
  { code: "NF", label: "Norfolk Island" },
  { code: "NG", label: "Nigeria" },
  { code: "NI", label: "Nicaragua" },
  { code: "NL", label: "Netherlands" },
  { code: "NO", label: "Norway" },
  { code: "NP", label: "Nepal" },
  { code: "NR", label: "Nauru" },
  { code: "NU", label: "Niue" },
  { code: "NZ", label: "New Zealand" },
  { code: "OM", label: "Oman" },
  { code: "PA", label: "Panama" },
  { code: "PE", label: "Peru" },
  { code: "PF", label: "French Polynesia" },
  { code: "PG", label: "Papua New Guinea" },
  { code: "PH", label: "Philippines" },
  { code: "PK", label: "Pakistan" },
  { code: "PL", label: "Poland" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
  },
  { code: "PN", label: "Pitcairn" },
  { code: "PR", label: "Puerto Rico" },
  {
    code: "PS",
    label: "Palestine, State of",
  },
  { code: "PT", label: "Portugal" },
  { code: "PW", label: "Palau" },
  { code: "PY", label: "Paraguay" },
  { code: "QA", label: "Qatar" },
  { code: "RE", label: "Reunion" },
  { code: "RO", label: "Romania" },
  { code: "RS", label: "Serbia" },
  { code: "RU", label: "Russian Federation" },
  { code: "RW", label: "Rwanda" },
  { code: "SA", label: "Saudi Arabia" },
  { code: "SB", label: "Solomon Islands" },
  { code: "SC", label: "Seychelles" },
  { code: "SD", label: "Sudan" },
  { code: "SE", label: "Sweden" },
  { code: "SG", label: "Singapore" },
  { code: "SH", label: "Saint Helena" },
  { code: "SI", label: "Slovenia" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  { code: "SK", label: "Slovakia" },
  { code: "SL", label: "Sierra Leone" },
  { code: "SM", label: "San Marino" },
  { code: "SN", label: "Senegal" },
  { code: "SO", label: "Somalia" },
  { code: "SR", label: "Suriname" },
  { code: "SS", label: "South Sudan" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
  },
  { code: "SV", label: "El Salvador" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
  },
  { code: "SZ", label: "Swaziland" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
  },
  { code: "TD", label: "Chad" },
  {
    code: "TF",
    label: "French Southern Territories",
  },
  { code: "TG", label: "Togo" },
  { code: "TH", label: "Thailand" },
  { code: "TJ", label: "Tajikistan" },
  { code: "TK", label: "Tokelau" },
  { code: "TL", label: "Timor-Leste" },
  { code: "TM", label: "Turkmenistan" },
  { code: "TN", label: "Tunisia" },
  { code: "TO", label: "Tonga" },
  { code: "TR", label: "Turkey" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
  },
  { code: "TV", label: "Tuvalu" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
  },
  { code: "UA", label: "Ukraine" },
  { code: "UG", label: "Uganda" },
  {
    code: "US",
    label: "United States",
  },
  { code: "UY", label: "Uruguay" },
  { code: "UZ", label: "Uzbekistan" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  { code: "VE", label: "Venezuela" },
  {
    code: "VG",
    label: "British Virgin Islands",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
  },
  { code: "VN", label: "Vietnam" },
  { code: "VU", label: "Vanuatu" },
  { code: "WF", label: "Wallis and Futuna" },
  { code: "WS", label: "Samoa" },
  { code: "XK", label: "Kosovo" },
  { code: "YE", label: "Yemen" },
  { code: "YT", label: "Mayotte" },
  { code: "ZA", label: "South Africa" },
  { code: "ZM", label: "Zambia" },
  { code: "ZW", label: "Zimbabwe" },
];
