import React from "react";
import { styled } from "@mui/material/styles";

export default function Dot({ color }) {
  const DotDiv = styled("div")(({ theme }) => ({
    width: 8,
    height: 8,
    backgroundColor: color,
    borderRadius: "50%",
    transition: color,
    marginRight: "5px",
  }));

  return (
    <>
      <DotDiv />
    </>
  );
}
