import React, { useState } from "react";
import "./index.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import RowComponent from "./RowComponent.jsx";
import treeData from "../../contact_network_tree/data";
import ContactNetworkTree from "../../contact_network_tree/ContactNetworkTree";
import theme from "../../../../utils/theme";
const TableComponent = (props) => {
  const pageSize = props.pageSize;
  const [treeData, setTreeData] = useState([]);
  //  const tableColumns = tableColumns;

  const {
    tableColumns,
    detailsFields,
    data,
    isModified,
    detailsExist,
    otherDetailsExist,
    otherDetailsFieldInDataSet,
    otherDetailsTitle,
    otherDetailsFields,
    user_id,
    isLoading,
    errorMessage,

    onDoubleClickOnRow,
  } = props;

  return (
    <TableContainer
      style={{
        ...props.style,
        backgroundColor: "white",
        border: "1px solid grey",
        borderRadius: 5,
      }}
    >
      <Table aria-label="collapsible table" multiSelectable={true}>
        <TableHead>
          <TableRow>
            {isModified && <TableCell style={{ background: theme.palette.primary.main }} />}
            {tableColumns.map((cell, index) => {
              return (
                <TableCell key={index} align="left" style={{ background: theme.palette.primary.main }}>
                  <Typography
                    //fontSize={9}
                    fontWeight="bold"
                    style={{
                      lineHeight: 0.8,
                      fontSize: 12,
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "center",
                      lineHeight: 1.5,
                    }}
                  >
                    {cell.title.toUpperCase()}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <div>Loading ... </div>}
          {data
            ? data.map((line, index) => {
                return <RowComponent style={{ textAlign: "center" }} rowData={line.contacts} tableColumns={tableColumns ? tableColumns : []} detailsFields={detailsFields} isLoading={isLoading} isModified={isModified} detailsExist={detailsExist} otherDetailsData={line[otherDetailsFieldInDataSet] ? line[otherDetailsFieldInDataSet] : []} otherDetailsTitle={otherDetailsTitle} otherDetailsFields={otherDetailsFields} otherDetailsExist={otherDetailsFieldInDataSet && otherDetailsExist} onDoubleClickOnRow={onDoubleClickOnRow} treeData={line.treeData} />;
              })
            : null}
          {data && data.length === 0 && !isLoading && <div style={{ margin: "auto", alignItems: "center" }}>No Rows</div>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
