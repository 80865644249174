import { SEARCH_CONTACTS_ACTIONS, CREATE_CONTACT_ACTIONS, DOWNLOAD_CONTACTS_EXCEL, UPDATE_CONTACT_ACTIONS } from "../ActionsTypes";
import { get, post, downloadFile } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const contactsList = (parameters) => async (dispatch) => {
  dispatch({ type: SEARCH_CONTACTS_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    const result = await get(BACKEDN_URL + "/api/v1/contacts/search", params);
    dispatch({ type: SEARCH_CONTACTS_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: SEARCH_CONTACTS_ACTIONS.FAILED, payload: err });
  }
};

export const create_contact_action = (contact_data) => async (dispatch) => {
  dispatch({ type: CREATE_CONTACT_ACTIONS.REQUEST });

  try {
    const email = TokenStorage.getEmail();
    const params = { email: email };
    const result = await post(BACKEDN_URL + "/api/v1/contacts/create", contact_data, params);
    if (result.status === 201) {
      dispatch({ type: CREATE_CONTACT_ACTIONS.SUCCESS, payload: true });
    } else {
      dispatch({ type: CREATE_CONTACT_ACTIONS.FAILED });
    }
  } catch (err) {
    dispatch({ type: CREATE_CONTACT_ACTIONS.FAILED, payload: err });
  }
};

export const updateContactAction = (id_contact, contactUpdatedObject) => async (dispatch) => {
  dispatch({ type: UPDATE_CONTACT_ACTIONS.REQUEST });
  try {
    const result = await post(`${BACKEDN_URL}/api/v1/contacts/edit/${id_contact}`, { contact_data: contactUpdatedObject });
    // @ts-ignore
    if (result.status === 200) {
      dispatch({ type: UPDATE_CONTACT_ACTIONS.SUCCESS, payload: true });
    } else {
      dispatch({ type: UPDATE_CONTACT_ACTIONS.FAILED });
    }
  } catch (error) {}
};

//----------------Download contacts--as csv----------------------//
export const downloadContactsAction = () => async (dispatch) => {
  try {
    const email = TokenStorage.getEmail();
    const data = await downloadFile(`${BACKEDN_URL}/api/v1/contacts/download`, "contacts");
    dispatch({ type: DOWNLOAD_CONTACTS_EXCEL.SUCCESS });
  } catch (error) {
    dispatch({ type: DOWNLOAD_CONTACTS_EXCEL.FAILED });
  }
};
