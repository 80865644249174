import React, { useState } from "react";
import * as Yup from "yup";
import { Opportunity } from "./opportunities";

import { forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { FormProvider } from "../../components/hook-form";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {
  OpportunityCapabilities,
  OpportunityCommentsDescriptionInfo,
  OpportunityDetailsInfo,
} from "../../pages/opportunities/opportunities-forms";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createOpportunityAction } from "../../utils/redux/action_creator/Opportunities_creators";
import { LoadingPanel } from "../../components/loading-panel";
import { CREATE_OPPORTUNITY_ACTIONS } from "../../utils/redux/ActionsTypes";
import { set } from "lodash";
export function NewOpportunity(props) {
  const defaultValues = {
    client_name: null,
    principal_full_name: null,
    entry_start_date: null,
    region: null,
    country: null,
    bg_status: null,
    case_status: null,
    probability_conversion: null,
    avg_weekly_run_rate: null,
    // weeks_number: null,
  };
  const opportunitiesDetailsSchema = Yup.object().shape({
    client_name: Yup.string().required(),
    principal_full_name: Yup.string().required(),
    entry_start_date: Yup.date().required(),
    region: Yup.string().required(),
    country: Yup.string().required(),
    bg_status: Yup.string().required(),
    case_status: Yup.string().required(),
    probability_conversion: Yup.string().notRequired(),
  });

  const { handleModalClose } = props;
  const { innerWidth, innerHeight } = window;
  const [newOpportunity, setNewOpportunity] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [validForm, setValidForm] = useState(false);
  const theme = createTheme();
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMessageDetailsInfo, setShowErrorMessageDetailsInfo] =
    useState(false);
  const [showErrorMessageCapabilities, setShowErrorMessageCapabilities] =
    useState(false);
  const [
    showErrorMessageCommentsDescription,
    setShowErrorMessageCommentsDescription,
  ] = useState(false);

  // unsed for form validation
  const [clientName, setClientName] = useState("");
  const [principalFullName, setPrincipalFullName] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [entryStartDate, setEntryStartDate] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [bgStatus, setBgStatus] = useState("");
  const [source, setSource] = useState("");
  const [listOfRequiredFieldsEmpty, setListOfRequiredFieldsEmpty] = useState([]);

  const { data, error, isLoading, isError } = useSelector(
    // @ts-ignore
    (state) => state.CreateOpportunityReducer
  );

  const [capabilities, setCapabilities] = useState([
    { id: 0, kind: "data", staffing: 1, hours: 16 },
  ]);

  useEffect(() => {
    setNewOpportunity((opp) => ({ ...opp, capabilities }));
  }, [capabilities]);
  const dispatch = useDispatch();
  const steps = ["Details Infos", "Capabilities", "Characteristics"];

  const methods = useForm({
    //resolver: yupResolver(opportunitiesDetailsSchema),
    defaultValues,
  });
  useEffect(() => {
    if (data) {
      handleModalClose();
      dispatch({ type: CREATE_OPPORTUNITY_ACTIONS.DEFAULT });
    }
  }, [data]);

  const validateForm = () => {
    if (activeStep === 0) {
      let isStepValid = true;
      if (clientName === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "client_name"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "client_name"));
      }
      if (principalFullName === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "principal_full_name"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "principal_full_name"));
      }
      if (region === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "region"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "region"));
      }
      if (country === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "country"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "country"));
      }
      if (bgStatus === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "bg_status"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "bg_status"));
      }
      if (entryDate === "") {
        setShowErrorMessageDetailsInfo(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "entry_date"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "entry_date"));
      }
      if (!isStepValid) {
        return false;
      }
      setShowErrorMessageDetailsInfo(false);
    } else if (activeStep === 2) {
      let isStepValid = true;
      if (entryStartDate === "") {
        setShowErrorMessageCommentsDescription(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "expected_start_date"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "expected_start_date"));
      }
      if (source === "") {
        setShowErrorMessageCommentsDescription(true);
        setListOfRequiredFieldsEmpty((prev) => [...prev, "source"]);
        isStepValid = false;
      }
      else {
        setListOfRequiredFieldsEmpty((prev) => prev.filter((item) => item !== "source"));
      }
      if (!isStepValid) {
        return false;
      }
      setShowErrorMessageCommentsDescription(false);
    }
    return true;
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const handleNext = () => {
    let isValid = validateForm();
    if (isValid) {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    setShowErrorAlert(true);
  }, [data]);
  const onSubmit = (newOpportunityObject) => {
    if (validForm) {
      let isValid = validateForm();

      if (isValid) {
        dispatch(createOpportunityAction(newOpportunityObject));
      }
    } else {
      // alert("Please fill all required fields");
    }
  };

  const handleBack = () => {
    // depending on the step, perform a validation check on required fields

    setActiveStep(activeStep - 1);
  };
  function getStepContent(step) {
    const allSteps = [
      <OpportunityDetailsInfo
        setClientName={setClientName}
        setPrincipalFullName={setPrincipalFullName}
        setEntryDate={setEntryDate}
        setRegion={setRegion}
        setCountry={setCountry}
        setBgStatus={setBgStatus}
        showErrorMessage={showErrorMessageDetailsInfo}
        listOfRequiredFieldsEmpty={listOfRequiredFieldsEmpty}
      />,
      <OpportunityCapabilities />,
      <OpportunityCommentsDescriptionInfo
        setEntryStartDate={setEntryStartDate}
        setSource={setSource}
        showErrorMessage={showErrorMessageCommentsDescription}
        listOfRequiredFieldsEmpty={listOfRequiredFieldsEmpty}
      />,
    ];
    return (
      <>
        {allSteps.map((stepCmp, index) => {
          return (
            <div
              key={index}
              style={{
                height: "100%",
              }}
              hidden={index !== step}
            >
              {stepCmp}
            </div>
          );
        })}
      </>
    );
  }

  if (error) {
    if (showErrorAlert) {
      alert("Error creating opportunity please check later !");
      setShowErrorAlert(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 2 }, p: { xs: 2, md: 3 } }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={() => handleModalClose()}
            >
              Cancel
            </Button>
            <Typography component="h1" variant="h4" align="center">
              New Opportunity
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "success.dark", // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "success.light", // circle color (ACTIVE)
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{ width: innerWidth / 1.2, height: innerHeight / 2.8 }}>
              {isLoading && <LoadingPanel />}
              {activeStep === steps.length && data ? (
                <div>
                  <Typography variant="h5" gutterBottom>
                    Thank you
                  </Typography>
                  <Typography variant="subtitle1">
                    New opportunity successfully created
                  </Typography>
                </div>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}

                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-20px",
                      right: 0,
                      fontStyle: "italic",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    (*) required fields
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      // bottom: 0,
                      marginBottom: "20px",
                      right: 0,
                    }}
                  >
                    {activeStep !== 0 ? (
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        type="button"
                        color="success"
                        style={{ marginTop: "-30px", left: 0 }}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        Back
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      {activeStep === steps.length - 1 ? (
                        <Button
                          variant="contained"
                          color="success"
                          type="submit"
                          style={{ marginTop: "-30px" }}
                          onClick={() => {
                            setValidForm(true);
                          }}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          type="button"
                          color="success"
                          style={{ marginTop: "-30px" }}
                          onClick={() => {
                            handleNext();
                            setValidForm(false);
                          }}
                        >
                          Next
                        </Button>
                      )}
                    </Box>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Paper>
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
}
