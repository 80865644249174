import React, { useState } from "react";
import { Paper, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useNavigate } from "react-router-dom";
import DisplayMode from "../dashboard/DisplayMode";

const useStyles = makeStyles((theme) => ({
  widgetWrapper: {
    display: "flex",
    minHeight: "100%",
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)",
    },
  },
  noWidgetShadow: {
    boxShadow: "none",
  },
}));

export default function Widget({ children, title, noBodyPadding, bodyClass, disableWidgetMenu, header, noHeaderPadding, headerClass, style, noWidgetShadow, opportunities, infoText, displayModeValues, displayModeSelectedValue, setDisplayModeSelectedValue, ...props }) {
  const classes = useStyles;
  const navigate = useNavigate();

  // local
  let [moreButtonRef, setMoreButtonRef] = useState(null);
  let [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const [relatedOppsAnchorEl, setRelatedOppsAnchorEl] = React.useState(null);
  const relatedOppsOpen = Boolean(relatedOppsAnchorEl);
  const relatedOppsHandleClick = (event) => {
    setRelatedOppsAnchorEl(event.currentTarget);
  };
  const relatedOppsHandleClose = () => {
    setRelatedOppsAnchorEl(null);
  };
  const handleViewDetails = (opportunityID) => {
    navigate(`/opportunities/${opportunityID}`);
  };

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100%",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          // boxShadow: theme.customShadows.widget
        }}
      >
        <div
          style={{
            padding: "24px 24px 16px 24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
          }}
        >
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" color="textSecondary" noWrap style={{ margin: "20px" }}>
                {title}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {displayModeValues && displayModeSelectedValue && setDisplayModeSelectedValue ? <DisplayMode style={{ marginRight: "20px" }} displayModeValues={displayModeValues} displayModeSelectedValue={displayModeSelectedValue} setDisplayModeSelectedValue={setDisplayModeSelectedValue} /> : null}
                {opportunities ? (
                  <>
                    <IconButton id="basic-button" aria-controls={relatedOppsOpen ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={relatedOppsOpen ? "true" : undefined} onClick={relatedOppsHandleClick}>
                      <BusinessCenterIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={relatedOppsAnchorEl}
                      open={relatedOppsOpen}
                      onClose={relatedOppsHandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {opportunities.map((opportunity) => {
                        return (
                          <MenuItem onClick={() => handleViewDetails(opportunity.id)}>
                            {opportunity.label} {opportunity.project_code !== null ? "[" + opportunity.project_code + "]" : ""}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                ) : null}
                {infoText ? (
                  <Tooltip title={infoText}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            </React.Fragment>
          )}
        </div>
        <div style={{ padding: "0px 24px 24px 24px" }}>
          <div style={style}>{children}</div>
        </div>
      </Paper>
    </div>
  );
}
