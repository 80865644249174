import React, { useState } from "react";
import "./index.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import RowComponent from "./RowComponent.jsx";
import theme from "../../utils/theme";

const TableComponent = (props) => {
  const pageSize = props.pageSize;
  //  const tableColumns = tableColumns;

  const { tableColumns, detailsFields, data, isModified, detailsExist, otherDetailsExist, otherDetailsFieldInDataSet, otherDetailsTitle, otherDetailsFields, user_id, isLoading, ComponentDetails, onDoubleClickOnRow } = props;

  return (
    <TableContainer
      style={{
        ...props.style,
        backgroundColor: "white",
        border: "1px solid grey",
        borderRadius: 5,
      }}
    >
      <Table aria-label="collapsible table" multiSelectable={true}>
        <TableHead>
          <TableRow>
            {isModified && <TableCell style={{ background: theme.palette.primary.main }} />}
            {tableColumns.map((cell, index) => {
              return (
                <TableCell key={index} align="left" style={{ background: theme.palette.primary.main }}>
                  <Typography
                    //fontSize={9}
                    fontWeight="bold"
                    style={{
                      lineHeight: 0.8,
                      fontSize: 12,
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "center",
                      lineHeight: 1.5,
                    }}
                  >
                    {cell.title.toUpperCase()}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ? data.map((line, index) => {
                return (
                  <RowComponent
                    style={{
                      textAlign: "center",

                      borderBottom: "none",
                    }}
                    rowData={line}
                    tableColumns={tableColumns ? tableColumns : []}
                    detailsFields={detailsFields}
                    isLoading={isLoading}
                    // right solution !!! fixe css     isModified={parseInt(user_id) === parseInt(line.created_by)}
                    isModified={isModified}
                    detailsExist={detailsExist}
                    otherDetailsData={line[otherDetailsFieldInDataSet] ? line[otherDetailsFieldInDataSet] : []}
                    otherDetailsTitle={otherDetailsTitle}
                    otherDetailsFields={otherDetailsFields}
                    otherDetailsExist={otherDetailsFieldInDataSet && otherDetailsExist}
                    ComponentDetails={ComponentDetails}
                    onDoubleClickOnRow={onDoubleClickOnRow}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
      {data && data.length === 0 && !isLoading && <div style={{ margin: "auto", alignItems: "center" }}>No Rows</div>}
    </TableContainer>
  );
};

export default TableComponent;
