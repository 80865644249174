import { getItem, setItem, removeItem } from "../localStorage";
import axios from "axios";
import { BACKEDN_URL } from "../baseURL";
export default class TokenStorage {
  static ACCESS_TOKEN = "access_token";
  static REFRESH_TOKEN = "refresh_token";
  static IDTOKEN = "IDTOKEN";
  static FULL_NAME = "FULL_NAME";
  static EMAIL = "EMAIL";
  static attempts = 0;
  static maxAttempts = 4;
  static ENDPOINT = BACKEDN_URL + "/api/v1/auth/token/refresh";

  static incrementAttempts = () => {
    this.attempts += 1;
  };

  static initialAttempts = () => {
    this.attempts = 0;
  };
  static getAttempts = () => {
    return this.attempts;
  };

  static isAuthenticated = () => {
    return this.getToken() !== null;
  };

  static getToken = () => {
    return getItem(this.ACCESS_TOKEN);
  };

  static getRefreshToken = () => {
    return getItem(this.REFRESH_TOKEN);
  };

  static storeToken = (token) => {
    setItem(this.ACCESS_TOKEN, token);
  };
  static storeRefreshToken = (refreshToken) => {
    setItem(this.REFRESH_TOKEN, refreshToken);
  };

  static storeIdToken = (idToken) => {
    setItem(this.IDTOKEN, idToken);
  };

  static storeFullName = (fullName) => {
    setItem(this.FULL_NAME, fullName);
  };
  static getFullName = () => {
    return getItem(this.FULL_NAME);
  };

  static getIdToken = () => {
    return getItem(this.IDTOKEN);
  };
  static storeEmail = (email) => {
    return setItem(this.EMAIL, email);
  };

  static getEmail = () => {
    return getItem(this.EMAIL);
  };
  static removeAll = () => {
    removeItem(this.REFRESH_TOKEN);
    removeItem(this.ACCESS_TOKEN);
    removeItem(this.EMAIL);
    removeItem(this.FULL_NAME);
    removeItem(this.IDTOKEN);
  };

  static getNewToken = () => {
    return new Promise((resolve, reject) => {
      const refreshToken = this.getRefreshToken();
      this.attempts = this.attempts + 1;
      axios
        .post(this.ENDPOINT, null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })
        .then((response) => {
          this.storeToken(response.data.access_token);
          this.storeRefreshToken(response.data.refresh_token);
          this.incrementAttempts();
          if (this.attempts <= this.maxAttempts) {
            resolve(response);
          } else {
            reject("MAX_ATTEMPTS");
          }
        })
        .catch((error) => reject(error));
    });
  };
}
