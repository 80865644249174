import { GET_FINANCES_ACTIONS, IMPORT_FINANCES_ACTIONS, GET_NCC_TABLE_ACTIONS, WIPE_IMPORT_FINANCES_ACTIONS } from "../ActionsTypes";

const initialStateData = {
    finances: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const GetFinanceReducer = (state = initialStateData, action) => {
    switch (action.type) {
        case GET_FINANCES_ACTIONS.REQUEST:
            return {
                ...state,
                finances: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case GET_FINANCES_ACTIONS.SUCCESS:
            return {
                ...state,
                finances: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case GET_FINANCES_ACTIONS.FAILED:
            return {
                ...state,
                finances: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const initialStateMessage = {
    message: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const ImportFinanceReducer = (state = initialStateMessage, action) => {
    switch (action.type) {
        case IMPORT_FINANCES_ACTIONS.REQUEST:
            return {
                ...state,
                message: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case IMPORT_FINANCES_ACTIONS.SUCCESS:
            return {
                ...state,
                message: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case IMPORT_FINANCES_ACTIONS.FAILED:
            return {
                ...state,
                message: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const initialStateWipeImportMessage = {
    wipeImportMessage: null,
    wipeImportError: null,
    wipeImportIsLoading: false,
    wipeImportIsError: false,
};

export const WipeImportFinanceReducer = (state = initialStateWipeImportMessage, action) => {
    switch (action.type) {
        case WIPE_IMPORT_FINANCES_ACTIONS.REQUEST:
            return {
                ...state,
                wipeImportMessage: null,
                wipeImportError: null,
                wipeImportIsLoading: true,
                wipeImportIsError: false,
            };
        case WIPE_IMPORT_FINANCES_ACTIONS.SUCCESS:
            return {
                ...state,
                wipeImportMessage: action.payload,
                wipeImportError: null,
                wipeImportIsLoading: false,
                wipeImportIsError: false,
            };
        case WIPE_IMPORT_FINANCES_ACTIONS.FAILED:
            return {
                ...state,
                wipeImportMessage: null,
                wipeImportError: action.payload,
                wipeImportIsLoading: false,
                wipeImportIsError: true,
            };
        default:
            return { ...state };
    }
};

const initialStateNccTable = {
    nccTableData: null,
    nccTableError: null,
    nccTableIsLoading: false,
    nccTableIsError: false,
};

export const FinanceNccTableReducer = (state = initialStateNccTable, action) => {
    switch (action.type) {
        case GET_NCC_TABLE_ACTIONS.REQUEST:
            return {
                ...state,
                nccTableData: null,
                nccTableError: null,
                nccTableIsLoading: true,
                nccTableIsError: false,
            };
        case GET_NCC_TABLE_ACTIONS.SUCCESS:
            return {
                ...state,
                nccTableData: action.payload,
                nccTableError: null,
                nccTableIsLoading: false,
                nccTableIsError: false,
            };
        case GET_NCC_TABLE_ACTIONS.FAILED:
            return {
                ...state,
                nccTableData: null,
                nccTableError: action.payload,
                nccTableIsLoading: false,
                nccTableIsError: true,
            };
        default:
            return { ...state };
    }
};
