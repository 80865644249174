import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { TextField } from "@material-ui/core";
import ItemCard from "./ItemCard";
import ConfirmationDialog from "./ConfirmationDialog";
import { useSelector, useDispatch } from "react-redux";
import { assignUserAdminAction, dismissUserAdminAction } from "../../utils/redux/action_creator/users_action_creators";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useEmployee } from "../../utils/services/people_api";
import { get_user_action } from "../../utils/redux/action_creator/users_action_creators";
const ADDING_NEW_USER_POP_UP_DIALOG = {
  title: "Confirm ADD",
  bodyMessage: "Are you sure you want add this  this user ?",
};

const DELETE_USER_POP_UP_DIALOG = {
  title: "Confirm Deletion",
  bodyMessage: "Are you sure you want to permanently remove this user ?",
};

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState();
  const [popupNotification, setPopupNotification] = useState(DELETE_USER_POP_UP_DIALOG);

  const [searchResult, setSearchResult] = useState([]);
  const [userToAdd, setUserToAdd] = useState(null);
  const [userToDelete, setUserToDelete] = useState([]);
  const [subUserList, setSubUserList] = useState([]);
  const [preferredFirstName, setPreferredFirstName] = useState(null);
  const [preferredLastName, setPreferredLastName] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { employeesData, isFetching, refetch } = useEmployee(preferredFirstName, preferredLastName);

  const searchForTarget = (fullName) => {
    const firstName = fullName.split(" ")[0];
    let lastName = "";
    for (let i = 1; i <= fullName.split(" ").length > 0; i++) {
      if (fullName.split(" ")[i] != undefined) {
        lastName = lastName + "" + fullName.split(" ")[i];
      }
    }
    setPreferredFirstName(firstName);
    setPreferredLastName(lastName);
  };

  useEffect(() => {
    dispatch(get_user_action());
  }, []);
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [preferredFirstName, preferredLastName]);

  const { user } = useSelector(
    // @ts-ignore
    (state) => state.GetUserReducer
  );

  const handleSelectedValue = (userDetails, selectedValue) => {
    if ("ADD" === selectedValue) {
      setUserToAdd(userDetails);
      setPopupNotification(ADDING_NEW_USER_POP_UP_DIALOG);
    } else {
      setUserToDelete([userDetails]);
      setPopupNotification(DELETE_USER_POP_UP_DIALOG);
    }
    handleClickOpen();
  };

  useEffect(() => {
    if (user != null && user.sub_users != null && user.sub_users.length > 0) {
      setSubUserList([...user.sub_users]);
    }
  }, [user]);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleDisagreeCloseDialog = () => {
    handleCloseDialog();
  };
  const handleAgreeCloseDialog = () => {
    // if add
    if (ADDING_NEW_USER_POP_UP_DIALOG === popupNotification) {
      if (searchResult != null) {
        dispatch(assignUserAdminAction(userToAdd));
        setSearchResult([]);
      }
    } else if (DELETE_USER_POP_UP_DIALOG === popupNotification) {
      // @ts-ignore
      dispatch(dismissUserAdminAction(userToDelete));

      // dispatch delete ection
    }
    handleCloseDialog();
  };

  return (
    <Grid container xs={12} sm={12} md={12} spacing={3} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          User Management
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={3} flexDirection="row">
        <TextField
          label="Full name"
          type={"text"}
          fullWidth
          InputProps={{
            endAdornment: <IconButton>{isFetching ? <CircularProgress size={25} /> : <SearchIcon onClick={searchForTarget} />}</IconButton>,
          }}
          onChange={(e) => searchForTarget(e.target.value)}
        />
      </Grid>

      <Grid item container xs={12} sm={12} md={12} spacing={1}>
        {subUserList.map((user, index) => {
          return (
            <Grid key={index} item xs={6} sm={3} md={3}>
              <ItemCard user_email={user.email} user_full_name={user.user_full_name} colorIndex={index} handleSelectedValue={handleSelectedValue} isSubuser={true} flag={"OLD"} />
            </Grid>
          );
        })}

        {employeesData.map((employee, index) => {
          return (
            <Grid key={index} item xs={12} sm={4} md={4}>
              <ItemCard user_email={employee.workEmail} user_full_name={employee.preferredDisplayName} colorIndex={-1} handleSelectedValue={handleSelectedValue} isSubuser={false} flag={"NEW"} />
            </Grid>
          );
        })}

        <ConfirmationDialog popupNotification={popupNotification} isOpen={isOpen} handleCloseDialog={handleCloseDialog} handleDisagreeCloseDialog={handleDisagreeCloseDialog} handleAgreeCloseDialog={handleAgreeCloseDialog} />
      </Grid>
    </Grid>
  );
};

export default UserManagement;
