export const CHARTCOLORS = [
  "#259d22",
  "#81ff7b",
  "#0aF208",
  "#236022",
  "#08ecae",
  "#0663ee",
  "#8923a1",
  "#EA052DFF",
  "#D8E704FF",
  "#6f08ec",
  "#ec6708",
  "#502204",
  "#f17d2e",
  "#92a108",
  "#5743e1",
  "#211d3b",
  "#806fe3",
  "#259d22",
  "#81ff7b",
  "#0aF208",
  "#236022",
  "#08ecae",
  "#0663ee",
  "#8923a1",
  "#EA052DFF",
  "#D8E704FF",
  "#6f08ec",
  "#ec6708",
  "#502204",
  "#f17d2e",
  "#92a108",
  "#5743e1",
  "#211d3b",
  "#806fe3",
];
