import { LOGIN, LOGOUT } from "../ActionsTypes";
import TokenStorage from "../../http_methods/TokenStorage";
import { get_user_action } from "./users_action_creators";
export const authentication = (authState) => async (dispatch) => {
  dispatch({ type: LOGIN.REQUEST });

  try {
    const { accessToken, idToken } = authState;

    const { claims } = idToken;
    const { name, email } = claims;

    TokenStorage.storeFullName(name);
    TokenStorage.storeEmail(email);
    TokenStorage.storeToken(accessToken.accessToken);
    TokenStorage.storeIdToken(idToken.idToken);
    dispatch({ type: LOGIN.SUCCESS, payload: true });
    dispatch(get_user_action());
  } catch (err) {
    dispatch({ type: LOGIN.FAILED, payload: err });
  }
};

export const logout_action_creator = () => async (dispatch) => {
  dispatch({ type: LOGIN.REQUEST });
  try {
    TokenStorage.removeAll();
    dispatch({ type: LOGOUT.SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: LOGOUT.FAILED, payload: error });
  }
};

//------------------------Get--Okta--Token---------------------------------------

//---------------------------------------------------------------
