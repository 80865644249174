import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { financesList, financesImport, financesWipeImport } from "../../utils/redux/action_creator/finances_creators";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Alert } from "@material-ui/lab";
import Button from "@mui/material/Button";
import CloudIcon from "@mui/icons-material/Cloud";
import { DataGrid } from "@mui/x-data-grid";
// import DropdownButton from "../../components/DropdownButton";
import { FINANCE_TABLE_COLUMNS_TO_SHOW, FINANCE_TABLE_COLUMNS_TO_HIDE } from "../../utils/static/Constants";
import { convertArrayToCsvString, downloadCsvFile } from "../../utils/export-csv";
import { CircularProgress, Tooltip, Zoom } from "@mui/material";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RepartitionIcon from "@mui/icons-material/Repartition";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Papa from "papaparse";
import { REQUIRED_COLUMNS_FINANCE_FILE_IMPORT } from "../../utils/appConstants";

// let do_get_all_finance_data = false;
const tooltipImportMessage = `Notes:<br/> - Expected file type: .xlsb <br/> - Only recent records will be imported`;
const tooltipWipeMessage = `This operation will:<br/> - Wipe the database of financial data <br/> - Import the content of an .xlsb file`;
const wipeValidationMessage = "Yes, wipe and refresh financial data";

export default function FinanceManagement(props) {
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false);
    const [successWipeImport, setSuccessWipeImport] = useState(false);
    const [errorWipeImport, setErrorWipeImport] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [filter, setFilter] = useState({});
    const [sorting, setSorting] = useState({});
    const [numberOfRowsImported, setNumberOfRowsImported] = useState(0);
    const [financeProcessedData, setFinanceProcessedData] = useState([]);
    const [financeProcessedColumns, setFinanceProcessedColumns] = useState([]);
    // const [selectedRows, setSelectedRows] = useState([]);
    const [isImportLoading, setIsImportLoading] = useState(false);
    const [isDataGridLoading, setIsDataGridLoading] = useState(true);
    const [isWipeImportLoading, setIsWipeImportLoading] = useState(false);
    // const [isFirstRun, setIsFirstRun] = useState(true);
    const [openWipeValidation, setOpenWipeValidation] = useState(false);
    const [validateWipeComfirmationMessage, setValidateWipeComfirmationMessage] = useState("");
    const [inputFileRef, setInputFileRef] = useState(null);
    const [inputWipeFileRef, setInputWipeFileRef] = useState(null);
    const { finances, error, loading, isError } = useSelector((state) => state.GetFinanceReducer);
    const { message } = useSelector((state) => state.ImportFinanceReducer);
    const { wipeImportMessage } = useSelector((state) => state.WipeImportFinanceReducer);

    const handleClickOpenWipeDialog = () => {
        setOpenWipeValidation(true);
    };

    const handleCloseWipeDialog = () => {
        setOpenWipeValidation(false);
    };

    const handleWipeValidationDialog = () => {
        if (validateWipeComfirmationMessage === wipeValidationMessage) {
            handleWipeFileUploadBtnClick();
            return;
        }

        handleCloseWipeDialog();
    };

    let rowIdTracker = 0;

    const handleFileUploadBtnClick = () => {
        inputFileRef.click();
    };
    const handleWipeFileUploadBtnClick = () => {
        inputWipeFileRef.click();
    };

    useEffect(() => {
        if (message) {
            setSuccess(true);
            setNumberOfRowsImported(message.imported);
            setIsImportLoading(false);
            setFinanceProcessedData([]);
            setIsDataGridLoading(true);
            dispatch(
                financesList({
                    page: 0,
                    page_size: pageSize,
                    column_field: filter?.columnField,
                    operator_value: filter?.operatorValue,
                    value: filter?.value,
                    // get_all: true, // do_get_all_finance_data
                }),
            );
        }
    }, [message]);

    useEffect(() => {
        if (wipeImportMessage?.status === "success") {
            setSuccessWipeImport(true);
            setErrorWipeImport(false);
        } else if (wipeImportMessage?.status === "fail") {
            setErrorWipeImport(true);
            setSuccessWipeImport(false);
        }
        setIsWipeImportLoading(false);
    }, [wipeImportMessage]);

    useEffect(() => {
        // TODO: to refactor. Necessity level: low
        const columns = FINANCE_TABLE_COLUMNS_TO_SHOW.map((key) => {
            return {
                field: key,
                headerName: key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                sortable: true,
                width: 150,
                hide: false,
            };
        });

        // reorder column fields based on the order in the FINANCE_TABLE_COLUMNS_TO_SHOW array
        columns.sort((a, b) => {
            return FINANCE_TABLE_COLUMNS_TO_SHOW.indexOf(a.field) - FINANCE_TABLE_COLUMNS_TO_SHOW.indexOf(b.field);
        });

        // add FINANCE_TABLE_COLUMNS_TO_HIDE to the end of the columns array
        FINANCE_TABLE_COLUMNS_TO_HIDE.forEach((key) => {
            columns.push({
                field: key,
                headerName: key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                sortable: true,
                width: 150,
                hide: true,
                status: false,
            });
        });

        setFinanceProcessedColumns(columns);
        setFinanceProcessedData([]);
        setIsDataGridLoading(true);
        dispatch(
            financesList({
                page: currentPage,
                page_size: pageSize,
                column_field: filter?.columnField,
                operator_value: filter?.operatorValue,
                value: filter?.value,
                // get_all: true, // do_get_all_finance_data
            }),
        );
    }, []);

    const handleFileUpload = (event) => {
        setIsImportLoading(true);
        // check if its an excel file
        if (
            event.target.files[0].type === "application/vnd.ms-excel" ||
            event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            event.target.files[0].type === "text/csv" ||
            event.target.files[0].type === "application/vnd.ms-excel.sheet.binary.macroenabled.12"
            // also accept .xlsb files
        ) {
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            dispatch(financesImport(formData));
        } else {
            setErrorMessage("Invalid file type. Please upload an excel file. (.xlsb, .xlsx, .csv)");
        }
    };

    const handleWipeFileUpload = (event) => {
        setIsWipeImportLoading(true);
        setOpenWipeValidation(false);

        const file = event.target.files[0];

        // Check file type
        if (
            // file.type === "application/vnd.ms-excel" ||
            // file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "text/csv"
            // || file.type === "application/vnd.ms-excel.sheet.binary.macroenabled.12"
        ) {
            // Read the first 5024 bytes of the file
            const blob = file.slice(0, 5024);
            const reader = new FileReader();

            reader.onload = function (e) {
                const csvData = e.target.result;

                // Parse the CSV data
                Papa.parse(csvData, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        if (!results.data || results.data.length === 0 || typeof results.data[0] !== "object") {
                            setErrorMessage("The uploaded file is empty or invalid.");
                            setIsWipeImportLoading(false);
                            return;
                        }

                        // Use results.meta.fields for columns presence validation
                        const availableColumns = results.meta.fields;
                        const missingColumns = REQUIRED_COLUMNS_FINANCE_FILE_IMPORT.filter((col) => !availableColumns.includes(col));

                        if (missingColumns.length > 0) {
                            setErrorMessage(`Missing columns: ${missingColumns.join(", ")}`);
                            setIsWipeImportLoading(false);
                            return;
                        }

                        // Proceed with API call
                        const formData = new FormData();
                        formData.append("file", file);
                        dispatch(financesWipeImport(formData));
                    },
                });
            };

            reader.readAsText(blob); // Read the first 5024 bytes
        } else {
            setIsWipeImportLoading(false);
            setErrorMessage("Invalid file type. Please upload an excel file. (.xlsb, .xlsx, .csv)");
        }
    };

    const handleChangeRowsPerPageInfo = (newValue) => {
        setFinanceProcessedData([]);
        setIsDataGridLoading(true);
        // Used to bypass material-ui's datagrid community limitation. NaN value is the representation of the "All" option in the dropdown
        if (newValue === finances?.pagination?.total) {
            setPageSize(0);
            // // archive was used as an attempt to immplement server side pagination
            // do_get_all_finance_data = true;
            dispatch(
                financesList({
                    page: 0,
                    page_size: newValue,
                    column_field: filter?.columnField,
                    operator_value: filter?.operatorValue,
                    value: filter?.value,
                }),
            );
        } else {
            setPageSize(parseInt(newValue, 10));
            // do_get_all_finance_data = false;

            // // archive was used as an attempt to immplement server side pagination
            // do_get_all_finance_data = false;
            dispatch(
                financesList({
                    page: 0,
                    page_size: newValue,
                    column_field: filter?.columnField,
                    operator_value: filter?.operatorValue,
                    value: filter?.value,
                }),
            );
        }
    };

    const handleSortModelChange = (sortModel) => {
        // Here you save the data you need from the sort model
        setFinanceProcessedData([]);
        setIsDataGridLoading(true);
        setSorting(sortModel);
    };

    useEffect(() => {
        setIsDataGridLoading(true);
        dispatch(
            financesList({
                page: currentPage,
                page_size: pageSize,
                column_field: filter?.columnField,
                operator_value: filter?.operatorValue,
                value: filter?.value,
                sortingField: sorting[0]?.field,
                sortingMode: sorting[0]?.sort,
            }),
        );
    }, [sorting]);

    // archive was used as an attempt to immplement server side pagination
    const handleChangePageInfo = (newPage) => {
        // if (
        //   (newPage === 0 && currentPage === 0)
        //   // ||
        //   // (newPage === 0 && currentPage > 1) // TODO: investigate why this is needed
        // ) {
        //   return;
        // }
        setCurrentPage(newPage);

        setFinanceProcessedData([]);
        setIsDataGridLoading(true);
        dispatch(
            financesList({
                page: newPage,
                page_size: pageSize,
                column_field: filter?.columnField,
                operator_value: filter?.operatorValue,
                value: filter?.value,
            }),
        );
    };

    useEffect(() => {
        if (finances?.data?.length > 0) {
            // setIsFirstRun(false);
            const data = finances?.data.map((row) => {
                return { ...row, id: rowIdTracker++ };
            });

            setFinanceProcessedData(data);
        }
    }, [finances]);

    useEffect(() => {
        if (financeProcessedData.length > 0) {
            setIsDataGridLoading(false);
        }
    }, [financeProcessedData]);

    const onFilterChange = useCallback((filterModel) => {
        if (filterModel?.items[0]?.value !== undefined) {
            setFilter({
                columnField: filterModel?.items[0]?.columnField,
                operatorValue: filterModel?.items[0]?.operatorValue,
                value: filterModel?.items[0]?.value,
            });
        } else {
            setFilter({});
        }
    }, []);

    useEffect(() => {
        setFinanceProcessedData([]);
        setIsDataGridLoading(true);
        if (filter && filter.columnField && filter.operatorValue && filter.value) {
            dispatch(
                financesList({
                    page: 0,
                    page_size: pageSize,
                    column_field: filter?.columnField,
                    operator_value: filter?.operatorValue,
                    value: filter?.value,
                }),
            );
        } else {
            dispatch(
                financesList({
                    page: currentPage,
                    page_size: pageSize,
                }),
            );
        }
    }, [filter]);

    // FinanceManagement.propTypes = {
    //   count: PropTypes.number.isRequired,
    //   onPageChange: PropTypes.func.isRequired,
    //   page: PropTypes.number.isRequired,
    //   rowsPerPage: PropTypes.number.isRequired,
    // };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <>
                <div style={{ marginBottom: "20px", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {successWipeImport && <Alert severity="success">{wipeImportMessage?.message}</Alert>}
                        {errorWipeImport && <Alert severity="error">{wipeImportMessage?.message}</Alert>}
                        {success && <Alert severity="success">{numberOfRowsImported} rows imported successfully!</Alert>}
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipWipeMessage }} />} disableInteractive TransitionComponent={Zoom}>
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={handleClickOpenWipeDialog}
                            style={{
                                marginRight: "35px",
                                textTransform: "none",
                                gap: "10px",
                            }}
                        >
                            {isWipeImportLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <>
                                    <div>Update</div>
                                    <RepartitionIcon />
                                </>
                            )}
                        </Button>
                    </Tooltip>
                    <Dialog open={openWipeValidation} onClose={handleCloseWipeDialog}>
                        <DialogTitle>Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <span dangerouslySetInnerHTML={{ __html: tooltipWipeMessage }} />
                                <br />
                                <br />
                                To validate this operation, please enter: <br />
                                <div
                                    style={{
                                        width: "fit-content",
                                        margin: "auto",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "red",
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                            fontSize: "0.8em",
                                            backgroundColor: "rgb(220,220,220)",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            padding: "5px",
                                        }}
                                    >
                                        {wipeValidationMessage}
                                    </span>
                                </div>
                                <div style={{ display: "none" }}>
                                    <input
                                        ref={(input) => setInputWipeFileRef(input)}
                                        type="file"
                                        onChange={(event) => {
                                            handleWipeFileUpload(event);
                                        }}
                                    />
                                </div>
                            </DialogContentText>
                            <TextField
                                autoFocus
                                color="success"
                                id="wipeValidationId"
                                label="Validation Message"
                                fullWidth
                                variant="outlined"
                                value={validateWipeComfirmationMessage}
                                onChange={(event) => {
                                    setValidateWipeComfirmationMessage(event.target.value);
                                }}
                                style={{ marginTop: "20px" }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseWipeDialog} style={{ color: "rgb(150,150,150)" }}>
                                Cancel
                            </Button>
                            <Button onClick={handleWipeValidationDialog} style={{ color: "rgb(69,123,59)" }}>
                                Validate
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipImportMessage }} />} disableInteractive TransitionComponent={Zoom}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleFileUploadBtnClick}
                            disabled={true}
                            style={{
                                marginRight: "35px",
                                textTransform: "none",
                                gap: "10px",
                            }}
                        >
                            {isImportLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <>
                                    <div>Entries</div>
                                    <AddCircleOutlineIcon />
                                </>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <div style={{ display: "none" }}>
                    <input
                        ref={(input) => setInputFileRef(input)}
                        type="file"
                        onChange={(event) => {
                            handleFileUpload(event);
                        }}
                    />
                </div>
                <>
                    <div
                        style={{
                            width: "98%",
                            height: "820px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            marginRight: "35px",
                            // marginBottom: "0px",
                            marginTop: "20px",
                            flexDirection: "column",
                            marginBottom: "50px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: "25px",
                                paddingTop: "40px",
                            }}
                        >
                            {/* <DropdownButton
                options={[
                  {
                    label: "CSV",
                    onClick: () => {
                      let data =
                        selectedRows.length > 0 ? selectedRows : finances?.data;
                      downloadCsvFile(
                        convertArrayToCsvString(financeProcessedData),
                        `finance_data_${moment().format("YYYY-MM-DD")}.csv`
                      );
                    },
                  },
                ]}
              /> */}
                        </div>
                        <TableContainer
                            component={Paper}
                            autoSize={true}
                            style={{
                                width: "96%",
                                height: "700px",
                                marginTop: "20px",
                                marginLeft: "35px",
                                marginBottom: "100px",
                            }}
                        >
                            <DataGrid
                                rows={financeProcessedData}
                                columns={financeProcessedColumns}
                                // checkboxSelection
                                // setup pagination
                                filterMode="server"
                                pagination
                                pageSize={pageSize}
                                page={currentPage}
                                paginationMode="server"
                                onFilterModelChange={onFilterChange}
                                rowCount={finances?.pagination?.total || 0}
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100,
                                    // +finances?.pagination?.total,
                                ]}
                                onPageChange={handleChangePageInfo}
                                onPageSizeChange={handleChangeRowsPerPageInfo}
                                // paginationMode={do_get_all_finance_data ? "server" : "client"} // Used to bypass material-ui's datagrid community limitation
                                // onSelectionModelChange={(newSelection) => {
                                //   setSelectedRows(newSelection);
                                // }}
                                // set is loading
                                loading={isDataGridLoading}
                                sortingMode="server"
                                onSortModelChange={handleSortModelChange}
                            />
                        </TableContainer>
                    </div>
                </>
            </>
        </div>
    );
}
