const treeData = [
  {
    name: "",
    attributes: {
      role: "GAMMA Lead",
      participants: 7,
    },
    children: [
      {
        name: "Bjoern Brings",
        attributes: {
          role: "GAMMA",
          participants: 2,
        },
      },
      {
        name: "Federico Folch",
        attributes: {
          role: "GAMMA",
          participants: 3,
        },
      },
      {
        name: "Eduardo Casanova",
        attributes: {
          role: "GAMMA",
          participants: 8,
        },
      },
    ],
  },
];

export default treeData;
