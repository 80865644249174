import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import clone from "clone";
import NodeLabel from "./NodeLabel";
import "./styles.css";

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;
//https://codesandbox.io/s/wnm0n12265?file=/src/data.js

export const edgeFix = () => {
  /* eslint-disable */
  new MutationObserver((muts) => {
    for (var i = muts.length - 1; i >= 0; i--) {
      var mut = muts[i],
        objs = mut.target.querySelectorAll("foreignObject");
      for (var j = objs.length - 1; j >= 0; j--) {
        var obj = objs[j];
        var val = obj.style.display;
        obj.style.display = "none";
        obj.getBBox();
        obj.style.display = val;
      }
    }
  }).observe(document.documentElement, {
    attributes: true,
    attributeFilter: ["transform"],
    subtree: true,
  });
  /* eslint-enable */
};

const TreeChart = (props) => {
  let tree = null;
  let treeWrapper = null;
  //const []
  const [toCollapse, setToCollapse] = useState(false);
  const [nodeX, setNodeX] = useState(246);
  const [nodeY, setNodeY] = useState(150);
  const [translate, setTranslate] = useState();

  useEffect(() => {
    setDimensions();
  }, []);

  const setDimensions = () => {
    if (!treeWrapper) {
      return;
    }

    const dimensions = treeWrapper.getBoundingClientRect();
    setTranslate({
      x: props.isChrome ? dimensions.width / 2 : dimensions.width / 2 - 70,
      y: props.isChrome ? -100 : dimensions.width / 800 - 140,
    });
  };

  const highlightActiveNode = (nodeObject) => {
    let nodeObj = nodeObject;
    if (!nodeObj) {
      return;
    }
    const nodes = Array.from(treeWrapper.getElementsByTagName("foreignObject"));

    // remove highlight
    nodes.forEach((node) => {
      node.firstChild.classList.remove("activeNode");
    });
    // set highlight
    const currNode = nodes.filter((node) => node.parentElement.getAttribute("id") === nodeObj.id)[0];
    currNode.firstChild.classList.add("activeNode");
    // highlight parents
    const highlightParents = () => {
      while (nodeObj.parent) {
        // eslint-disable-line no-param-reassign
        nodeObj = nodeObj.parent;
        const parent = nodes.filter((node) => node.parentElement.getAttribute("id") === nodeObj.id)[0]; // eslint-disable-line no-loop-func
        parent.firstChild.classList.add("activeNode");
        highlightParents();
      }
    };
    highlightParents();
  };

  const handleActiveNode = (nodeObj) => {
    highlightActiveNode(nodeObj);

    if (!nodeObj || nodeObj._collapsed) {
      return;
    }

    const parentObj = nodeObj.parent;
    const data = clone(tree.data);

    if (parentObj && tree) {
      const nodesToBeCollapsed = Array.from(parentObj.children.filter((c) => c.id !== nodeObj.id && !c.collapsed && c.children));
      nodesToBeCollapsed.map(async (o) => {
        const matches = tree.findNodesById(o.id, data, []);
        tree.collapseNode(matches[0]);
        await tree.setState({ data });
        return o;
      });
    }
  };

  if (isEdge) {
    edgeFix();
  }

  const renderForeignObjectNode = ({ nodeData, foreignObjectProps }) => {
    return (
      <foreignObject {...foreignObjectProps}>
        <div onClick={() => handleActiveNode(nodeData.nodeDatum)}>
          <NodeLabel className="label" nodeData={nodeData.nodeDatum} />
        </div>
      </foreignObject>
    );
  };

  const path = (d) => {
    if (props.isChrome) {
      return `
         M${d.source.x},${d.source.y + 205}
         L${d.target.x}, ${d.source.y + 270}
         L${d.target.x},${d.target.y + 190}`;
    }
    // other browsers ...
    return `
          M${d.source.x},${d.source.y + 205}
         L${d.target.x}, ${d.source.y + 270}
         L${d.target.x},${d.target.y + 190}`;
  };

  return (
    <div
      className="treeWrapper"
      ref={(r) => {
        treeWrapper = r;
      }}
    >
      <Tree
        ref={(r) => {
          tree = r;
        }}
        //   onNodeClick={(nodeObj) => handleActiveNode(nodeObj)}
        data={props.treeData}
        //     initialDepth={1}
        translate={translate}
        orientation="vertical"
        shouldCollapseNeighborNodes={true}
        // nodeSvgShape={{ shape: "none" }}
        nodeSize={{ x: nodeX + 8, y: nodeY }}
        zoomable={false}
        pathFunc={(d) => path(d)}
        renderCustomNodeElement={(nodeDatum) =>
          renderForeignObjectNode({
            nodeData: nodeDatum,
            foreignObjectProps: {
              x: (nodeX / 2) * -1,
              y: 150,
            },
          })
        }
      />
    </div>
  );
};
export default TreeChart;
