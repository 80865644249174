import { GET_EMPLOYEES_ACTIONS, IMPORT_EMPLOYEES_ACTIONS, PREPARE_EMPLOYEES_IMPORT_ACTIONS } from "../ActionsTypes";
import { get, post } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const employeesList = (parameters) => async (dispatch) => {
  dispatch({ type: GET_EMPLOYEES_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    const result = await get(BACKEDN_URL + "/api/v1/employees/get", params);
    dispatch({ type: GET_EMPLOYEES_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: GET_EMPLOYEES_ACTIONS.FAILED, payload: err });
  }
};

export const employeeImport = (formData) => async (dispatch) => {
  dispatch({ type: IMPORT_EMPLOYEES_ACTIONS.REQUEST });
  try {
    const email = TokenStorage.getEmail();
    formData.append("email", email);
    const response = await fetch(BACKEDN_URL + "/api/v1/employees/import", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    dispatch({ type: IMPORT_EMPLOYEES_ACTIONS.SUCCESS, payload: result });
  } catch (err) {
    dispatch({ type: IMPORT_EMPLOYEES_ACTIONS.FAILED, payload: err });
  }
};

export const employeePrepareImport = (formData) => async (dispatch) => {
  dispatch({ type: PREPARE_EMPLOYEES_IMPORT_ACTIONS.REQUEST });

  try {
    const email = TokenStorage.getEmail();
    formData.append("email", email);
    const response = await fetch(BACKEDN_URL + "/api/v1/employees/prepare/import", {
      method: "POST",
      body: formData,
    });
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let csvData = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      csvData += decoder.decode(value);
    }

    dispatch({
      type: PREPARE_EMPLOYEES_IMPORT_ACTIONS.SUCCESS,
      payload: csvData,
    });
  } catch (err) {
    dispatch({ type: PREPARE_EMPLOYEES_IMPORT_ACTIONS.FAILED, payload: err });
  }
};
