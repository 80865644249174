import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import Widget from "../../components/Widget/Widget";
import TotalNCCGraph from "../../components/dashboard/graphs/TotalNCCGraph";

const NccTable = (props) => {
    const { data, isLoading, title } = props;
    const { searchFieldsData, searchAction } = useSelector((state) => state.SearchFilterReducer);
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const [inputFileRef, setInputFileRef] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [displayModeValues, setDisplayModeValues] = useState(["Table", "Chart"]);
    const [displayModeSelectedValue, setDisplayModeSelectedValue] = useState(displayModeValues[0]);

    const columns = [
        { field: "project_name", headerName: "Project name", width: 220 },
        { field: "case_code", headerName: "Case code", flex: 1 },
        { field: "case_type", headerName: "Case type", flex: 1 },
        { field: "total_ncc", headerName: "Total Ncc", flex: 1 },
        {
            field: "total_Working_hours",
            headerName: "Total working hours",
            type: "number",
            width: 225,
            flex: 1,
        },
    ];

    return (
        <Widget
            title={title}
            upperTitle
            displayModeValues={displayModeValues}
            displayModeSelectedValue={displayModeSelectedValue}
            setDisplayModeSelectedValue={setDisplayModeSelectedValue}
        >
            <div style={{ height: 550, width: "100%", backgroundColor: "white" }}>
                {displayModeSelectedValue === displayModeValues[0] ? ( // table mode
                    <DataGrid
                        rows={data ? data : []}
                        columns={columns}
                        pageSize={8}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                        onRowDoubleClick={(params, event) => {
                            window.open(`/opportunities/${params.row.project_id}`, "_blank");
                        }}
                    />
                ) : (
                    <TotalNCCGraph data={data} /> // chart mode
                )}
            </div>
        </Widget>
    );
};
export default NccTable;
