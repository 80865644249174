import * as Yup from "yup";
import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, Grid } from "@mui/material";
// @ts-ignore
import { FormProvider, RHFTextField } from "../../components/hook-form";
import RHFSelectField from "../../components/hook-form/RHFSelectField.jsx";
import RHFDatePicker from "../../components/hook-form/RHFDateField.jsx";
import ContactFilterContext from "../../utils/context/contact-filter-context.jsx";
import { OUTREACH_TYPE_ITEMS, REGIONS } from "../../utils/static/Constants";
import { useSelector } from "react-redux";
export const ContactFormFilter = (props) => {
  const { clearForm } = props;
  const defaultValues = {
    oppl_full_name: null,
    from_outreach_date: null,
    to_outreach_date: null,
    created_by: null,
    opple_region: null,
    outreach_type: null,
  };
  const { user } = useSelector((state) => state.GetUserReducer);
  const { first_name, last_name, email_id, region, sub_users } = user || {};
  const isAdmin = user ? user.is_admin : false;
  const contactFilter = useContext(ContactFilterContext);
  const MANAGER_MENU_ITEMS_LIST = sub_users
    ? [
        {
          label: "Me",
          value: email_id,
        },
        ...sub_users.map((user, index) => {
          return {
            label: user.user_full_name,
            value: user.user_email,
          };
        }),
      ]
    : [];

  const methods = useForm({
    //resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    contactFilter.submit(values);
  };

  const selectItems = OUTREACH_TYPE_ITEMS;
  const REGION_MENU_ITEMS = REGIONS;
  const handleClearAll = (values) => {};

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        justifyContent="space-arround"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
          paddingBottom: "20px",
          paddingTop: "20px",
          paddingLeft: "20px",
          marginBottom: "10px",
          marginTop: "10px",
          //    justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={8} xl={8} container spacing={2}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6} item>
              <RHFDatePicker name="from_outreach_date" label="From" />
            </Grid>
            <Grid xs={12} sm={6} md={6} item>
              <RHFDatePicker name="to_outreach_date" label="To" />
            </Grid>
            {isAdmin && (
              <Grid item xs={12} sm={4} md={4}>
                <RHFSelectField
                  label={"Owner"}
                  name="created_by"
                  menuItems={MANAGER_MENU_ITEMS_LIST}
                />
              </Grid>
            )}

            <Grid xs={12} sm={4} md={4} item>
              <RHFSelectField
                label={"Region"}
                name="opple_region"
                menuItems={REGION_MENU_ITEMS}
              />
            </Grid>
            <Grid xs={12} sm={4} md={4} item>
              <RHFSelectField
                name="outreach_type"
                label="Type"
                menuItems={selectItems}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            name="applyFilters"
            //            onClick={handleClick}
            color="success"
            type="submit"
            sx={{
              minWidth: 100,
              marginBottom: 2,
            }}
            style={{ color: "#ffffff", height: "40px", width: "100%" }}
          >
            Apply
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            name="clearFilters"
            onClick={() => clearForm({ ...defaultValues })}
            sx={{ minWidth: 100 }}
            style={{
              backgroundColor: "#bfbfbf",
              color: "#ffffff",
              height: "40px",
              width: "100%",
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
