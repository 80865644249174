import React, { useMemo, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ContactPersonalInfosDetails, ContactCharacteristicsDetails } from "./new-contact/contact-details";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { FormProvider } from "../../components/hook-form";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { create_contact_action } from "../../utils/redux/action_creator/contacts_creators";
import { useSelector, useDispatch } from "react-redux";
import { peopleApiAction } from "../../utils/redux/action_creator/users_action_creators";
import { LoadingPanel } from "../../components/loading-panel";
import { CREATE_CONTACT_ACTIONS } from "../../utils/redux/ActionsTypes";
export function NewContact(props) {
  const { handleModalClose } = props;
  const defaultValues = {
    oppl_email: null,
    outreach_date: null,
    oppl_full_name: null,
    opple_region: null,
    team_manager: null,
    primaryWorkPhone: null,
    homeOffice: null,
    industry_classification: null,
    outreach_type: null,
  };

  const steps = ["Details Infos", "Characteristics"];
  const theme = createTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validForm, setValidForm] = useState(false);
  const [preferredFirstName, setPreferredFirstName] = useState(null);
  const [preferredLastName, setPreferredLastName] = useState(null);
  const [employeeSelectItem, setEmployeeSelectItem] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(true);

  const dispatch = useDispatch();
  const { innerWidth, innerHeight } = window;
  const { data, error, isLoading, isError } = useSelector((state) => state.CreateContactReducer);
  const { peopleData, peopleIsLoading, peopleReject, peopleErrorMessage } = useSelector((state) => state.PeopleApiReducer);

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState({});

  // unsed for form validation
  const [showErrorDetailsInfo, setShowErrorDetailsInfo] = useState(false);
  const [showErrorCharacteristicsDetails, setShowErrorCharacteristicsDetails] = useState(false);
  const [connectionType, setConnectionType] = useState("");
  const [classification, setClassification] = useState("");
  const validateForm = () => {
    if (activeStep === 0) {
      if (selectedEmployee.length === 0) {
        setShowErrorDetailsInfo(true);
        return false;
      }
    }
    if (activeStep === 1) {
      if (connectionType === "") {
        setShowErrorCharacteristicsDetails(true);
        return false;
      }
      if (classification === "") {
        setShowErrorCharacteristicsDetails(true);
        return false;
      }
      setShowErrorCharacteristicsDetails(false);
    }
    return true;
  };

  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleNext = () => {
    let isValid = validateForm();
    if (isValid) {
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  //---------reset----------
  useEffect(() => {
    if (preferredFirstName != null) dispatch(peopleApiAction(preferredFirstName, preferredLastName));
  }, [preferredFirstName, preferredLastName]);

  useEffect(() => {
    if (peopleData && peopleData.length > 0) {
      setEmployeeSelectItem(
        peopleData
          .filter((people) => people.workEmail != null && people.workEmail.length > 0)
          .map((employee, index) => {
            return {
              label: employee.preferredFirstName + " " + employee.preferredLastName,
              value: employee.workEmail,
            };
          })
      );
    }
  }, [peopleData]);

  useEffect(() => {
    if (selectedEmployee.length > 0 && peopleData != null && peopleData.length > 0) {
      const selectedEmp = peopleData.filter((employee) => employee.workEmail === selectedEmployee)[0];
      if (selectedEmp != undefined && selectedEmp != null) {
        const { workEmail, hostOfficeRegion, preferredFirstName, preferredLastName, supervisoryOrgName, primaryWorkPhone, homeOffice } = selectedEmp;
        let newDdefaultValues = {};
        newDdefaultValues.oppl_email = workEmail;
        newDdefaultValues.opple_region = hostOfficeRegion;
        newDdefaultValues.team_manager = supervisoryOrgName;
        newDdefaultValues.oppl_full_name = preferredFirstName + " " + preferredLastName;
        newDdefaultValues.primaryWorkPhone = primaryWorkPhone;
        newDdefaultValues.homeOffice = homeOffice;
        reset({ ...defaultValues, ...newDdefaultValues });
      }
    }
  }, [selectedEmployee]);

  const searchForTarget = (fullName) => {
    const firstName = fullName.split(" ")[0];
    let lastName = "";
    for (let i = 1; i <= fullName.split(" ").length > 0; i++) {
      if (fullName.split(" ")[i] != undefined) {
        lastName = lastName + "" + fullName.split(" ")[i];
      }
    }
    setPreferredFirstName(firstName);
    setPreferredLastName(lastName);
  };

  const onSubmit = (contactData) => {
    if (validForm) {
      let isValid = validateForm();
      if (isValid) {
        dispatch(create_contact_action(contactData));
      }
    }
  };

  useEffect(() => {
    if (data) {
      handleModalClose();
      dispatch({ type: CREATE_CONTACT_ACTIONS.DEFAULT });
    }
  }, [data]);

  if (error) {
    if (showErrorAlert) {
      alert("Error creating contact please check later !");
      setShowErrorAlert(false);
    }
  }

  function getStepContent(step) {
    const allSteps = [
      <ContactPersonalInfosDetails
        searchForTarget={searchForTarget}
        employeeSelectItem={employeeSelectItem}
        isLoading={peopleIsLoading}
        selectedEmployee={setSelectedEmployee}
        showErrorMessage={showErrorDetailsInfo}
      />,
      <ContactCharacteristicsDetails
        setConnectionType={setConnectionType}
        setClassification={setClassification}
        showErrorMessage={showErrorCharacteristicsDetails}
        connectionType={connectionType}
        classification={classification}
      />
    ];
    return (
      <>
        {allSteps.map((stepCmp, index) => {
          return (
            <div
              key={index}
              style={{
                height: "100%",
              }}
              hidden={index !== step}
            >
              {stepCmp}
            </div>
          );
        })}
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 2 }, p: { xs: 2, md: 3 } }}>
            <Button variant="outlined" color="success" onClick={() => handleModalClose()}>
              Cancel
            </Button>
            <Typography component="h1" variant="h4" align="center">
              New Contact
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "success.dark", // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "success.light", // circle color (ACTIVE)
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{ width: innerWidth / 1.2, height: innerHeight / 3.5 }}>
              {isLoading && <LoadingPanel />}
              {activeStep === steps.length ? (
                <div>
                  <Typography variant="h5" gutterBottom>
                    Thank you
                  </Typography>
                  <Typography variant="subtitle1">New Contact successfully created</Typography>
                </div>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-20px",
                      right: 0,
                      fontStyle: "italic",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    (*) required fields
                  </span>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    {activeStep !== 0 ? (
                      <Button onClick={handleBack} variant="contained" type="button" color="success" style={{ marginTop: "-40px", left: 0 }} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        // sx={{ mt: 3, ml: 1 }}
                        type="submit"
                        color="success"
                        style={{ marginTop: "-30px" }}
                        onClick={() => {
                          setValidForm(true);
                        }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        type="button"
                        color="success"
                        style={{ marginTop: "-30px" }}
                        onClick={() => {
                          handleNext();
                          setValidForm(false);
                        }}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </Paper>
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
}
