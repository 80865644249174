import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const Mockdata = [
//   {
//     name: "Page A",
//     uv: 590,
//     pv: 800,
//     amt: 1400,
//   },
// ];

function TotalNCCGraph(props) {
  const { data } = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      {data && data.length > 0 ? (
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 0,
            right: 30,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="project_name" scale="band" angle={-5} dy={10} />
          <YAxis
            yAxisId="left"
            label={{
              value: "Number of Hours (H)",
              angle: -90,
              position: "center",
              dx: -30,
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: "Dollars ($)",
              angle: 90,
              position: "center",
              dx: 50,
            }}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            formatter={(value) =>
              value
                .replace(/_/g, " ")
                .replace(
                  /\w\S*/g,
                  (txt) =>
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                )
            }
          />
          <Bar
            yAxisId="left"
            dataKey="total_Working_hours"
            barSize={20}
            radius={[10, 10, 0, 0]}
            fill="#DD742D"
            legendType="circle"
          />
          <Bar
            yAxisId="right"
            dataKey="total_ncc"
            radius={[10, 10, 0, 0]}
            barSize={20}
            fill="rgb(82,122,66)"
            legendType="circle"
          />
        </ComposedChart>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h3>No data to display</h3>
        </div>
      )}
    </ResponsiveContainer>
  );
}

export default TotalNCCGraph;
