import * as Yup from "yup";
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid } from "@mui/material";
// @ts-ignore
import { FormProvider, RHFTextField } from "../../components/hook-form";
import RHFSelectField from "../../components/hook-form/RHFSelectField.jsx";
import RHFMultiSelectField from '../../components/hook-form/RHFMultiSelectField'

import RHFDatePicker from "../../components/hook-form/RHFDateField";
import { useSelector } from "react-redux";
import { CASE_STATUS, BG_STATUS, PROBABILITY_CONVERSION, CAPABILITIES_PROFILES } from "../../utils/static/Constants";

export const OpportunityFormFilter = (props) => {
  const [formSearchValues, setFormSearchValues] = useState();
  const { clearForm, submit } = props;
  const defaultValues = {
    client_name: null,
    entry_start_date: null,
    entry_end_date: null,
    bd_stage: null,
    case_status: null,
    probability_conversion: null,
    created_by: null,
    capabilities: null,
  };
  const { user } = useSelector((state) => state.GetUserReducer);
  const { first_name, last_name, email_id, region, sub_users } = user || {};
  const isAdmin = user ? user.is_admin : false;

  const MANAGER_MENU_ITEMS_LIST = sub_users
    ? [
        {
          label: "Me",
          value: email_id,
        },
        ...sub_users.map((user, index) => {
          return {
            label: user.user_full_name,
            value: user.user_email,
          };
        }),
      ]
    : [];

 
  const methods = useForm({
   
    defaultValues,
  });
  const { reset } = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    submit(formSearchValues);
  }, [formSearchValues]);

  const onSubmit = (values) => {
    setFormSearchValues({ ...values });
  };

  const handleClearForm = () => {
    reset();
    clearForm({ ...defaultValues });
  };

  const caseStatusItems = CASE_STATUS;
  const caseBDStage = BG_STATUS;
  const probabilityItems = PROBABILITY_CONVERSION;
  const capabilitiesMenuItems = CAPABILITIES_PROFILES;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
          paddingBottom: "20px",
          marginBottom: "10px",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={10} md={10} container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <RHFTextField source="sdsq" name="client_name" label="Client Name" fullWidth />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <RHFDatePicker name="entry_start_date" label="From entry date" />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <RHFDatePicker name="entry_end_date" label="To entry date" />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <RHFMultiSelectField   label={"BD Stage"} name="bd_stage" menuItems={caseBDStage} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <RHFMultiSelectField   label="Case Status" name="case_status" menuItems={caseStatusItems} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <RHFMultiSelectField   label="Probability of conversion" name="probability_conversion" menuItems={probabilityItems} />
          </Grid>
          {isAdmin && (
            <Grid item xs={12} sm={4} md={4}>
              <RHFMultiSelectField label={"User"} name="created_by" menuItems={MANAGER_MENU_ITEMS_LIST} />
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={4}>
            <RHFMultiSelectField label="Capabilities" name="capabilities" menuItems={capabilitiesMenuItems} isMulti={true} />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          style={{
            alignItems: "center",
          }}
        >
          <Grid container spacing={1} style={{ height: "100%" }}>
            <Grid item xs={12} sm={12} md={12}>
              <Button variant="contained" color="success" type="submit" sx={{ minWidth: 100, marginRight: 3, marginLeft: 6 }} style={{ color: "#ffffff", height: "40px", width: "60px" }}>
                Search
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                name="clearFilters"
                onClick={handleClearForm}
                sx={{ minWidth: 100, marginRight: 3, marginLeft: 6 }}
                style={{
                  backgroundColor: "#bfbfbf",
                  color: "#ffffff",
                  height: 40,
                  width: 60,
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
