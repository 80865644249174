import {
    GET_OPPORTUNITY_METRICS,
    SEARCH_OPPORTUNITIES_ACTIONS,
    CASE_STATUS_CHART_ACTIONS,
    PROJECTS_WORKING_WEEKS_ACTIONS,
    CREATE_OPPORTUNITY_ACTIONS,
    UPDATE_OPPORTUNITY_ACTIONS,
    AVG_WEEKLY_RATE_ACTIONS,
    STALL_OPPORTUNITIES_ACTIONS,
    GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS,
    GRT_OPPORTUNITY_DETAILS_ACTIONS,
    TOTAL_DURATION_IN_WEEKS,
    GET_CAPABILITIESY_CHART_ACTIONS,
    EDIT_OPPORTUNITY_ACTIONS,
} from "../ActionsTypes";

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const OpportunityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_OPPORTUNITIES_ACTIONS.REQUEST:
            return {
                ...state,
                data: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case SEARCH_OPPORTUNITIES_ACTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case SEARCH_OPPORTUNITIES_ACTIONS.FAILED:
            return {
                ...state,
                data: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const initialStateOpportunityMetrics = {
    opportunityMetricsData: null,
    opportunityMetricsError: null,
    opportunityMetricsLoading: false,
    opportunityMetricsIsError: false,
};

export const GetOpportunityMetricsReducer = (state = initialStateOpportunityMetrics, action) => {
    switch (action.type) {
        case GET_OPPORTUNITY_METRICS.REQUEST:
            return {
                ...state,
                opportunityMetricsData: null,
                opportunityMetricsError: null,
                opportunityMetricsLoading: true,
                opportunityMetricsIsError: false,
            };
        case GET_OPPORTUNITY_METRICS.SUCCESS:
            return {
                ...state,
                opportunityMetricsData: action.payload,
                opportunityMetricsError: null,
                opportunityMetricsLoading: false,
                opportunityMetricsIsError: false,
            };
        case GET_OPPORTUNITY_METRICS.FAILED:
            return {
                ...state,
                opportunityMetricsData: null,
                opportunityMetricsError: action.payload,
                opportunityMetricsLoading: false,
                opportunityMetricsIsError: true,
            };
        default:
            return { ...state };
    }
};

const caseInitialState = {
    caseStatusData: null,
    error: null,
    isLoading: false,
    isError: false,
};
export const CaseStatusChartDataReducer = (state = caseInitialState, action) => {
    switch (action.type) {
        case CASE_STATUS_CHART_ACTIONS.REQUEST:
            return {
                ...state,
                caseStatusData: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case CASE_STATUS_CHART_ACTIONS.SUCCESS:
            return {
                ...state,
                caseStatusData: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case CASE_STATUS_CHART_ACTIONS.FAILED:
            return {
                ...state,
                caseStatusData: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const pjhInitialState = {
    projectWorkingWeeksData: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const ProjectWorkingWeeksDataReducer = (state = pjhInitialState, action) => {
    switch (action.type) {
        case PROJECTS_WORKING_WEEKS_ACTIONS.REQUEST:
            return {
                ...state,
                projectWorkingWeeksData: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case PROJECTS_WORKING_WEEKS_ACTIONS.SUCCESS:
            return {
                ...state,
                projectWorkingWeeksData: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case PROJECTS_WORKING_WEEKS_ACTIONS.FAILED:
            return {
                ...state,
                projectWorkingWeeksData: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const createOppInitialState = {
    data: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const CreateOpportunityReducer = (state = createOppInitialState, action) => {
    switch (action.type) {
        case CREATE_OPPORTUNITY_ACTIONS.DEFAULT:
            return {
                ...state,
                data: null,
                error: null,
                isLoading: false,
                isError: false,
            };
        case CREATE_OPPORTUNITY_ACTIONS.REQUEST:
            return {
                ...state,
                data: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case CREATE_OPPORTUNITY_ACTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case CREATE_OPPORTUNITY_ACTIONS.FAILED:
            return {
                ...state,
                data: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const updateOppInitialState = {
    isUpdated: false,
    errorUpdate: null,
    isLoadingUpdate: false,
    isErrorUpdate: false,
};

export const UpdateOpportunityReducer = (state = updateOppInitialState, action) => {
    switch (action.type) {
        case UPDATE_OPPORTUNITY_ACTIONS.REQUEST:
            return {
                ...state,
                isUpdated: false,
                errorUpdate: null,
                isLoadingUpdate: true,
                isErrorUpdate: false,
            };
        case UPDATE_OPPORTUNITY_ACTIONS.SUCCESS:
            return {
                ...state,
                isUpdated: false,
                errorUpdate: true,
                isLoadingUpdate: false,
                isErrorUpdate: false,
            };
        case UPDATE_OPPORTUNITY_ACTIONS.FAILED:
            return {
                ...state,
                isUpdated: false,
                errorUpdate: action.payload,
                isLoadingUpdate: false,
                isErrorUpdate: true,
            };
        default:
            return { ...state };
    }
};

const editOppInitialState = {
    message: "",
    status: null,
    isLoadingEdit: false,
    isErrorEdit: false,
};

export const editOpportunityReducer = (state = editOppInitialState, action) => {
    switch (action.type) {
        case EDIT_OPPORTUNITY_ACTIONS.REQUEST:
            return {
                ...state,
                message: "",
                status: null,
                isLoadingEdit: true,
                isErrorEdit: false,
            };
        case EDIT_OPPORTUNITY_ACTIONS.SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                status: action.payload.status,
                isLoadingEdit: false,
                isErrorEdit: false,
            };
        case EDIT_OPPORTUNITY_ACTIONS.FAILED:
            return {
                ...state,
                message: action.payload.message,
                status: action.payload.status,
                isLoadingEdit: false,
                isErrorEdit: true,
            };
        default:
            return { ...state };
    }
};

const startState = {
    avgWeeklyRunRateData: null,
    avgWeekerror: null,
    avgWeekisLoading: false,
    avgWeekisError: false,
};
export const AvgWeeklyRunRateReducer = (state = startState, action) => {
    switch (action.type) {
        case AVG_WEEKLY_RATE_ACTIONS.REQUEST:
            return {
                ...state,
                avgWeeklyRunRateData: null,
                avgWeekerror: null,
                avgWeekisLoading: true,
                avgWeekisError: false,
            };
        case AVG_WEEKLY_RATE_ACTIONS.SUCCESS:
            return {
                ...state,
                avgWeeklyRunRateData: action.payload,
                avgWeekerror: null,
                avgWeekisLoading: false,
                avgWeekisError: false,
            };
        case AVG_WEEKLY_RATE_ACTIONS.FAILED:
            return {
                ...state,
                avgWeeklyRunRateData: null,
                avgWeekerror: action.payload,
                avgWeekisLoading: false,
                avgWeekisError: true,
            };
        default:
            return { ...state };
    }
};

const totalDurationStartState = {
    totalDurationInWeeksData: null,
    totalDurationInWeeksError: null,
    totalDurationInWeeksLoading: false,
    totalDurationInWeeksIsError: false,
};
export const TotalDurationInWeeksReducer = (state = totalDurationStartState, action) => {
    switch (action.type) {
        case TOTAL_DURATION_IN_WEEKS.REQUEST:
            return {
                ...state,
                totalDurationInWeeksData: null,
                totalDurationInWeeksError: null,
                totalDurationInWeeksLoading: true,
                totalDurationInWeeksIsError: false,
            };
        case TOTAL_DURATION_IN_WEEKS.SUCCESS:
            return {
                ...state,
                totalDurationInWeeksData: action.payload,
                totalDurationInWeeksError: null,
                totalDurationInWeeksLoading: false,
                totalDurationInWeeksIsError: false,
            };
        case TOTAL_DURATION_IN_WEEKS.FAILED:
            return {
                ...state,
                totalDurationInWeeksData: null,
                totalDurationInWeeksError: action.payload,
                totalDurationInWeeksLoading: false,
                totalDurationInWeeksIsError: true,
            };
        default:
            return { ...state };
    }
};

export const StallOpportunitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STALL_OPPORTUNITIES_ACTIONS.REQUEST:
            return {
                ...state,
                data: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case STALL_OPPORTUNITIES_ACTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case STALL_OPPORTUNITIES_ACTIONS.FAILED:
            return {
                ...state,
                data: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const funnelChartInitialState = {
    data: null,
    error: null,
    isLoading: false,
    isError: false,
};

export const FunnelChartOpportunityReducer = (state = funnelChartInitialState, action) => {
    switch (action.type) {
        case GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.REQUEST:
            return {
                ...state,
                data: null,
                error: null,
                isLoading: true,
                isError: false,
            };
        case GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.FAILED:
            return {
                ...state,
                data: null,
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const opportunityDetailsInitialState = {
    data: {},
    error: null,
    isLoading: false,
    isError: false,
};

export const OpportunityDetailsReducer = (state = opportunityDetailsInitialState, action) => {
    switch (action.type) {
        case GRT_OPPORTUNITY_DETAILS_ACTIONS.REQUEST:
            return {
                ...state,
                data: {},
                error: null,
                isLoading: true,
                isError: false,
            };
        case GRT_OPPORTUNITY_DETAILS_ACTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
                isError: false,
            };
        case GRT_OPPORTUNITY_DETAILS_ACTIONS.FAILED:
            return {
                ...state,
                data: {},
                error: action.payload,
                isLoading: false,
                isError: true,
            };
        default:
            return { ...state };
    }
};

const capabilitiesInitialState = {
    capabilitiesTableData: null,
    capabilitiesTableError: null,
    capabilitiesTableIsLoading: false,
    capabilitiesTableIsError: false,
};

export const capabilitiesReducer = (state = capabilitiesInitialState, action) => {
    switch (action.type) {
        case GET_CAPABILITIESY_CHART_ACTIONS.REQUEST:
            return {
                ...state,
                capabilitiesTableData: null,
                capabilitiesTableError: null,
                capabilitiesTableIsLoading: true,
                capabilitiesTableIsError: false,
            };
        case GET_CAPABILITIESY_CHART_ACTIONS.SUCCESS:
            return {
                ...state,
                capabilitiesTableData: action.payload,
                capabilitiesTableError: null,
                capabilitiesTableIsLoading: false,
                capabilitiesTableIsError: false,
            };
        case GET_CAPABILITIESY_CHART_ACTIONS.FAILED:
            return {
                ...state,
                capabilitiesTableData: null,
                capabilitiesTableError: action.payload,
                capabilitiesTableIsLoading: false,
                capabilitiesTableIsError: true,
            };
        default:
            return { ...state };
    }
};
