import React from "react";

import Box from "@mui/joy/Box";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Typography from "@mui/joy/Typography";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import BarChartIcon from "@mui/icons-material/BarChart";
import RadarIcon from "@mui/icons-material/Radar";

import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";

function DisplayMode(props) {
  const {
    displayModeValues,
    displayModeSelectedValue,
    setDisplayModeSelectedValue,
    style,
  } = props;
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
          style={style}
        >
          <RadioGroup
            orientation="horizontal"
            aria-labelledby="segmented-controls-example"
            name="displayModeSelectedValue"
            value={displayModeSelectedValue}
            onChange={(event) => {
              setDisplayModeSelectedValue(event.target.value);
            }}
            sx={{
              minHeight: 48,
              padding: "4px",
              borderRadius: "md",
              bgcolor: "neutral.softBg",
              "--RadioGroup-gap": "4px",
              "--Radio-action-radius": "8px",
            }}
          >
            {displayModeValues.map((item) => (
              <Radio
                key={item}
                color="neutral"
                value={item}
                selected={displayModeSelectedValue === item}
                disableIcon={true}
                label={
                  <div style={{ marginTop: "5px" }}>
                    {displayModeValues.indexOf(item) === 0 ? (
                      <BackupTableIcon
                        style={
                          displayModeSelectedValue === item
                            ? { color: "green" }
                            : { color: "#000000" }
                        }
                      />
                    ) : displayModeValues.indexOf(item) === 1 ? (
                      <BarChartIcon
                        style={
                          displayModeSelectedValue === item
                            ? { color: "green" }
                            : { color: "#000000" }
                        }
                      />
                    ) : displayModeValues.indexOf(item) === 2 ? (
                      <RadarIcon
                        style={
                          displayModeSelectedValue === item
                            ? { color: "green" }
                            : { color: "#000000" }
                        }
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                }
                variant="icon"
                sx={{
                  px: 2,
                  alignItems: "center",
                }}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: {
                      ...(checked && {
                        bgcolor: "background.surface",
                        boxShadow: "md",
                        "&:hover": {
                          bgcolor: "background.surface",
                        },
                      }),
                    },
                  }),
                }}
              />
            ))}
          </RadioGroup>
        </Box>
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}

export default DisplayMode;
