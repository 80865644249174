import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file date range picker
import "react-date-range/dist/theme/default.css"; // theme css file date range picker
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import TokenStorage from "../../../utils/http_methods/TokenStorage";

import { REGIONS, LETTERS } from "../../../utils/static/Constants";
import reactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SEARCH_FILTER_ACTION_TYPE } from "../../../utils/redux/ActionsTypes";
import Cookies from "universal-cookie";
import { set } from "lodash";
const cookies = new Cookies();

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const billingCodes = ["B", "C", "DW", "X"];

let DEFAULT_PARAMETERS = {
  // make start date (new Date(new Date().getFullYear(), 0, 1) in this format 01 Jan 2012
  startDate: new Date(new Date().getFullYear(), 0, 1),
  endDate: new Date(),
  regions: REGIONS,
  billing_codes: billingCodes,
  gamma_codes: LETTERS,
  users: [TokenStorage.getEmail()],
};

export default function Filters(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sub_users } = props;

  const [managerItemMenu, setManagerItemMenu] = useState([
    {
      label: "Me",
      value: TokenStorage.getEmail(),
    },
  ]);
  const [selectedLetter, setSelectedLetter] = useState(
    DEFAULT_PARAMETERS.gamma_codes.map((region) => {
      return region.value;
    })
  );
  const [selectedBillingCode, setSelectedBillingCode] = useState(
    DEFAULT_PARAMETERS.billing_codes
  );
  const [selectedRegion, setSelectedRegion] = useState(
    DEFAULT_PARAMETERS.regions.map((region) => {
      return region.value;
    })
  );
  const [selectedManager, setSelectedManager] = useState(
    DEFAULT_PARAMETERS.users
  );
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

  const [selectedDateRange, setSelectedDateRange] =
    useState(DEFAULT_PARAMETERS);
  const [parameters, setParameters] = useState();
  //   {
  //   regions: selectedRegion,
  //   billing_codes: selectedBillingCode,
  //   gamma_codes: selectedLetter,
  //   users: selectedManager,
  //   from_period_date: selectedDateRange.startDate,
  //   to_period_date: selectedDateRange.endDate,
  // }

  useEffect(() => {
    if (sub_users != null && sub_users.length > 0)
      setManagerItemMenu([
        ...managerItemMenu,
        ...sub_users.map((user) => {
          const result = {
            label: user.user_full_name,
            value: user.user_email,
          };
          return result;
        }),
      ]);
  }, [sub_users]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    let currentValue = typeof value === "string" ? value.split(",") : value;

    if (name === "letter") {
      if (value.includes("all")) {
        if (LETTERS.length === selectedLetter.length) setSelectedLetter([]);
        else setSelectedLetter(LETTERS);
      } else setSelectedLetter([...value]);
    } else if (name === "billingCode") {
      if (value.includes("all")) {
        if (billingCodes.length === selectedBillingCode.length)
          setSelectedBillingCode([]);
        else setSelectedBillingCode(billingCodes);
      } else setSelectedBillingCode([...value]);
    } else if (name === "region") {
      //regions
      if (value.includes("all")) {
        if (REGIONS.length === selectedRegion.length) setSelectedRegion([]);
        else setSelectedRegion(REGIONS.map((region) => region.label));
      } else setSelectedRegion([...value]);
    } else if (name === "manager_name") {
      if (value.includes("all")) {
        if (managerItemMenu.length === selectedManager.length)
          setSelectedManager([]);
        else setSelectedManager(managerItemMenu.map((item) => item.value));
      } else setSelectedManager([...value]);
    }
  };

  const handleDateRangeChange = (item) => {
    let startDate = "";
    let endDate = "";

    if (item && item.range2) {
      endDate = new Date(item.range2.endDate);
      endDate.setDate(endDate.getDate() - endDate.getDay() + 5);
      setSelectedDateRange({
        startDate: selectedDateRange.startDate,
        endDate,
        key: "selection",
      });
    }
    if (item && item.range1) {
      startDate = new Date(item.range1.startDate);
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      setSelectedDateRange({
        startDate,
        endDate: selectedDateRange.endDate,
        key: "selection",
      });
    }

    if (item && item.selection) {
      startDate = new Date(item.selection.startDate);
      endDate = new Date(item.selection.endDate);

      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      endDate.setDate(endDate.getDate() - endDate.getDay() + 5);
      setSelectedDateRange({
        startDate,
        endDate,
        key: "selection",
      });
    }
  };

  useEffect(() => {
    let startDate = "";
    let endDate = "";
    let regions = [];
    let billingCodes = [];
    let letters = [];
    let users = [];

    let initialFiltersSource = cookies.get("dashboardFilters");
    if (initialFiltersSource === undefined) {
      initialFiltersSource = DEFAULT_PARAMETERS;
      startDate = new Date(initialFiltersSource.startDate);
      endDate = new Date(initialFiltersSource.endDate);
      regions = initialFiltersSource.regions.map((region) => {
        return region.value;
      });
      billingCodes = initialFiltersSource.billing_codes;
      letters = initialFiltersSource.gamma_codes.map((code) => {
        return code.value;
      });
      users = initialFiltersSource.users;
    } else {
      startDate = new Date(initialFiltersSource.from_period_date);
      endDate = new Date(initialFiltersSource.to_period_date);
      regions = initialFiltersSource.regions;
      billingCodes = initialFiltersSource.billing_codes;
      letters = initialFiltersSource.gamma_codes;
      users = initialFiltersSource.users;
    }

    setSelectedBillingCode(billingCodes);
    setSelectedLetter(letters);
    setSelectedRegion(regions);
    setSelectedManager(initialFiltersSource.users);
    setSelectedDateRange({
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    });

    setParameters({
      ...parameters,
      from_period_date: startDate,
      to_period_date: endDate,
      regions: regions,
      billing_codes: billingCodes,
      gamma_codes: letters,
      users: users,
    });
  }, []);

  const handleClick = (event) => {
    dispatch({ type: SEARCH_FILTER_ACTION_TYPE.STOP });
    const {
      target: { name },
    } = event;
    if (name === "applyFilters") {
      let startDate = `${selectedDateRange.startDate.getDate()} ${selectedDateRange.startDate.toLocaleString(
        "default",
        {
          month: "short",
        }
      )} ${selectedDateRange.startDate.getFullYear()}`;
      let endDate = `${selectedDateRange.endDate.getDate()} ${selectedDateRange.endDate.toLocaleString(
        "default",
        {
          month: "short",
        }
      )} ${selectedDateRange.endDate.getFullYear()}`;

      setParameters({
        ...parameters,
        from_period_date: startDate,
        to_period_date: endDate,
        regions: selectedRegion,
        billing_codes: selectedBillingCode,
        gamma_codes: selectedLetter,
        users: selectedManager,
      });

      cookies.set(
        "dashboardFilters",
        {
          ...parameters,
          from_period_date: selectedDateRange.startDate,
          to_period_date: selectedDateRange.endDate,
          regions: selectedRegion,
          billing_codes: selectedBillingCode,
          gamma_codes: selectedLetter,
          users: selectedManager,
        },
        {
          path: "/",
          expires: new Date(Date.now() + 86400e3),
        }
      );
    } else if (name === "clearFilters") {
      setSelectedManager(DEFAULT_PARAMETERS.users);
      setSelectedRegion(
        DEFAULT_PARAMETERS.regions.map((region) => {
          return region.value;
        })
      );
      setSelectedBillingCode(DEFAULT_PARAMETERS.billing_codes);
      setSelectedLetter(
        DEFAULT_PARAMETERS.gamma_codes.map((region) => {
          return region.value;
        })
      );
      setParameters(DEFAULT_PARAMETERS);
    }
  };
  useEffect(() => {
    if (selectedDateRange.startDate === "" || selectedDateRange.endDate === "")
      return;
    let startDate = `${selectedDateRange.startDate.getDate()} ${selectedDateRange.startDate.toLocaleString(
      "default",
      {
        month: "short",
      }
    )} ${selectedDateRange.startDate.getFullYear()}`;
    let endDate = `${selectedDateRange.endDate.getDate()} ${selectedDateRange.endDate.toLocaleString(
      "default",
      {
        month: "short",
      }
    )} ${selectedDateRange.endDate.getFullYear()}`;

    let params = {
      ...parameters,
      from_period_date: startDate,
      to_period_date: endDate,
    };

    if (
      params.gamma_codes &&
      params.billing_codes &&
      params.regions &&
      params.users
    ) {
      dispatch({ type: SEARCH_FILTER_ACTION_TYPE.START, payload: params });
    }
  }, [parameters]);

  const handleDateRangeBtnClick = () => {
    toggleDatePicker();
  };

  const toggleDatePicker = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDateRangePickerOpen &&
        !event.target.closest(".rdrDateRangeWrapper") &&
        !event.target.closest('[name="date-range-picker-btn"]')
      ) {
        setIsDateRangePickerOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDateRangePickerOpen]);

  const renderSelectedValue = (selectedValues, itemMenu) => {
    let selectedItem = [];
    selectedValues.forEach((value, index) => {
      const val = itemMenu.filter((item) => item.value === value)[0];
      selectedItem.push(val?.label);
    });
    return selectedItem.join(", ");
  };

  return (
    <form style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleDateRangeBtnClick}
          name="date-range-picker-btn"
          style={{
            borderColor: "#bfbfbf",
            color: "#666666",
            height: "57px",
            marginRight: "10px",
          }}
        >
          {selectedDateRange.startDate && selectedDateRange.endDate
            ? `${selectedDateRange.startDate.toLocaleDateString()} - ${selectedDateRange.endDate.toLocaleDateString()}`
            : "Select Date Range"}
          <CalendarMonthIcon />
        </Button>
        <div
          style={{
            position: "absolute",
            top: "250px",
            zIndex: "99999",
          }}
        >
          {isDateRangePickerOpen && (
            <DateRangePicker
              rangeColors={["#3A795A"]}
              color="#3A795A"
              name="dateRange"
              ranges={[selectedDateRange]}
              onChange={handleDateRangeChange}
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date(new Date().getFullYear() + 10, 0, 1)}
            />
          )}
        </div>
        <FormControl sx={{ m: 1, width: 250 }}>
          <InputLabel
            id="squad-multiple-checkbox-label"
            style={{ width: "70%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Squad
            </div>
          </InputLabel>
          <Select
            labelId="letter-multiple-checkbox-label"
            id="letter-multiple-checkbox"
            multiple
            value={selectedLetter}
            label="Squad"
            name="letter"
            onChange={handleChange}
            input={<OutlinedInput label="Squad" name="letterInput" />}
            renderValue={(selected) => {
              return renderSelectedValue(selected, LETTERS);
            }}
            MenuProps={MenuProps}
          >
            {LETTERS.map((letter) => (
              <MenuItem key={letter.value} value={letter.value}>
                <Checkbox checked={selectedLetter.indexOf(letter.value) > -1} />
                <ListItemText primary={letter.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 250 }}>
          <InputLabel
            id="billing-code-multiple-checkbox-label"
            style={{ width: "70%" }}
          >
            Billing
          </InputLabel>
          <Select
            labelId="billing-code-multiple-checkbox-label"
            id="billing-code-multiple-checkbox"
            multiple
            value={selectedBillingCode}
            label="Billing"
            name="billingCode"
            onChange={handleChange}
            input={<OutlinedInput label="Billing" name="billingCodeInput" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem value={"all"}>
              <Checkbox
                checked={selectedBillingCode.length === billingCodes.length}
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {billingCodes.map((billingCode) => (
              <MenuItem key={billingCode} value={billingCode}>
                <Checkbox
                  checked={selectedBillingCode.indexOf(billingCode) > -1}
                />
                <ListItemText primary={billingCode} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 250 }}>
          <InputLabel
            id="region-multiple-checkbox-label"
            style={{ width: "70%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Region
            </div>
          </InputLabel>
          <Select
            labelId="region-multiple-checkbox-label"
            id="region-multiple-checkbox"
            multiple
            value={selectedRegion}
            label="Region"
            name="region"
            onChange={handleChange}
            input={<OutlinedInput label="region" name="regionInput" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem value={"all"}>
              <Checkbox checked={selectedRegion.length === REGIONS.length} />
              <ListItemText primary="Select All" />
            </MenuItem>

            {REGIONS.map((region) => (
              <MenuItem key={region.value} value={region.label}>
                <Checkbox checked={selectedRegion.indexOf(region.value) > -1} />
                <ListItemText primary={region.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 250 }}>
          <InputLabel
            id="user-multiple-checkbox-label"
            style={{ width: "70%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              User
            </div>
          </InputLabel>

          <Select
            labelId="manager-multiple-checkbox-label"
            id="manager-multiple-checkbox"
            value={selectedManager}
            label="User"
            name="manager_name"
            onChange={handleChange}
            input={<OutlinedInput label="User" name="userInput" />}
            renderValue={(selected) => {
              if (selected === undefined || selected === null) return "";

              return selected.map((item) => {
                if (item === undefined || item === null) return "";
                return item.split("@")[0] + ", ";
              });
            }}
            MenuProps={MenuProps}
            multiple
          >
            <MenuItem value={"all"}>
              <Checkbox
                checked={selectedManager.length === managerItemMenu.length}
              />
              <ListItemText primary="Select All" />
            </MenuItem>

            {managerItemMenu.map((user) => (
              <MenuItem key={user.leble} value={user.value}>
                <Checkbox checked={selectedManager.indexOf(user.value) > -1} />
                <ListItemText primary={user.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <Button
            variant="contained"
            name="applyFilters"
            onClick={handleClick}
            color="success"
            style={{ color: "#ffffff", height: "40px", width: "60px" }}
          >
            Apply
          </Button>
          {/* <Button
            variant="contained"
            name="clearFilters"
            onClick={handleClick}
            style={{
              backgroundColor: "#bfbfbf",
              color: "#ffffff",
              height: "40px",
              width: "60px",
            }}
          >
            Clear
          </Button> */}
        </div>
      </div>
    </form>
  );
}
