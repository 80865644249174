import { REGIONS, CASE_STATUS, BG_STATUS, PROBABILITY_CONVERSION, CAPABILITIES_PROFILES } from "../../utils/static/Constants";

export const tableColumns = [
    {
        field: "client_name",
        title: "Client name",
        fieldType: "",
        isEditable: false,
    },
    { field: "country", title: "Country", fieldType: "", isEditable: false },
    {
        field: "bg_status",
        title: "Funding",
        fieldType: "section",
        values: BG_STATUS,
        isEditable: true,
    },
    {
        field: "entry_date",
        title: "Entry Date",
        fieldType: "date",
        isEditable: false,
    },
    {
        field: "case_status",
        title: "Case Status",
        fieldType: "section",
        values: CASE_STATUS,
        isEditable: true,
    },
    {
        field: "probability_conversion",
        title: "Probability of Conversion",
        fieldType: "section",
        values: PROBABILITY_CONVERSION,
        isEditable: true,
    },
    {
        field: "expected_start_date",
        title: "Expect start date",
        fieldType: "date",
        isEditable: false,
    },
];
export const detailsFields = [
    {
        field: "region_staffing",
        title: "Region of Staffing",
        fieldType: "section",
        values: REGIONS,
        isEditable: true,
    },
    {
        field: "weeks_number",
        title: "#Weeks",
        fieldType: "number",
        isEditable: true,
    },
    //
    {
        field: "avg_weekly_run_rate",
        title: "Avg. Weekly Run-rate (USD)",
        fieldType: "",
        isEditable: true,
    },
   
    //project_code
    {
        field: "project_code",
        title: "Project Code",
        isEditable: true,
    },
    {
        field: "principal_full_name",
        title: "Partner / Principal",
        fieldType: "",
        isEditable: false,
    },
    {
        field: "last_update_date",
        title: "Last update date",
        fieldType: "date",
        isEditable: false,
    },
    {
        field: "description",
        title: "Description",
        fieldType: "string",
        isEditable: true,
    },
    { field: "comments", title: "Comments", fieldType: "", isEditable: true },
];

export const capabilitiesFields = [
    {
        field: "it_profile_field",
        title: "Capability ",
        fieldType: "section",
        values: CAPABILITIES_PROFILES,
    },
    {
        field: "it_profile_level",
        title: "Level ",
    },

    {
        field: "num_profile",
        title: "Staffing",
    },
    {
        field: "num_weeks",
        title: "#Weeks",
    },
];
