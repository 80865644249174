import React, { useEffect, useState } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";

function StallCapabilitiesGraph(props) {
  const { data } = props;
  const navigate = useNavigate();

  const [lastDataPointIdHovered, setLastDataPointIdHovered] = useState(null);
  const [processedDataWithProjectCode, setProcessedDataWithProjectCode] =
    useState([]);
  const [processedDataWithoutProjectCode, setProcessedDataWithoutProjectCode] =
    useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      let withProjectCode = [];
      let withoutProjectCode = [];
      data.forEach((item) => {
        const {
          id,
          client_name,
          project_code,
          weeks_number,
          avg_weekly_run_rate,
          lastRelevantUpdate,
          capabilities,
          bg_status,
          region_staffing,
          case_status,
        } = item;

        let expectedAmount = 0;
        if (weeks_number && avg_weekly_run_rate) {
          expectedAmount = weeks_number * avg_weekly_run_rate;
        }
        let dataEntry = {
          opportunity_id: id,
          client_name: client_name,
          project_code: project_code ? project_code : "",
          expectedAmount: expectedAmount,
          numberOfCapabilities: capabilities.length,
          numberOfDaysSinceLastUpdate: Math.floor(
            (new Date() - new Date(lastRelevantUpdate)) / (1000 * 60 * 60 * 24)
          ),

          bg_status: bg_status ? bg_status : "N/A",
          region_staffing: region_staffing ? region_staffing : "N/A",
          case_status: case_status ? case_status : "N/A",
        };
        if (dataEntry.project_code) {
          withProjectCode.push(dataEntry);
        } else {
          withoutProjectCode.push(dataEntry);
        }
      });
      setProcessedDataWithProjectCode(withProjectCode);
      setProcessedDataWithoutProjectCode(withoutProjectCode);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      setLastDataPointIdHovered(payload[0].payload.opportunity_id);
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            margin: 0,
            padding: 10,
            borderRadius: 5,
            elevation: 5,
          }}
        >
          <h3
            style={{
              color: payload[0].payload.project_code ? "#82ca9d" : "#E08142",
              fontWeight: "bold",
              fontSize: "1.0rem",
            }}
          >{`${payload[0].payload.client_name} [${payload[0].payload.project_code}]`}</h3>
          <br />
          <p>{`${payload[0].name} : ${payload[0].value} ${payload[0].unit} Ago`}</p>
          <p>{`${payload[1].name} : ${payload[1].unit} ${payload[1].value}`}</p>
          <p>{`${payload[2].name} : ${payload[2].value} ${payload[2].unit}`}</p>
          <p>{`Region : ${payload[0].payload.region_staffing.replace(
            /_/g,
            " "
          )}`}</p>
          <p>{`Funding Status : ${payload[0].payload.bg_status.replace(
            /_/g,
            " "
          )}`}</p>
          <p>{`Case Status : ${payload[0].payload.case_status.replace(
            /_/g,
            " "
          )}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      {processedDataWithoutProjectCode &&
      processedDataWithoutProjectCode.length > 0 &&
      processedDataWithProjectCode &&
      processedDataWithProjectCode.length > 0 ? (
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          onClick={(data, index) => {
            navigate(`/opportunities/${lastDataPointIdHovered}`);
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="numberOfDaysSinceLastUpdate"
            name="Last Update"
            unit=" Days"
            tickMargin={10}
          />
          <YAxis
            type="number"
            dataKey="expectedAmount"
            name="Expected Amount"
            unit=" $"
          />
          <ZAxis
            type="number"
            dataKey="numberOfCapabilities"
            range={[100, 400]}
            name="Team Size"
            unit=" Members"
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Scatter
            name="With Project Code"
            data={processedDataWithProjectCode}
            fill="#82ca9d"
            shape="dot"
          />
          <Scatter
            name="Without Project Code"
            data={processedDataWithoutProjectCode}
            fill="#E08142"
            shape="dot"
          />
        </ScatterChart>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          No data to display
        </div>
      )}
    </ResponsiveContainer>
  );
}

export default StallCapabilitiesGraph;
