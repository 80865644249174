import { green, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            light: "#92DE6E",
            main: "#198754",

            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#161616",
            contrastText: "#FFFFFF",
        },
        error: {
            main: red.A400,
        },
        success: {
            main: "#4BB543",
        },
    },

    typography: {
        allVariants: {
            color: "#2A2C31",
        },

        h1: {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontSize: "2rem", //32px
            fontWeight: 700,
            lineHeight: "2.5rem",
        },
        h2: {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontSize: "1.5rem", // 24px
            fontWeight: 600,
            lineHeight: "2rem",
        },
        h3: {
            fontFamily: "Open Sans",
            fontSize: "1.25rem", // 20px
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.75rem",
        },
        h4: {
            fontFamily: "Open Sans",
            fontSize: "1.125rem", // 18px
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.5rem",
        },
        h5: {
            fontFamily: "Open Sans",
            fontSize: "1rem", // 16px
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.5rem",
        },
        h6: {
            fontFamily: "Open Sans",
            fontSize: "0.875rem", // 14px
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "1.25rem",
        },

        body1: {
            fontFamily: "Open Sans",
            fontSize: "1.125rem", // 18px
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "2rem",
        },
        body2: {
            fontFamily: "Open Sans",
            fontSize: "1rem", // 16px
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.5rem",
        },

        button: {
            fontFamily: "Open Sans",
            fontSize: "1rem", // 16px
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.375rem",
            color: "#FFFFFF",
            textTransform: "none",
        },
    },
});

export default theme;
