import { ASSIGN_USER_TO_ADMIN_ACTION, GET_USER, DISMISS_USER_TO_ADMIN_ACTION, PROPLE_API_ACTIONS_TYPE } from "../ActionsTypes";
import { get, post } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const get_user_action = () => async (dispatch) => {
  dispatch({ type: GET_USER.REQUEST });
  const email = TokenStorage.getEmail();
  const fullName = await TokenStorage.getFullName();

  try {
    const result = await get(BACKEDN_URL + "/api/v1/users/profile", {
      email: email,
      fullName: fullName,
    });
    dispatch({ type: GET_USER.SUCCESS, payload: result.user });
  } catch (error) {
    dispatch({ type: GET_USER.FAILED, payload: error });
  }
};

export const assignUserAdminAction = (user_meta_data) => async (dispatch) => {
  dispatch({ type: ASSIGN_USER_TO_ADMIN_ACTION.REQUEST });

  try {
    const result = await post(`${BACKEDN_URL}/api/v1/users/assign`, user_meta_data, undefined);
    if (result.status === 200) {
      dispatch({ type: ASSIGN_USER_TO_ADMIN_ACTION.SUCCESS });
      dispatch(get_user_action());
    } else {
      dispatch({ type: ASSIGN_USER_TO_ADMIN_ACTION.FAILED });
    }
  } catch (error) {
    dispatch({ type: ASSIGN_USER_TO_ADMIN_ACTION.FAILED });
  }
};

export const dismissUserAdminAction = (user_meta_data) => async (dispatch) => {
  dispatch({ type: DISMISS_USER_TO_ADMIN_ACTION.REQUEST });
  try {
    const result = await post(`${BACKEDN_URL}/api/v1/users/dismiss`, user_meta_data, undefined);
    if (result.status === 200) {
      dispatch({ type: DISMISS_USER_TO_ADMIN_ACTION.SUCCESS });
      dispatch(get_user_action());
    } else {
      dispatch({ type: DISMISS_USER_TO_ADMIN_ACTION.FAILED });
    }
  } catch (error) {
    dispatch({ type: DISMISS_USER_TO_ADMIN_ACTION.FAILED });
  }
};

export const peopleApiAction = (preferredFirstName, preferredLastName) => async (dispatch) => {
  dispatch({ type: PROPLE_API_ACTIONS_TYPE.REQUEST });

  try {
    const parameters = {
      preferredDisplayName: `${preferredFirstName}${preferredLastName}`,
    };
    const result = await get(`${BACKEDN_URL}/api/v1/users/people-api`, parameters);

    dispatch({ type: PROPLE_API_ACTIONS_TYPE.SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: PROPLE_API_ACTIONS_TYPE.FAILED, payload: error });
  }
};
