import React, { useState, PureComponent } from "react";
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// const Mockdata = [
//   {
//     name: "Page A",
//     uv: 590,
//     pv: 800,
//     amt: 1400,
//   },
// ];

function OppsGraph(props) {
    const { data, label, actual_key_value, expected_key_value } = props;

    const [lastClick, setLastClick] = useState(null);

    if (!data || !label || !actual_key_value || !expected_key_value) {
        return <div>Loading...</div>; // Replace this with your own loading component
    }

    const handleBarClick = (e, clickedRow) => {
        const currentTime = new Date().getTime();
        const diffTime = currentTime - lastClick;

        if (lastClick && diffTime < 300) {
            // 300ms between clicks for a double-click
            window.open(`/opportunities/${data[clickedRow].id}`, "_blank");
            setLastClick(null);
        } else {
            setLastClick(currentTime);
        }
    };

    return (
        <ResponsiveContainer width="100%" minHeight={500}>
            {data.length > 0 ? (
                <ComposedChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 0,
                        right: 30,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="label" scale="band" angle={-5} dy={10} />
                    <YAxis
                        yAxisId="left"
                        label={{
                            value: label,
                            angle: -90,
                            position: "center",
                            dx: -30,
                        }}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar
                        yAxisId="left"
                        dataKey={expected_key_value}
                        barSize={20}
                        radius={[10, 10, 0, 0]}
                        fill="#DD742D"
                        legendType="circle"
                        onClick={(e, clickedRow) => handleBarClick(e, clickedRow)}
                    />
                    <Bar
                        yAxisId="left"
                        dataKey={actual_key_value}
                        radius={[10, 10, 0, 0]}
                        barSize={20}
                        fill="rgb(82,122,66)"
                        legendType="circle"
                        onClick={(e, clickedRow) => handleBarClick(e, clickedRow)}
                    />
                </ComposedChart>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <h3>No data to display</h3>
                </div>
            )}
        </ResponsiveContainer>
    );
}

export default OppsGraph;
