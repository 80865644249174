import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RHFTextField, RHFDatePicker, RHFTextFieldSearchIcon } from "../../../components/hook-form";
// @ts-ignore
import RHFSelectField from "../../../components/hook-form/RHFSelectField";
import { OUTREACH_TYPE_ITEMS, REGIONS, PA_INDUSTRY_LIST_ITEMS } from "../../../utils/static/Constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import { useSelector } from "react-redux";

export const ContactPersonalInfosDetails = ({ searchForTarget, isLoading, employeeSelectItem, selectedEmployee, showErrorMessage }) => {
  const { peopleData, peopleIsLoading, peopleReject, peopleErrorMessage } = useSelector((state) => state.PeopleApiReducer);

  const [oppEmail, setOppEmail] = useState();
  const [oppFullName, setFullName] = useState();
  const [oppHomeOffice, setHomeOffice] = useState();
  const [oppRegion, setRegion] = useState();
  const [oppPhoneNumber, setPhoneNumber] = useState();

  const REGIONS_MENUE_ITEMS = REGIONS;
  const [open, setOpen] = React.useState(false);

  const handleSelectEmployee = (selectedEmployeeEmail) => {
    if (selectedEmployeeEmail && selectedEmployeeEmail.length > 0 && peopleData != null && peopleData.length > 0) {
      const selectedEmp = peopleData.filter((employee) => employee.workEmail === selectedEmployeeEmail)[0];

      if (selectedEmp != undefined && selectedEmp != null) {
        const { workEmail, hostOfficeRegion, preferredFirstName, preferredLastName, supervisoryOrgName, primaryWorkPhone, homeOffice } = selectedEmp;
        setOppEmail(workEmail);
        setFullName(preferredFirstName + " " + preferredLastName);
        setHomeOffice(homeOffice);
        setRegion(hostOfficeRegion);
        setPhoneNumber(primaryWorkPhone);
      }
    }
  };

  const EmptyAllAutoFilledFields = () => {
    selectedEmployee([]);
    setOppEmail("");
    setFullName("");
    setHomeOffice("");
    setRegion("");
    setPhoneNumber("");
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Details infos
      </Typography>
      {showErrorMessage && (
        <Alert severity="error" style={{ marginBottom: "10px" }}>
          Some required fields are missing
        </Alert>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5} md={5}>
          <Autocomplete
            id="asynchronous-demo"
            //  sx={{ width: 600 }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(event, value) => {
              EmptyAllAutoFilledFields();
              if (value != null && value != undefined) {
                handleSelectEmployee(value.value), selectedEmployee(value.value);
              }
            }}
            fullWidth
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
            getOptionLabel={(option) => {
              return option.label;
            }}
            options={employeeSelectItem}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size={"small"}
                label="Full name *"
                onChange={(event) => searchForTarget(event.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={showErrorMessage}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField id="outlined-basic" value={oppEmail} label={oppEmail ? '' : "Work email"} variant="outlined" fullWidth size={"small"} onChange={(event) => setOppEmail(event.target.value)} />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField id="outlined-basic" value={oppRegion} label={oppRegion ? '' : "Region"} variant="outlined" fullWidth size={"small"} onChange={(event) => setRegion(event.target.value)} />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField id="outlined-basic" value={oppHomeOffice} label={oppHomeOffice ? '' : "Home Office"} variant="outlined" fullWidth size={"small"} onChange={(event) => setHomeOffice(event.target.value)} />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField id="outlined-basic" value={oppPhoneNumber} label={oppPhoneNumber ? '' : "Work Phone"} variant="outlined" fullWidth size={"small"} onChange={(event) => setPhoneNumber(event.target.value)} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const ContactCharacteristicsDetails = (props) => {
  const { setConnectionType, setClassification, showErrorMessage, connectionType, classification } = props;
  const OUTREACH_TYPES = OUTREACH_TYPE_ITEMS;
  const PA_INDUSTRY_LIST = PA_INDUSTRY_LIST_ITEMS;
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Characteristics
        </Typography>
        {showErrorMessage && (
          <Alert severity="error" style={{ marginBottom: "10px" }}>
            Some required fields are missing
          </Alert>
        )}
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField required label="Connection Type *" name="outreach_type" menuItems={OUTREACH_TYPES} style={{ zIndex: 10 }} emptyError={showErrorMessage && connectionType == ""} setVariableChange={setConnectionType} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField required label="PA/Industry Classification *" name="industry_classification" variant="outlined" menuItems={PA_INDUSTRY_LIST} style={{ zIndex: 10 }} emptyError={showErrorMessage && classification == ""} setVariableChange={setClassification} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFTextField id="description" name="description" label="Topics discussed" fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFTextField id="comments" name="comments" label="comments" fullWidth variant="outlined" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
