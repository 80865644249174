import MUIDrawer from "../navbar/navigation";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  childrenContainer: {
    marginTop: "80px",
    marginLeft: "120px",
    marginBottom: "80px",
  },
});

export function Layout({ children }) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <MUIDrawer />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <div className={classes.childrenContainer}>{children}</div>
      </Grid>
    </Grid>
  );
}
