import axios from "axios";
import TokenStorage from "./TokenStorage";

export function httpAxiosResponseInterceptor(axios) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const errorResponse = error.response;

      if (errorResponse.status === 401) {
        return TokenStorage.getNewToken().then((response) => {
          errorResponse.config.__isRetryRequest = true;
          errorResponse.config.headers["Authorization"] =
            "Bearer " + response.data.access_token;

          return axios(errorResponse.config);
        });
      }
    }
  );
}
