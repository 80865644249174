import React from "react";

import "./utils/styles/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import OktaLayer from "./OktaLayer";

import { withOktaAuth } from "@okta/okta-react";
const queryClient = new QueryClient();
function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <OktaLayer />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

// wrapper for app
export default withOktaAuth(App);
