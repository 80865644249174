import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LabelList,
} from "recharts";
import Select from "react-select";

// const data = [
//   {
//     date: "2000-01",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   }
// ];

function CapabilitiesGraph(props) {
  const { data } = props;
  const [processedData, setProcessedData] = React.useState([]);
  const [dropDownOptions, setDropDownOptions] = React.useState(
    [...new Set(data.map((item) => item.project_code))].map((item) => {
      return {
        value: item,
        label:
          data.find((i) => i.project_code === item).client_name +
          " " +
          "[" +
          item +
          "]",
      };
    })
  );

  const [dropDownSelectedOption, setDropDownSelectedOption] = React.useState(
    dropDownOptions[0]
  );

  useEffect(() => {
    let filteredData = data.filter(
      (item) =>
        item.project_code === dropDownSelectedOption.value &&
        item.client_name ===
          dropDownSelectedOption.label.split(" ").slice(0, -1).join(" ")
    );

    let ungroupedData = filteredData.map((item) => {
      return {
        profile:
          item.profile.charAt(0).toUpperCase() +
          item.profile.slice(1).replace(/_/g, " "),
        num_weeks: Number(item.num_weeks),
        num_staffing: Number(item.num_staffing),
        level: item.level,
      };
    });
    // group by profile. do the sum of num_weeks and num_staffing for each profile
    let groupedData = ungroupedData.reduce((r, a) => {
      r[a.profile] = r[a.profile] || {};
      r[a.profile].profile = a.profile;
      r[a.profile].num_weeks =
        r[a.profile].num_weeks + a.num_weeks || a.num_weeks;
      r[a.profile].num_staffing =
        r[a.profile].num_staffing + a.num_staffing || a.num_staffing;
      r[a.profile].level = a.level;
      return r;
    }, Object.create(null));

    setProcessedData(
      Object.keys(groupedData).map((key) => {
        return groupedData[key];
      })
    );
  }, [dropDownSelectedOption]);

  const onSelectUpdated = (selectedOption) => {
    setDropDownSelectedOption(selectedOption);
  };

  //   const renderCustomizedLabel = (props) => {
  //     const { x, y, width, height, value } = props;
  //     const radius = 10;

  //     return (
  //       <g>
  //         {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
  //         <text
  //           x={x + width / 2}
  //           y={y * 2}
  //           fill="#000000"
  //           textAnchor="middle"
  //           dominantBaseline="middle"
  //         >
  //           {value}
  //         </text>
  //       </g>
  //     );
  //   };

  return (
    <>
      <Select
        options={dropDownOptions}
        placeholder={"Projects"}
        value={dropDownSelectedOption}
        onChange={onSelectUpdated}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
          }),
        }}
      />
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={processedData}
          margin={{
            top: 15,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="profile" />
          <YAxis
            yAxisId="left"
            label={{
              value: "Duration (Weeks)",
              angle: -90,
              position: "center",
              dx: -30,
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: "Number",
              angle: 90,
              position: "center",
              dx: 30,
            }}
          />
          <Tooltip />
          <Legend
            formatter={(value) =>
              value
                .replace(/_/g, " ")
                .replace(
                  /\w\S*/g,
                  (txt) =>
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                )
            }
          />
          <Bar
            dataKey="num_staffing"
            stroke="#DD742D"
            fill="#E08142"
            legendType="circle"
            radius={[5, 5, 0, 0]}
            yAxisId="left"
          >
            {/* <LabelList dataKey="level" content={renderCustomizedLabel} /> */}
          </Bar>
          <Bar
            dataKey="num_weeks"
            fill="rgb(82,122,66)"
            legendType="circle"
            radius={[5, 5, 0, 0]}
            yAxisId="right"
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default CapabilitiesGraph;
