import React, { useEffect, useState } from "react";

import { Grid, Container, Typography, Modal, Tabs, Tab } from "@mui/material";

import StallOpportunities from "./StallOpportunities";
import CapabilitiesChart from "./CapabilitiesChart";
import { useSelector, useDispatch } from "react-redux";
import { avgWeeklyRunRateAction, totalDurationInWeeksAction, get_metrics } from "../../utils/redux/action_creator/Opportunities_creators";
import { useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import TheoreticalVsActualCard from "../../components/dashboard/dashboardCards/TheoreticalVsActualCard";
import NumberOfOpportunitiesCard from "../../components/dashboard/dashboardCards/NumberOfOpportunitiesCard";
import PlainNumberCard from "../../components/dashboard/dashboardCards/PlainNumberCard";
import NumberOfFinanceDatesRecordsCard from "../../components/dashboard/dashboardCards/NumberOfFinanceDatesRecordsCard";
import CapabilitiesHeatmapCard from "../../components/dashboard/dashboardCards/CapabilitiesHeatmapCard";

import { financesNccTable } from "../../utils/redux/action_creator/finances_creators";
import { getFunnelChartDataAction } from "../../utils/redux/action_creator/Opportunities_creators";
import { get_capabilities } from "../../utils/redux/action_creator/Opportunities_creators";

import Filters from "../../components/dashboard/filters/Filters";
import Widget from "../../components/Widget/Widget";
import OppStatusBreakdown from "../../components/dashboard/graphs/OppStatusBreakdown";

import { formatDate } from "../../utils/formatTime";
import { calculateAverage, calculateSum } from "../../utils/helpers";

import useStyles from "./styles";
import NccTable from "./NccTable";
import { SearchFilterReducer } from "../../utils/redux/reducers/SearchFilterReducer";

import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
// import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AvTimerTwoToneIcon from "@mui/icons-material/AvTimerTwoTone";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";

export default function DashboardApp(props) {
    //-------------------------------------------------
    const params = useParams();

    //-----------------------------------------

    const [managerFullName, setManagerFullName] = useState("Manager Name");
    const [staffingRegion, setStaffingRegion] = useState("EMESA");
    const [selectedTab, setSelectedTab] = useState(0);

    const dispatch = useDispatch();
    const { searchFieldsData, searchAction } = useSelector((state) => state.SearchFilterReducer);
    const { user, error, isLoading, isError } = useSelector(
        // @ts-ignore
        (state) => state.GetUserReducer,
    );

    const { nccTableData, nccTableError, nccTableIsLoading, nccTableIsError } = useSelector((state) => state.FinanceNccTableReducer);

    const { avgWeeklyRunRateData, avgWeekerror, avgWeekisLoading, avgWeekisError } = useSelector(
        // @ts-ignore
        (state) => state.AvgWeeklyRunRateReducer,
    );

    const FunnelChartFullData = useSelector((state) => state.FunnelChartOpportunityReducer);

    const { totalDurationInWeeksData, totalDurationInWeeksError, totalDurationInWeeksLoading, totalDurationInWeeksIsError } = useSelector(
        // @ts-ignore
        (state) => state.TotalDurationInWeeksReducer,
    );

    const { opportunityMetricsData, opportunityMetricsError, opportunityMetricsLoading, opportunityMetricsIsError } = useSelector(
        // @ts-ignore
        (state) => state.GetOpportunityMetricsReducer,
    );

    const {
        capabilitiesTableData,
        capabilitiesTableError,
        capabilitiesTableIsLoading,
        capabilitiesTableIsError,
        // @ts-ignore
    } = useSelector((state) => state.capabilitiesReducer);

    const isAdmin = user?.is_admin;
    const { first_name, last_name, email_id, region, sub_users } = user || {};

    useEffect(() => {
        if (searchAction) {
            dispatch(avgWeeklyRunRateAction(searchFieldsData));
            dispatch(totalDurationInWeeksAction(searchFieldsData));
            dispatch(get_metrics(searchFieldsData));
            dispatch(financesNccTable({ ...searchFieldsData }));
            dispatch(getFunnelChartDataAction(searchFieldsData));
            dispatch(get_capabilities({ ...searchFieldsData }));
        }
    }, [searchAction]);

    const handleSubmit = (values) => {
        // setParamerts({ ...values });
    };

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Widget title="Filters" upperTitle bodyClass={useStyles.fullHeightBody} className={useStyles.card}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <Filters sub_users={sub_users ? sub_users : []} />
                    </div>
                </Widget>
            </Grid>

            <Tabs value={selectedTab} onChange={handleChange} aria-label="dashboard tabs" style={{ marginTop: "20px" }}>
                <Tab label={"Cases (" + (opportunityMetricsData?.opportunities?.oppsWithCodes?.length ?? 0) + ")"} />
                <Tab label={"Opportunities (" + (opportunityMetricsData?.opportunities?.oppsWithoutCodes?.length ?? 0) + ")"} />
            </Tabs>

            {selectedTab === 0 && (
                <Grid container xs={12} sm={12} md={12} spacing={2} justifyContent="center">
                    <script src="/path/to/d3.js"></script>
                    <script src="/path/to/dist/d3-funnel.js"></script>
                    {/* <Box display="flex" flexDirection="row" alignItems="stretch"> */}
                    <Grid container spacing={2} justifyContent="center" alignItems="stretch" style={{ marginTop: 30, paddingLeft: 20 }}>
                        {opportunityMetricsData && (
                            <>
                                <Grid item xs={6} md={2}>
                                    {/* This card also displays a breakdown of users by opportunity */}
                                    {/* <NumberOfOpportunitiesCard data={opportunityMetricsData} dataKey="oppsWithCodes" cardTitle="# Cases" /> */}
                                    <PlainNumberCard
                                        data={opportunityMetricsData?.opportunities?.oppsWithCodes?.length.toLocaleString()}
                                        cardTitle="# Cases"
                                        subTitle="Total Count"
                                        icon={<BusinessCenterOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={"$" + parseFloat(calculateAverage(nccTableData?.withCaseCode, "total_ncc").toFixed(2) || 0).toLocaleString()}
                                        cardTitle="Average NCC"
                                        subTitle="Amount in Dollars"
                                        // icon={<CreditCardOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={parseFloat(calculateAverage(nccTableData?.withCaseCode, "total_Working_hours") / 40 || 0)
                                            .toFixed(2)
                                            .toLocaleString()} // 40 to convert to weeks
                                        cardTitle="Average Duration"
                                        subTitle="Duration in Weeks"
                                        icon={<AvTimerTwoToneIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>

                                <Grid item xs={6} md={2}>
                                    <PlainNumberCard
                                        data={opportunityMetricsData?.employees?.count.toLocaleString()}
                                        cardTitle="# Employees Charging"
                                        subTitle="Total Count"
                                        icon={<BadgeOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>

                                <Grid item xs={6} md={2}>
                                    <PlainNumberCard
                                        data={formatDate(opportunityMetricsData?.finances?.newestRecordDateAcrossAllData)}
                                        cardTitle="Finance Entries"
                                        subTitle="Most Recent Entry"
                                        textVariant="h5"
                                        variant="secondary"
                                        icon={<DescriptionOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                    {/* <NumberOfFinanceDatesRecordsCard data={opportunityMetricsData.finances.newestRecordDate} /> */}
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TheoreticalVsActualCard
                            title="Total NCCs"
                            theoreticalValue={avgWeeklyRunRateData?.withCaseCode?.sum_avg_weekly_run_rate}
                            actualValue={avgWeeklyRunRateData?.withCaseCode?.sum_real_nccs}
                            unit="$"
                            infoText="This metric is calculated based on the opportunities that have been entered into the database and the financial data imported to the system. The actual NCC value is calculated by summing the entries in the finance data of the selected opportunities. The expected NCC value is calculated from aggregate the approriate finance entries of the selected opportunities. Using the NCC metric, you can gain valuable insights into the overall compensation structure of the application and identify opportunities for improvement. By comparing the expected and actual compensation amounts, you can better understand how to optimize your compensation plan to meet the needs and expectations of your clients."
                            isLoading={avgWeekisLoading}
                            opportunities={avgWeeklyRunRateData?.withCaseCode?.opportunities}
                            graphLabel="NCC ($)"
                            graphActualKeyValue="actualNCC"
                            graphExpectedKeyValue="expectedNCC"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TheoreticalVsActualCard
                            title="Total Time in Weeks"
                            theoreticalValue={totalDurationInWeeksData?.withCaseCode?.expected}
                            actualValue={totalDurationInWeeksData?.withCaseCode?.actual}
                            unit="Weeks"
                            infoText="The total amount of time in weeks is a metric that provides insight into the expected and actual length of client engagements. The actual length of client engagements is calculated by summing the entries in the finance data of selected opportunities. The expected value is calculated from the application’s opportunity entries. Using the total amount of time in weeks metric, you can gain valuable insights into the overall engagement structure of the projects and identify opportunities for improvement. By comparing the expected and actual engagement lengths, you can better understand how to optimize your engagement management to meet the needs and expectations of your clients."
                            isLoading={totalDurationInWeeksLoading}
                            opportunities={totalDurationInWeeksData?.withCaseCode?.opportunities}
                            graphLabel="Number of Weeks"
                            graphActualKeyValue="actual_weeks_number"
                            graphExpectedKeyValue="expected_weeks_number"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <NccTable data={nccTableData?.withCaseCode} isLoading={nccTableIsLoading} title="NCC distribution by case to" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} marginY={0.5}>
                        <OppStatusBreakdown FullData={FunnelChartFullData?.data?.withCaseCode} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <CapabilitiesChart data={capabilitiesTableData?.withCaseCode} isLoading={capabilitiesTableIsLoading} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <StallOpportunities />
                    </Grid>
                </Grid>
            )}

            {selectedTab === 1 && (
                <Grid container xs={12} sm={12} md={12} spacing={2} justifyContent="center">
                    <script src="/path/to/d3.js"></script>
                    <script src="/path/to/dist/d3-funnel.js"></script>
                    {/* <Box display="flex" flexDirection="row" alignItems="stretch"> */}
                    <Grid container spacing={2} justifyContent="center" alignItems="stretch" style={{ marginTop: 30, paddingLeft: 20 }}>
                        {opportunityMetricsData && (
                            <>
                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={opportunityMetricsData?.opportunities?.oppsWithoutCodes?.length.toLocaleString()}
                                        cardTitle="# Opportunities"
                                        subTitle="Total Count"
                                        icon={<BusinessCenterOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={"$" + calculateSum(opportunityMetricsData?.opportunities?.oppsWithoutCodes, "expectedNCC").toLocaleString()}
                                        cardTitle="Total Expected charge"
                                        subTitle="Amount in Dollars"
                                        // icon={<CreditCardOutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={calculateAverage(opportunityMetricsData?.opportunities?.oppsWithoutCodes, "expected_weeks_number").toLocaleString()}
                                        cardTitle="Average Duration"
                                        subTitle="Duration in Weeks"
                                        icon={<AvTimerTwoToneIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <PlainNumberCard
                                        data={calculateSum(opportunityMetricsData?.opportunities?.oppsWithoutCodes, "capabilities").toLocaleString()}
                                        cardTitle="# Staffing"
                                        subTitle="Total Count"
                                        icon={<Groups2OutlinedIcon style={{ marginLeft: "10px" }} fontSize="large" />}
                                    />
                                </Grid>
                                {/* <CapabilitiesHeatmapCard data={{ capabilitiesHeatmap: opportunityMetricsData.capabilitiesHeatmap }} /> */}
                            </>
                        )}
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TheoreticalVsActualCard
                            title="Total NCCs"
                            theoreticalValue={avgWeeklyRunRateData?.withoutCaseCode?.sum_avg_weekly_run_rate}
                            actualValue={avgWeeklyRunRateData?.withoutCaseCode?.sum_real_nccs}
                            unit="$"
                            infoText="This metric is calculated based on the opportunities that have been entered into the database and the financial data imported to the system. The actual NCC value is calculated by summing the entries in the finance data of the selected opportunities. The expected NCC value is calculated from aggregate the approriate finance entries of the selected opportunities. Using the NCC metric, you can gain valuable insights into the overall compensation structure of the application and identify opportunities for improvement. By comparing the expected and actual compensation amounts, you can better understand how to optimize your compensation plan to meet the needs and expectations of your clients."
                            isLoading={avgWeekisLoading}
                            opportunities={avgWeeklyRunRateData?.withoutCaseCode?.opportunities}
                            graphLabel="NCC ($)"
                            graphActualKeyValue="actualNCC"
                            graphExpectedKeyValue="expectedNCC"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TheoreticalVsActualCard
                            title="Total Time in Weeks"
                            theoreticalValue={totalDurationInWeeksData?.withoutCaseCode?.expected}
                            actualValue={totalDurationInWeeksData?.withoutCaseCode?.actual}
                            unit="Weeks"
                            infoText="The total amount of time in weeks is a metric that provides insight into the expected and actual length of client engagements. The actual length of client engagements is calculated by summing the entries in the finance data of selected opportunities. The expected value is calculated from the application’s opportunity entries. Using the total amount of time in weeks metric, you can gain valuable insights into the overall engagement structure of the projects and identify opportunities for improvement. By comparing the expected and actual engagement lengths, you can better understand how to optimize your engagement management to meet the needs and expectations of your clients."
                            isLoading={totalDurationInWeeksLoading}
                            opportunities={totalDurationInWeeksData?.withoutCaseCode?.opportunities}
                            graphLabel="Number of Weeks"
                            graphActualKeyValue="actual_weeks_number"
                            graphExpectedKeyValue="expected_weeks_number"
                        />
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={6}>
                        <NccTable data={nccTableData?.withoutCaseCode} isLoading={nccTableIsLoading} title="Expected NCC by opportunity" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} marginY={0.5}>
                        <OppStatusBreakdown FullData={FunnelChartFullData?.data?.withoutCaseCode} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <CapabilitiesChart data={capabilitiesTableData?.withoutCaseCode} isLoading={capabilitiesTableIsLoading} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <StallOpportunities />
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
