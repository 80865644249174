import { post, get } from "../http_methods/api";
import { useQuery } from "@tanstack/react-query";
import { BACKEDN_URL } from "../baseURL";

export const useEmployee = (preferredFirstName, preferredLastName) => {
  // const { data, isFetching, refetch } = useQuery(["perople_api_data"], async () => post(PROPLE_API_URL, body, null, header));

  const parameters = {
    preferredDisplayName: `${preferredFirstName}${preferredLastName}`,
  };
  const { data, isFetching, refetch } = useQuery(["perople_api_data"], async () => get(`${BACKEDN_URL}/api/v1/users/people-api`, parameters));
  if (data != undefined) {
    const employeesData = data;
    return { employeesData, isFetching, refetch };
  } else {
    const employeesData = [];
    return { employeesData, isFetching, refetch };
  }
};
