import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

// export const WidgetContainer = styled(Box)<BoxProps>`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: "20%";
//   gap: "2rem";
// `;

export const promoKPIStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: "24px",
      marginTop: "24px",
      background: "white",
      padding: 4,
    },
    card: {
      background: "#F4F4F4",
      minWidth: "166px",
      height: "100px",
      borderRadius: "5px",
      boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.15)",
      filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15))",
      flexGrow: 1,
      margin: "0 12px 0 12px",
    },
    cardContent: {
      padding: "12px!important",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#F4F4F4",
    },
    promoKPI: {
      padding: 4,
      backgroundColor: "white",
      width: "white",
      minHeight: 400,
    },
  })
);
