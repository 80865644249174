import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RHFTextField, RHFDatePicker } from "../../components/hook-form";
import {
  BG_STATUS,
  CAPABILITIES_PROFILES,
  CASE_STATUS,
  PROBABILITY_CONVERSION,
  REGIONS,
  SENIORITY,
  OPPORTUNITY_SOURCE_MENUITEMS,
} from "../../utils/static/Constants";
import ClearIcon from "@mui/icons-material/Clear";
// @ts-ignore
import RHFSelectField from "../../components/hook-form/RHFSelectField";
import { Button, Alert, AlertTitle } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const OpportunityDetailsInfo = (props) => {
  const {
    setClientName,
    setPrincipalFullName,
    setEntryDate,
    setRegion,
    setCountry,
    setBgStatus,
    showErrorMessage,
    listOfRequiredFieldsEmpty,
  } = props;
  const BG_STATUS_MENUE_ITEMS = BG_STATUS;
  const CASE_STATUS_MENUE_ITEMS = CASE_STATUS;
  const PROBABILITY_CONVERSION_MENUE_ITEMS = PROBABILITY_CONVERSION;
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Details infos
        </Typography>
        {showErrorMessage && (
          <Alert severity="error" style={{ marginBottom: "10px" }}>
            Some required fields are missing
          </Alert>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <RHFTextField
            id="client_name"
            name="client_name"
            label="Client name *"
            fullWidth
            variant="outlined"
            setVariableChange={setClientName}
            emptyError={listOfRequiredFieldsEmpty.includes("client_name")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFTextField
            id="principal_full_name"
            name="principal_full_name"
            label="Principal / Partner *"
            fullWidth
            variant="outlined"
            setVariableChange={setPrincipalFullName}
            emptyError={listOfRequiredFieldsEmpty.includes("principal_full_name")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            label="Region *"
            name="region"
            menuItems={REGIONS}
            setVariableChange={setRegion}
            emptyError={listOfRequiredFieldsEmpty.includes("region")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            label="Country *"
            name="country"
            isCountryField={true}
            setVariableChange={setCountry}
            emptyError={listOfRequiredFieldsEmpty.includes("country")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            label="Funding *"
            name="bg_status"
            menuItems={BG_STATUS_MENUE_ITEMS}
            setVariableChange={setBgStatus}
            emptyError={listOfRequiredFieldsEmpty.includes("bg_status")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            label="Opportunity status"
            name="case_status"
            menuItems={CASE_STATUS_MENUE_ITEMS}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <RHFDatePicker
            name="entry_date"
            label="Entry Date *"
            setVariableChange={setEntryDate}
            emptyError={listOfRequiredFieldsEmpty.includes("entry_date")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            label="Probability Of conversion"
            name="probability_conversion"
            menuItems={PROBABILITY_CONVERSION_MENUE_ITEMS}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const OpportunityCapabilities = (props) => {
  const CAPABILITIES_MENUE_ITEMS = CAPABILITIES_PROFILES;
  const [capabilities, setCapabilities] = useState([]);
  const [counter, setCounter] = useState(0);

  const addNewCapabilityHandler = () => {
    // @ts-ignore
    setCapabilities((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  // const deleteLocalCapability = (capabilityIndex) => {
  //   setCapabilities(
  //     capabilities.filter((capability, index) => index != capabilityIndex)
  //   );
  //   setCounter((prevCounter) => prevCounter - 1);
  // };
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Capabilities
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={12} sm={12} xl={12}>
          <div style={{ float: "right" }}>
            <Button
              onClick={addNewCapabilityHandler}
              color="success"
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <div style={{ marginRight: "5px" }}>Add</div>
              <AddCircleOutlineIcon />
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          xl={12}
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            maxHeight: "180px",
            minHeight: "180px",
            overflow: "scroll",
          }}
        >
          {capabilities.map((capability, index) => {
            const fieldName = `capabilities[${index}]`;
            return (
              <fieldset name={"fieldName"} key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // marginBottom: "10px",
                    margin: "10px",
                  }}
                >
                  <RHFSelectField
                    label="Profile"
                    name={`${fieldName}.it_profile_field`}
                    menuItems={CAPABILITIES_MENUE_ITEMS}
                    style={{ width: "20%" }}
                  />

                  <RHFSelectField
                    label="Level"
                    name={`${fieldName}.it_profile_level`}
                    menuItems={SENIORITY}
                    style={{ width: "20%" }}
                  />

                  <RHFTextField
                    id="num_profile"
                    name={`${fieldName}.num_profile`}
                    label="Staffing"
                    // fullWidth
                    variant="standard"
                    inputType={"number"}
                  />

                  <RHFTextField
                    id="num_weeks"
                    name={`${fieldName}.num_weeks`}
                    label="For how long (week)"
                    variant="standard"
                    inputType={"number"}
                    //   style={{ marginBo: 10 }}
                  />
                  {/* <Button
                    onClick={() => deleteLocalCapability(index)}
                    style={{ marginLeft: "10px" }}
                  >
                    <DeleteOutlineIcon style={{ color: "grey" }} />
                  </Button> */}
                </div>
              </fieldset>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export const OpportunityCommentsDescriptionInfo = (props) => {
  const { setEntryStartDate, setSource, showErrorMessage, listOfRequiredFieldsEmpty } = props;
  const OPPORTUNITY_SOURCES_MENUITEMS = OPPORTUNITY_SOURCE_MENUITEMS;
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Characteristics
        </Typography>
        {showErrorMessage && (
          <Alert severity="error" style={{ marginBottom: "10px" }}>
            Some required fields are missing
          </Alert>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <RHFDatePicker
            name="expected_start_date"
            label="Expect start date *"
            setVariableChange={setEntryStartDate}
            emptyError={listOfRequiredFieldsEmpty.includes("expected_start_date")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <RHFSelectField
            required
            label="Source (RMS, Existing Case, New connect, CT approached) *"
            name="source"
            menuItems={OPPORTUNITY_SOURCES_MENUITEMS}
            setVariableChange={setSource}
            emptyError={listOfRequiredFieldsEmpty.includes("source")}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <RHFTextField
            id="avg_weekly_run_rate"
            name="avg_weekly_run_rate"
            label="Avrg weekly rate"
            fullWidth
            variant="standard"
            inputType="number"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <RHFTextField
            id="weeks_number"
            name="weeks_number"
            label="#week"
            fullWidth
            variant="standard"
            inputType="number"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFTextField
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <RHFTextField
            id="comments"
            name="comments"
            label="Comments"
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
