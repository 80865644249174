import { UPDATE_EMPLOYEE_EXCLUSION } from "../ActionsTypes";

const update_initialState = {
  data: null,
  error: null,
  isLoading: false,
  isError: false,
};

export const UpdateEmployeeExclusionReducer = (
  state = update_initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_EXCLUSION.REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: true,
        isError: false,
      };
    case UPDATE_EMPLOYEE_EXCLUSION.SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        isError: false,
      };
    case UPDATE_EMPLOYEE_EXCLUSION.FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return { ...state };
  }
};
