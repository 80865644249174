import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import { fDate } from "../../../../utils/formatTime";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

export const CustomTableCell = ({ row, fieldID, isEditable, onChange, fieldType, fieldItemsValues, style, handleOnClick }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  const [inputText, setInputText] = useState(true);
  const [inputDate, setInputDate] = useState(false);
  const [inputSection, setInputSection] = useState(false);
  useEffect(() => {
    switch (fieldType) {
      case "section": {
        setInputDate(false);
        setInputText(false);
        setInputSection(true);
        break;
      }
      case "date": {
        // to be changed if needed!!
        setInputDate(false);
        setInputText(true);
        setInputSection(false);
        break;
      }
      default: {
        setInputDate(false);
        setInputText(true);
        setInputSection(false);
      }
    }
  }, [fieldType]);
  const MENU_ITEMS = fieldItemsValues;

  const getlabelFromField = (fieldValue) => {
    const result = fieldItemsValues.filter((field) => field.value === fieldValue)[0];
    if (result != null && result != undefined && result.label != null) return result.label;
    return fieldValue;
  };

  return (
    <TableCell align="left" style={{ ...style }} onClick={handleOnClick}>
      {isEditable && isEditMode
        ? (inputText && <Input value={row[fieldID]} name={fieldID} onChange={(e) => onChange(e, row)} className={classes.input} />) ||
          (inputSection && (
            <Select
              className={classes.input}
              options={MENU_ITEMS}
              placeholder={row[fieldID]}
              menuPlacement="auto"
              onChange={(item) => {
                if (item === null) return null;
                const e = {
                  target: {
                    value: item.value,
                    name: fieldID,
                  },
                };
                return onChange(e, row);
              }}
            />
          ))
        : "date" === fieldType
        ? fDate(row[fieldID])
        : "section" === fieldType
        ? getlabelFromField(row[fieldID])
        : row[fieldID]}
    </TableCell>
  );
};
