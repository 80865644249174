import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { MenuItem, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {};
// @ts-ignore
export default function RHFDatePicker({
  name,
  label,
  setVariableChange = () => {},
  emptyError,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name ? name : "date"}
      //      rules={{ required: false }} //optional
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={label}
              inputFormat="dd/MM/yyyy"
              value={value ? value : null}
              // in the onchange, both, call onChange and setVariableChange to the new value
              onChange={(newValue) => {
                onChange(newValue);

                if (newValue === null) setVariableChange("");
                else setVariableChange(newValue);
              }}
              reduceAnimations={true}
              renderInput={(params) => (
                <TextField
                  style={{ zIndex: 0 }}
                  label={label}
                  fullWidth
                  size={"small"}
                  {...params}
                  error={error ? true : false || emptyError}
                  helperText={error?.message}
                />
              )}
              //            onError={(e,v)=>fieldState.error}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}
