import { useDispatch, useSelector } from "react-redux";
import {
  employeesList,
  employeeImport,
  employeePrepareImport,
} from "../../utils/redux/action_creator/employees_creators";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Alert } from "@material-ui/lab";
import Button from "@mui/material/Button";
import CloudIcon from "@mui/icons-material/Cloud";
import { DataGrid } from "@mui/x-data-grid";
// import DropdownButton from "../../components/DropdownButton";
import {
  EMPLOYEES_TABLE_COLUMNS_TO_SHOW,
  EMPLOYEES_TABLE_COLUMNS_TO_HIDE,
} from "../../utils/static/Constants";
import {
  convertArrayToCsvString,
  downloadCsvFile,
} from "../../utils/export-csv";
import { CircularProgress, Tooltip, Zoom } from "@mui/material";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RepartitionIcon from "@mui/icons-material/Repartition";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// let do_get_all_finance_data = false;
const tooltipImportMessage = `Notes:<br/> - The expected file is the finance file <br/> - Expected file type: .csv <br/> - Already existing employees will be ignored`;

export default function EmployeeManagement(props) {
  const dispatch = useDispatch();

  const [success, setSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [filter, setFilter] = React.useState({});
  const [sorting, setSorting] = React.useState({});
  const [numberOfRowsImported, setNumberOfRowsImported] = React.useState(0);
  const [employeesProcessedData, setEmployeesProcessedData] = React.useState(
    []
  );
  const [employeesProcessedColumns, setEmployeesProcessedColumns] =
    React.useState([]);
  // const [selectedRows, setSelectedRows] = React.useState([]);
  const [isImportLoading, setIsImportLoading] = React.useState(false);
  const [isDataGridLoading, setIsDataGridLoading] = React.useState(true);
  // const [isFirstRun, setIsFirstRun] = React.useState(true);
  const [inputFileRef, setInputFileRef] = React.useState(null);
  const [inputPrepareImportFileRef, setInputPrepareImportFileRef] =
    React.useState(null);
  const [anchorElActionMenu, setAnchorElActionMenu] = React.useState(null);
  const openActionMenu = Boolean(anchorElActionMenu);
  const handleActionMenuClick = (event) => {
    setAnchorElActionMenu(event.currentTarget);
  };
  const handleActionMenuClose = () => {
    setAnchorElActionMenu(null);
  };

  const { employees, error, isLoading, isError } = useSelector(
    (state) => state.GetEmployeesReducer
  );
  const { message } = useSelector((state) => state.ImportEmployeesReducer);
  const { prepareImportMessage } = useSelector(
    (state) => state.PrepareImportEmployeesReducer
  );

  let rowIdTracker = 0;

  const handleFileUploadBtnClick = () => {
    inputFileRef.click();
    handleActionMenuClose();
  };
  const handleFilePrepareImportBtnClick = () => {
    inputPrepareImportFileRef.click();
    handleActionMenuClose();
  };

  React.useEffect(() => {
    if (message) {
      setSuccess(true);
      setNumberOfRowsImported(message.imported);
      setIsImportLoading(false);
      setEmployeesProcessedData([]);
      setIsDataGridLoading(true);
      dispatch(
        employeesList({
          page: 0,
          page_size: pageSize,
          column_field: filter?.columnField,
          operator_value: filter?.operatorValue,
          value: filter?.value,
          // get_all: true, // do_get_all_finance_data
        })
      );
    }
  }, [message]);

  React.useEffect(() => {
    setIsImportLoading(false);
    if (prepareImportMessage) {
      downloadCsvFile(
        prepareImportMessage,
        `employees_${moment().format("YYYY-MM-DD_HH-mm-ss")}.csv`
      );
    }
  }, [prepareImportMessage]);

  React.useEffect(() => {
    // TODO: to refactor. Necessity level: low
    const columns = EMPLOYEES_TABLE_COLUMNS_TO_SHOW.map((key) => {
      return {
        field: key,
        headerName: key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        sortable: true,
        width: 150,
        hide: false,
      };
    });

    // reorder column fields based on the order in the EMPLOYEES_TABLE_COLUMNS_TO_SHOW array
    columns.sort((a, b) => {
      return (
        EMPLOYEES_TABLE_COLUMNS_TO_SHOW.indexOf(a.field) -
        EMPLOYEES_TABLE_COLUMNS_TO_SHOW.indexOf(b.field)
      );
    });

    // add EMPLOYEES_TABLE_COLUMNS_TO_HIDE to the end of the columns array
    EMPLOYEES_TABLE_COLUMNS_TO_HIDE.forEach((key) => {
      columns.push({
        field: key,
        headerName: key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        sortable: true,
        width: 150,
        hide: true,
        status: false,
      });
    });

    setEmployeesProcessedColumns(columns);
    setEmployeesProcessedData([]);
    setIsDataGridLoading(true);
    dispatch(
      employeesList({
        page: currentPage,
        page_size: pageSize,
        column_field: filter?.columnField,
        operator_value: filter?.operatorValue,
        value: filter?.value,
        // get_all: true, // do_get_all_finance_data
      })
    );
  }, []);

  const handleFileUpload = (event) => {
    setIsImportLoading(true);
    // check if its an excel file
    if (
      // event.target.files[0].type === "application/vnd.ms-excel" ||
      // event.target.files[0].type ===
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "text/csv"
      // ||
      // event.target.files[0].type ===
      //   "application/vnd.ms-excel.sheet.binary.macroenabled.12"
      // // also accept .xlsb files
    ) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      dispatch(employeeImport(formData));
    } else {
      setErrorMessage(
        "Invalid file type. Please upload an excel file. (.xlsb, .xlsx, .csv)"
      );
    }
  };

  const handleFileUploadPrepareImport = (event) => {
    setIsImportLoading(true);
    // check if its an excel file
    if (
      // event.target.files[0].type === "application/vnd.ms-excel" ||
      // event.target.files[0].type ===
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "text/csv"
      // ||
      // event.target.files[0].type ===
      //   "application/vnd.ms-excel.sheet.binary.macroenabled.12"
      // // also accept .xlsb files
    ) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      dispatch(employeePrepareImport(formData));
    } else {
      setErrorMessage(
        "Invalid file type. Please upload an excel file. (.xlsb, .xlsx, .csv)"
      );
    }
  };

  const handleChangeRowsPerPageInfo = (newValue) => {
    setEmployeesProcessedData([]);
    setIsDataGridLoading(true);
    // Used to bypass material-ui's datagrid community limitation. NaN value is the representation of the "All" option in the dropdown
    if (newValue === employees?.pagination?.total) {
      setPageSize(0);
      // // archive was used as an attempt to immplement server side pagination
      // do_get_all_finance_data = true;
      dispatch(
        employeesList({
          page: 0,
          page_size: newValue,
          column_field: filter?.columnField,
          operator_value: filter?.operatorValue,
          value: filter?.value,
        })
      );
    } else {
      setPageSize(parseInt(newValue, 10));
      // do_get_all_finance_data = false;

      // // archive was used as an attempt to immplement server side pagination
      // do_get_all_finance_data = false;
      dispatch(
        employeesList({
          page: 0,
          page_size: newValue,
          column_field: filter?.columnField,
          operator_value: filter?.operatorValue,
          value: filter?.value,
        })
      );
    }
  };

  const handleSortModelChange = (sortModel) => {
    // Here you save the data you need from the sort model
    setEmployeesProcessedData([]);
    setIsDataGridLoading(true);
    setSorting(sortModel);
  };

  React.useEffect(() => {
    setIsDataGridLoading(true);
    dispatch(
      employeesList({
        page: currentPage,
        page_size: pageSize,
        column_field: filter?.columnField,
        operator_value: filter?.operatorValue,
        value: filter?.value,
        sortingField: sorting[0]?.field,
        sortingMode: sorting[0]?.sort,
      })
    );
  }, [sorting]);

  // archive was used as an attempt to immplement server side pagination
  const handleChangePageInfo = (newPage) => {
    // if (
    //   (newPage === 0 && currentPage === 0)
    //   // ||
    //   // (newPage === 0 && currentPage > 1) // TODO: investigate why this is needed
    // ) {
    //   return;
    // }
    setCurrentPage(newPage);

    setEmployeesProcessedData([]);
    setIsDataGridLoading(true);
    dispatch(
      employeesList({
        page: newPage,
        page_size: pageSize,
        column_field: filter?.columnField,
        operator_value: filter?.operatorValue,
        value: filter?.value,
      })
    );
  };

  React.useEffect(() => {
    if (employees?.data?.length > 0) {
      // setIsFirstRun(false);
      const data = employees?.data.map((row) => {
        return { ...row, id: rowIdTracker++ };
      });

      setEmployeesProcessedData(data);
    }
  }, [employees]);

  React.useEffect(() => {
    if (employeesProcessedData.length > 0) {
      setIsDataGridLoading(false);
    }
  }, [employeesProcessedData]);

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items[0]?.value !== undefined) {
      setFilter({
        columnField: filterModel?.items[0]?.columnField,
        operatorValue: filterModel?.items[0]?.operatorValue,
        value: filterModel?.items[0]?.value,
      });
    } else {
      setFilter({});
    }
  }, []);

  React.useEffect(() => {
    setEmployeesProcessedData([]);
    setIsDataGridLoading(true);
    if (filter && filter.columnField && filter.operatorValue && filter.value) {
      dispatch(
        employeesList({
          page: 0,
          page_size: pageSize,
          column_field: filter?.columnField,
          operator_value: filter?.operatorValue,
          value: filter?.value,
        })
      );
    } else {
      dispatch(
        employeesList({
          page: currentPage,
          page_size: pageSize,
        })
      );
    }
  }, [filter]);

  // FinanceManagement.propTypes = {
  //   count: PropTypes.number.isRequired,
  //   onPageChange: PropTypes.func.isRequired,
  //   page: PropTypes.number.isRequired,
  //   rowsPerPage: PropTypes.number.isRequired,
  // };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <>
        <div style={{ marginBottom: "20px", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {success && (
              <Alert severity="success">
                {numberOfRowsImported} rows imported successfully!
              </Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1
            style={{
              fontWeight: "semi-bold",
              fontSize: "1.4rem",
            }}
          >
            Employees
          </h1>

          {/* <Tooltip
            title={
              <span
                dangerouslySetInnerHTML={{ __html: tooltipImportMessage }}
              />
            }
            disableInteractive
            TransitionComponent={Zoom}
          > */}
          {/* <Button
            variant="contained"
            onClick={handleFileUploadBtnClick}
            color="success"
            style={{
              marginRight: "35px",
              textTransform: "none",
              gap: "10px",
            }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {isImportLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <>
                <div>Actions</div>
              </>
            )}
          </Button> */}

          <Button
            id="basic-button"
            aria-controls={openActionMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openActionMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            color="success"
            style={{
              marginRight: "35px",
              textTransform: "none",
              gap: "10px",
            }}
            onClick={handleActionMenuClick}
            endIcon={isImportLoading ? null : <KeyboardArrowDownIcon />}
          >
            {isImportLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <span>Actions</span>
            )}
          </Button>

          <Menu
            id="employees_import-menu"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorEl={anchorElActionMenu}
            open={openActionMenu}
            onClose={handleActionMenuClose}
          >
            <MenuItem onClick={handleFilePrepareImportBtnClick} disableRipple>
              <HealthAndSafetyIcon style={{ marginRight: "10px" }} />
              Prepare
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleFileUploadBtnClick} disableRipple>
              <MoveToInboxIcon style={{ marginRight: "10px" }} />
              Import
            </MenuItem>
          </Menu>

          {/* </Tooltip> */}
        </div>
        <div style={{ display: "none" }}>
          <input
            ref={(input) => setInputFileRef(input)}
            type="file"
            onChange={(event) => {
              handleFileUpload(event);
            }}
          />
          <input
            ref={(input) => setInputPrepareImportFileRef(input)}
            type="file"
            onChange={(event) => {
              handleFileUploadPrepareImport(event);
            }}
          />
        </div>
        <>
          <div
            style={{
              width: "98%",
              height: "820px",
              backgroundColor: "white",
              borderRadius: "10px",
              marginRight: "35px",
              // marginBottom: "0px",
              marginTop: "20px",
              flexDirection: "column",
              marginBottom: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "25px",
                paddingTop: "40px",
              }}
            >
              {/* <DropdownButton
                options={[
                  {
                    label: "CSV",
                    onClick: () => {
                      let data =
                        selectedRows.length > 0 ? selectedRows : employees?.data;
                      downloadCsvFile(
                        convertArrayToCsvString(employeesProcessedData),
                        `finance_data_${moment().format("YYYY-MM-DD")}.csv`
                      );
                    },
                  },
                ]}
              /> */}
            </div>
            <TableContainer
              component={Paper}
              autoSize={true}
              style={{
                width: "96%",
                height: "700px",
                marginTop: "20px",
                marginLeft: "35px",
                marginBottom: "100px",
              }}
            >
              <DataGrid
                rows={employeesProcessedData}
                columns={employeesProcessedColumns}
                // checkboxSelection
                // setup pagination
                filterMode="server"
                pagination
                pageSize={pageSize}
                page={currentPage}
                paginationMode="server"
                onFilterModelChange={onFilterChange}
                rowCount={employees?.pagination?.total || 0}
                rowsPerPageOptions={[
                  5, 10, 25, 50, 100,
                  // +employees?.pagination?.total,
                ]}
                onPageChange={handleChangePageInfo}
                onPageSizeChange={handleChangeRowsPerPageInfo}
                // paginationMode={do_get_all_finance_data ? "server" : "client"} // Used to bypass material-ui's datagrid community limitation
                // onSelectionModelChange={(newSelection) => {
                //   setSelectedRows(newSelection);
                // }}
                // set is loading
                loading={isDataGridLoading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </TableContainer>
          </div>
        </>
      </>
    </div>
  );
}
