import axios from "axios";
import { httpAxiosResponseInterceptor } from "./axiosInterceptors";
import TokenStorage from "./TokenStorage";

httpAxiosResponseInterceptor(axios);

export async function get(endpoint, parameters) {
  const searchQuery = convertMapToString(parameters);
  const access_token = TokenStorage.getToken();

  let headers = {};
  if (access_token) {
    headers = {
      authorization: `Bearer ${access_token}`,
    };
  }
  const data = await axios.get(`${endpoint}${searchQuery}`, {
    headers: {
      ...headers,
    },
  });
  const result = await data.data;
  return result;
}

export async function post(endpoint, body, parameters, headersOption) {
  const searchQuery = convertMapToString(parameters);
  const access_token = TokenStorage.getToken();
  let headers = {};
  if (access_token) {
    headers = {
      authorization: `Bearer ${access_token}`,
    };
  }
  const data = await axios.post(`${endpoint}${searchQuery}`, body, {
    headers: { ...headers, ...headersOption },
  });
  // const result = await data.data;
  return data;
}

export async function put(endpoint, body, parameters) {
  const searchQuery = convertMapToString(parameters);
  const data = await fetch(`${endpoint}${searchQuery}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const json = await data.json();
  return json;
}

export async function del(endpoint) {
  const data = await fetch(`${endpoint}/`, {
    method: "DELETE",
    credentials: "include",
  });

  const json = await data.json();
  return json;
}

export async function downloadFile(endpoint, type) {
  const access_token = TokenStorage.getToken();
  axios({
    url: `${endpoint}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    params: {
      email: TokenStorage.getEmail(),
    },
  }).then((response) => {
    const fileName = type + "_" + getTodayDate();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + ".csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

const getTodayDate = () => {
  let today = new Date();
  let dd = today.getDate().toString();
  let mm = (today.getMonth() + 1).toString();
  let yyyy = today.getFullYear();
  if (parseInt(dd) < 10) {
    dd = "0" + dd;
  }
  if (parseInt(mm) < 10) {
    mm = "0" + mm;
  }
  const result = mm + "-" + dd + "-" + yyyy;
  return result;
};

const convertMapToString = (parameters) => {
  if (parameters === undefined || parameters == null) return "";
  const params = [];
  for (const [key, value] of Object.entries(parameters)) {
    if (value == null || value == undefined || (typeof value === "string" && value.trim().length === 0)) continue;
    params.push(`${key}=${value}`);
  }
  if (params.length < 1) return "";
  return "?" + params.join("&");
};
