import { UPDATE_EMPLOYEE_EXCLUSION } from "../ActionsTypes";
import { get, post } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const update_employee_exclusion_action =
  (employee_exclusion_data) => async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYEE_EXCLUSION.REQUEST });

    try {
      const email = TokenStorage.getEmail();
      const params = { email: email };
      const result = await post(
        BACKEDN_URL + "/api/v1/employees-exclusion/update",
        employee_exclusion_data,
        params
      );
      if (result.status === 201) {
        dispatch({ type: UPDATE_EMPLOYEE_EXCLUSION.SUCCESS, payload: true });
      } else {
        dispatch({ type: UPDATE_EMPLOYEE_EXCLUSION.FAILED });
      }
    } catch (err) {
      dispatch({ type: UPDATE_EMPLOYEE_EXCLUSION.FAILED, payload: err });
    }
  };
