import React, { useEffect, useState } from "react";
// @ts-ignore
import TableComponent from "./components/table/TableComponent.jsx";
import { Button, Collapse, Grid, Typography } from "@mui/material";
import { ContactFormFilter } from "./contact-form-filter";
import ContactFilterContext from "../../utils/context/contact-filter-context";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { contactsList, downloadContactsAction } from "../../utils/redux/action_creator/contacts_creators";
import { useNavigate } from "react-router-dom";
import { OUTREACH_TYPE_ITEMS, REGIONS, PA_INDUSTRY_LIST_ITEMS } from "../../utils/static/Constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import treeData from "./contact_network_tree/data";
import ContactNetworkTree from "./contact_network_tree/ContactNetworkTree";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DropdownButton from "../../components/DropdownButton";
import Modal from "@mui/material/Modal";
import { NewContact } from "./new-contact";
import { updateContactAction } from "../../utils/redux/action_creator/contacts_creators";
import TableUpdateContext from "../../utils/context/table-update-context";

const tableColumns = [
  //{ field: "owner_full_name", title: "Owner Full Name", filterType: "" },
  { field: "oppl_full_name", title: "Full Name", filterType: "", isEditable: true },
  { field: "oppl_email", title: "Email", filterType: "", isEditable: true },
  { field: "opple_region", title: "Region", isEditable: true },
  {
    field: "outreach_type",
    title: "type",
    fieldType: "section",
    values: OUTREACH_TYPE_ITEMS,
    isEditable: true,
  },
  { field: "created_at", title: "Date", fieldType: "Date" },
  { field: "industry_classification", title: "Industry classification", isEditable: true, fieldType: "section", values: PA_INDUSTRY_LIST_ITEMS },
];

const detailsFields = [
  { field: "description", title: "Description", filterType: "", isEditable: true },
  { field: "comments", title: "Comments", filterType: "", isEditable: true },
];

export default function Contacts() {
  const [parameters, setParameters] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const { data, error, isLoading, isError } = useSelector((state) => state.ContactReducer);
  const { user } = useSelector((state) => state.GetUserReducer);
  const metaData = data ? data.meta : { is_modified: false, pages: 0 };

  const submitSearchFilter = (values) => {
    setParameters({ ...values });
  };

  useEffect(() => {
    if (user && user.is_admin) {
      if (tableColumns[0].field != "owner_full_name") {
        tableColumns.unshift({
          field: "owner_full_name",
          title: "Owner",
          fieldType: "",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!modalOpen) dispatch(contactsList(parameters));
  }, [parameters, modalOpen]);

  const handlePageChange = (event) => {
    setParameters({ ...parameters, page: event.selected + 1 });
  };

  if (isError) {
  }

  const handleNewContactClick = () => {
    // navigate("/contacts/new");
    setModalOpen(true);
  };
  const handleExportCSV = () => {
    dispatch(downloadContactsAction());
  };

  const clearFormHandler = (defaultValues) => {
    setParameters({ ...parameters, ...defaultValues });
  };
  const onDoubleClickOnRow = (row) => {
    // fetch all outreach for treeData
  };

  const updateTable = (updatedContact) => {
    dispatch(updateContactAction(updatedContact.id, updatedContact));
  };

  return (
    <div style={{ margin: "2%" }}>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NewContact handleModalClose={handleModalClose} />
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontSize={40}>Contacts</Typography>

        <div style={{ float: "right", cursor: "pointer", margin: "1%" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleNewContactClick}
            style={{
              margin: "10px",
              textTransform: "none",
              gap: "10px",
            }}
          >
            <div>New Contact</div>
            <AddCircleIcon />
          </Button>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item sx={12} md={12} sm={12}>
          <ContactFilterContext.Provider value={{ submit: submitSearchFilter }}>
            <Collapse in={openFilter} timeout="auto" unmountOnExit>
              <ContactFormFilter onSubmit={submitSearchFilter} clearForm={clearFormHandler} />
            </Collapse>
          </ContactFilterContext.Provider>
        </Grid>

        <Grid item py={1} sx={12} md={12} sm={12}>
          <div
            style={{
              widht: "100%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="success"
              variant="outlined"
              onClick={() => setOpenFilter(!openFilter)}
              // sx={{ cursor: "pointer" }}
              style={{
                margin: "10px",
                textTransform: "none",
                gap: "10px",
              }}
            >
              <FilterListIcon /> Search
            </Button>
            <DropdownButton options={[{ label: "CSV", onClick: handleExportCSV }]} />
          </div>
          <TableUpdateContext.Provider value={{ updateTable: updateTable }}>
            <TableComponent data={data ? data.contacts : []} isLoading={isLoading} tableColumns={tableColumns} detailsFields={detailsFields} showFilter={true} isModified={true} detailsExist={true} onDoubleClickOnRow={onDoubleClickOnRow} />
          </TableUpdateContext.Provider>
          <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageChange} pageRangeDisplayed={3} pageCount={metaData.pages} previousLabel="< previous" containerClassName="pagination justify-content-center" pageClassName={"page-item"} pageLinkClassName="page-link" previousClassName={"page-item"} nextClassName={"page-item"} previousLinkClassName="page-link" nextLinkClassName="page-link" activeClassName="active" />
        </Grid>
      </Grid>
    </div>
  );
}
