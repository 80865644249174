import React, { useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Select from "react-select";

// const Mockdata = [
//   {
//     subject: "Math",
//     A: 120,
//     B: 110,
//   },
// ];

function CapabilitiesRadarChart(props) {
  const { data } = props;
  const [processedData, setProcessedData] = React.useState([]);
  const [dropDownOptions, setDropDownOptions] = React.useState(
    [...new Set(data.map((item) => item.project_code))].map((item) => {
      return {
        value: item,
        label:
          data.find((i) => i.project_code === item).client_name +
          " " +
          "[" +
          item +
          "]",
      };
    })
  );
  const [dropDownSelectedOption, setDropDownSelectedOption] = React.useState(
    dropDownOptions[0]
  );

  useEffect(() => {
    let filteredData = data.filter(
      (item) =>
        item.project_code === dropDownSelectedOption.value &&
        item.client_name ===
          dropDownSelectedOption.label.split(" ").slice(0, -1).join(" ")
    );

    let ungroupedData = filteredData.map((item) => {
      return {
        profile:
          item.profile.charAt(0).toUpperCase() +
          item.profile.slice(1).replace(/_/g, " "),
        num_weeks: Number(item.num_weeks),
        num_staffing: Number(item.num_staffing),
      };
    });
    // group by profile. do the sum of num_weeks and num_staffing for each profile
    let groupedData = ungroupedData.reduce((r, a) => {
      r[a.profile] = r[a.profile] || {};
      r[a.profile].profile = a.profile;
      r[a.profile].num_weeks =
        r[a.profile].num_weeks + a.num_weeks || a.num_weeks;
      r[a.profile].num_staffing =
        r[a.profile].num_staffing + a.num_staffing || a.num_staffing;
      return r;
    }, Object.create(null));

    setProcessedData(
      Object.keys(groupedData).map((key) => {
        return groupedData[key];
      })
    );
  }, [dropDownSelectedOption]);

  const onSelectUpdated = (selectedOption) => {
    setDropDownSelectedOption(selectedOption);
  };

  return (
    <>
      <Select
        options={dropDownOptions}
        placeholder={"Projects"}
        value={dropDownSelectedOption}
        onChange={onSelectUpdated}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
          }),
        }}
      />
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart data={processedData}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="profile"
            angle={-30}
            tick={{ fontSize: 15 }}
          />
          <PolarRadiusAxis />
          <Radar
            name="Number of Weeks"
            dataKey="num_weeks"
            stroke="#75B58D"
            fill="#82ca9d"
            fillOpacity={0.6}
            legendType="circle"
          />
          <Radar
            name="Staffing"
            dataKey="num_staffing"
            stroke="#DD742D"
            fill="#E08142"
            fillOpacity={0.6}
            legendType="circle"
          />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </>
  );
}

export default CapabilitiesRadarChart;
