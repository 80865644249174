import React from "react";
import { CircularProgress, Grid, LinearProgress, Typography, Box } from "@mui/material";
import Widget from "../../Widget/Widget";
import Dot from "../Dot";

import OppsGraph from "../graphs/OppsGraph";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

// import useStyles from "./styles";

function TheoreticalVsActualCard(props) {
    // const classes = useStyles;
    const { title, theoreticalValue, actualValue, unit, infoText, isLoading, opportunities, graphLabel, graphExpectedKeyValue, graphActualKeyValue } = props;

    const highestValue = Math.max(theoreticalValue, actualValue);
    const lowestValue = Math.min(theoreticalValue, actualValue);
    const difference = highestValue - lowestValue;

    const division = (a, b) => {
        if (b === 0) return 0;
        return (a / b) * 100;
    };
    const achievementRatio = division(actualValue, theoreticalValue);
    const normalizedTheoreticalValue = division(theoreticalValue, highestValue);
    const normalizedActualValue = division(actualValue, highestValue);

    const PerformanceLegendWrapper = styled("div")(({ theme }) => ({
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        marginBottom: "10px",
    }));

    const LegendElement = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        marginRight: "20px",
    }));

    const ProgressSection = styled("div")(({ theme }) => ({
        marginBottom: "10px",
    }));

    return (
        <>
            <Widget
                title={title}
                upperTitle
                // className={classes.card}
                // bodyClass={classes.fullHeightBody}
                infoText={infoText}
                opportunities={opportunities}
            >
                <Grid container direction="row" justify="space-between" alignItems="center" style={{}}>
                    <Grid item xs={6}>
                        <PerformanceLegendWrapper>
                            <LegendElement>
                                <Dot color="#457b3b" />
                                <Typography color="text" colorBrightness="secondary">
                                    Actual
                                </Typography>
                            </LegendElement>
                            <LegendElement>
                                <Dot color="#DD742D" />
                                <Typography color="text" colorBrightness="secondary">
                                    Expected
                                </Typography>
                            </LegendElement>
                        </PerformanceLegendWrapper>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color="text" colorBrightness="secondary" noWrap>
                            Difference
                        </Typography>
                        {!isLoading ? (
                            <Typography size="md">
                                {/* add comma after each 3 digits */}
                                {unit.match(/[^a-z]/i) !== null ? unit : ""} {difference.toLocaleString()}
                                {unit.match(/[^a-z]/i) === null ? " " + unit : ""}
                            </Typography>
                        ) : (
                            <div
                                style={{
                                    marginLeft: "20px",
                                }}
                            >
                                <CircularProgress size={20} color="success" />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color="text" colorBrightness="secondary" noWrap>
                            Achievement Ratio
                        </Typography>
                        {!isLoading ? (
                            <Typography size="md">{achievementRatio.toFixed(2)}%</Typography>
                        ) : (
                            <div
                                style={{
                                    marginLeft: "20px",
                                }}
                            >
                                <CircularProgress size={20} color="success" />
                            </div>
                        )}
                    </Grid>
                </Grid>
                <ProgressSection>
                    {!isLoading ? (
                        <Typography
                            fontSize={22}
                            color="text"
                            colorBrightness="secondary"
                            // className={classes.progressSectionTitle}
                        >
                            {unit.match(/[^a-z]/i) !== null ? unit : ""} {actualValue?.toLocaleString()} {unit.match(/[^a-z]/i) === null ? unit : ""}
                        </Typography>
                    ) : (
                        <Typography fontSize={22} color="white">
                            Loading
                        </Typography>
                    )}
                    <LinearProgress
                        color="success"
                        variant={!isLoading ? "determinate" : "indeterminate"}
                        value={normalizedActualValue}
                        style={{ backgroundColor: "rgb(236, 236, 236)" }}
                    />
                </ProgressSection>
                <ProgressSection>
                    {!isLoading ? (
                        <Typography
                            fontSize={22}
                            color="text"
                            colorBrightness="secondary"
                            // className={classes.progressSectionTitle}
                        >
                            {unit.match(/[^a-z]/i) !== null ? unit : ""} {theoreticalValue?.toLocaleString()} {unit.match(/[^a-z]/i) === null ? unit : ""}
                        </Typography>
                    ) : (
                        <Typography fontSize={22} color="white">
                            Loading
                        </Typography>
                    )}
                    <LinearProgress
                        color="warning"
                        variant={!isLoading ? "determinate" : "indeterminate"}
                        value={normalizedTheoreticalValue}
                        style={{ backgroundColor: "rgb(236, 236, 236)" }}
                    />
                </ProgressSection>

                {opportunities && (
                    <Accordion style={{ marginTop: 30 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{ marginLeft: 10 }}>
                            <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OppsGraph data={opportunities} label={graphLabel} expected_key_value={graphExpectedKeyValue} actual_key_value={graphActualKeyValue} />
                        </AccordionDetails>
                    </Accordion>
                )}
            </Widget>
        </>
    );
}

export default TheoreticalVsActualCard;
