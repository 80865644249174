import React, { Fragment, useState, useEffect } from "react";
import { promoKPIStyles } from "./styles";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Grid,
  Button,
  MenuItem,
  Checkbox,
  Select,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import _ from "lodash";
import {
  getWeekNumberFromDate,
  getDateFromWeekNumber,
} from "../../../utils/formatTime";

// import TimesheetChart from "./TimesheetChart";
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const KpiIndicator = (props) => {
  const classes = promoKPIStyles();
  const { KPIs, data } = props;

  const [filteredData, setFilteredData] = useState(data["finance"]);
  const [tsHoursChargesChartData, setTsHoursChargesChartData] = useState([]);
  const [uniqueEmployees, setUniqueEmployees] = useState(
    Array.from(new Set(data["finance"].map((item) => item["employee_name_tb"])))
  );
  const [selectedEmployees, setSelectedEmployees] = useState(uniqueEmployees);
  const [uniqueEmployeeRegions, setUniqueEmployeeRegions] = useState(
    Array.from(new Set(data["finance"].map((item) => item["employee_region"])))
  );
  const [selectedEmployeeRegions, setSelectedEmployeeRegions] = useState(
    uniqueEmployeeRegions
  );

  const oldestYearFinance = _.minBy(data["finance"], (item) => {
    return item["year"];
  })["year"];

  const oldestWeekFinance = _.minBy(data["finance"], (item) => {
    if (item["year"] === oldestYearFinance) {
      return item["week_of_year"];
    }
  })["week_of_year"];
  const newestYearFinance = _.maxBy(data["finance"], (item) => {
    return item["year"];
  })["year"];

  const newestWeekFinance = _.maxBy(data["finance"], (item) => {
    if (item["year"] === newestYearFinance) {
      return item["week_of_year"];
    }
  })["week_of_year"];

  const [uniqueSquads, setUniqueSquads] = useState(
    Array.from(new Set(data["finance"].map((item) => item["letter"])))
  );
  const [selectedSquads, setSelectedSquads] = useState(uniqueSquads);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getDateFromWeekNumber(oldestYearFinance, oldestWeekFinance),
    endDate: getDateFromWeekNumber(newestYearFinance, newestWeekFinance),
    key: "selection",
  });
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const handleDateRangeBtnClick = () => {
    toggleDatePicker();
  };
  const toggleDatePicker = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDateRangePickerOpen &&
        !event.target.closest(".rdrDateRangeWrapper") &&
        !event.target.closest('[name="date-range-picker-btn"]')
      ) {
        setIsDateRangePickerOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDateRangePickerOpen]);

  const handleDateRangeChange = (item) => {
    let startDate = new Date(item.selection.startDate);
    let endDate = new Date(item.selection.endDate);

    // if (startDate.getTime() !== selectedDateRange.startDate.getTime())
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    // if (endDate.getTime() !== selectedDateRange.endDate.getTime())
    endDate.setDate(endDate.getDate() - endDate.getDay() + 5);

    setSelectedDateRange({
      startDate,
      endDate,
      key: "selection",
    });
  };

  const handleFilterChange = (event) => {
    const {
      target: { name, value },
    } = event;
    let currentValue = typeof value === "string" ? value.split(",") : value;

    if (name === "squad") {
      if (value.includes("all")) {
        if (uniqueSquads.length === selectedSquads.length)
          setSelectedSquads([]);
        else setSelectedSquads(uniqueSquads.map((item) => item.value));
      } else setSelectedSquads([...value]);
    } else if (name === "employees") {
      if (value.includes("all")) {
        if (uniqueEmployees.length === selectedEmployees.length)
          setSelectedEmployees([]);
        else setSelectedEmployees(uniqueEmployees);
      } else setSelectedEmployees([...value]);
    } else if (name === "employeeRegions") {
      if (value.includes("all")) {
        if (uniqueEmployeeRegions.length === selectedEmployeeRegions.length)
          setSelectedEmployeeRegions([]);
        else setSelectedEmployeeRegions(uniqueEmployeeRegions);
      } else setSelectedEmployeeRegions([...value]);
    }
  };

  useEffect(() => {
    setFilteredData(
      data["finance"].filter((item) => {
        const weekOfYear = item["week_of_year"];
        const year = item["year"];

        const selectedStartYear = selectedDateRange.startDate.getFullYear();
        const selectedStartWeek = getWeekNumberFromDate(
          selectedDateRange.startDate
        )[1];
        const selectedEndYear = selectedDateRange.endDate.getFullYear();
        const selectedEndWeek = getWeekNumberFromDate(
          selectedDateRange.endDate
        )[1];

        return (
          selectedSquads.includes(item["letter"]) &&
          selectedEmployees.includes(item["employee_name_tb"]) &&
          selectedEmployeeRegions.includes(item["employee_region"]) &&
          (selectedStartYear < year ||
            (selectedStartYear === year && selectedStartWeek <= weekOfYear)) &&
          (selectedEndYear > year ||
            (selectedEndYear === year && selectedEndWeek >= weekOfYear))
        );
      })
    );
  }, [
    selectedSquads,
    selectedEmployees,
    selectedDateRange,
    selectedEmployeeRegions,
  ]);

  useEffect(() => {
    updateChartData();
  }, [filteredData]);

  useEffect(() => {
    updateChartData();
  }, []);

  function updateChartData() {
    let chartData = [];
    const groupedData = _.groupBy(filteredData, (item) => {
      return item["week_of_year"] + item["year"];
    });
    for (const [key, value] of Object.entries(groupedData)) {
      const net_client_charges_total = _.sumBy(value, (item) => {
        return item["net_client_charges_total"];
      });
      const timesheet_hours = _.sumBy(value, (item) => {
        return item["timesheet_hours"];
      });
      chartData.push({
        name: `Week ${value[0]["week_of_year"]} ${value[0]["year"]}`,
        net_client_charges_total: net_client_charges_total,
        timesheet_hours: timesheet_hours,
      });
    }
    setTsHoursChargesChartData(chartData);
  }

  return (
    <div>
      <Accordion
        elevation={2}
        style={{ borderRadius: "20px", background: "#477969", color: "white" }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Typography>Client Name</Typography>
              <Typography variant="h4">
                {data["client_name"]}{" "}
                {data["project_code"] ? "[" + data["project_code"] + "]" : null}
              </Typography>
            </div>

            <div
              style={{
                marginRight: "20px",
              }}
            >
              <div>
                <Typography>
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Owner:{" "}
                  </span>
                  {data["owner"]?.first_name} {data["owner"]?.last_name}
                </Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Create at:
                  </span>{" "}
                  {data["created_at"]}
                </Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  <span
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    Updated at:{" "}
                  </span>
                  {data["updated_at"]}
                </Typography>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 19 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h6"
                  >
                    Filters
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <div
                        sx={{ m: 1, width: 250 }}
                        style={{ marginTop: "10px", marginLeft: "20px" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleDateRangeBtnClick}
                          name="date-range-picker-btn"
                          style={{
                            borderColor: "#bfbfbf",
                            color: "#666666",
                            height: "57px",
                            marginRight: "10px",
                          }}
                        >
                          {selectedDateRange.startDate &&
                          selectedDateRange.endDate
                            ? `${selectedDateRange.startDate.toLocaleDateString()} - ${selectedDateRange.endDate.toLocaleDateString()}`
                            : "Select Date Range"}
                          <CalendarMonthIcon style={{ marginLeft: "20px" }} />
                        </Button>
                        <div
                          style={{
                            position: "absolute",
                            top: "250px",
                            zIndex: "99999",
                          }}
                        >
                          {isDateRangePickerOpen && (
                            <DateRangePicker
                              rangeColors={["#3A795A"]}
                              color="#3A795A"
                              name="dateRange"
                              ranges={[selectedDateRange]}
                              onChange={handleDateRangeChange}
                              minDate={getDateFromWeekNumber(
                                oldestYearFinance,
                                oldestWeekFinance
                              )}
                              maxDate={getDateFromWeekNumber(
                                newestYearFinance,
                                newestWeekFinance
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl sx={{ m: 1, width: 250 }}>
                        <InputLabel
                          id="squad-multiple-checkbox-label"
                          style={{ width: "70%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            Squad
                          </div>
                        </InputLabel>
                        <Select
                          labelId="squad-multiple-checkbox-label"
                          id="squad-multiple-checkbox"
                          multiple
                          value={selectedSquads}
                          label="Squad"
                          name="squad"
                          onChange={handleFilterChange}
                          input={
                            <OutlinedInput label="squad" name="squadInput" />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value={"all"}>
                            <Checkbox
                              checked={
                                selectedSquads.length === uniqueSquads.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>

                          {uniqueSquads.map((letter) => (
                            <MenuItem key={letter} value={letter}>
                              <Checkbox
                                checked={selectedSquads.indexOf(letter) > -1}
                              />
                              <ListItemText primary={letter} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl sx={{ m: 1, width: 250 }}>
                        <InputLabel
                          id="squad-multiple-checkbox-label"
                          style={{ width: "70%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            Employess
                          </div>
                        </InputLabel>
                        <Select
                          labelId="employees-multiple-checkbox-label"
                          id="employees-multiple-checkbox"
                          multiple
                          value={selectedEmployees}
                          label="Employees"
                          name="employees"
                          onChange={handleFilterChange}
                          input={
                            <OutlinedInput
                              label="Employees"
                              name="employeesInput"
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value={"all"}>
                            <Checkbox
                              checked={
                                selectedEmployees.length ===
                                uniqueEmployees.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>

                          {uniqueEmployees.map((employeeName) => (
                            <MenuItem key={employeeName} value={employeeName}>
                              <Checkbox
                                checked={
                                  selectedEmployees.indexOf(employeeName) > -1
                                }
                              />
                              <ListItemText primary={employeeName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormControl sx={{ m: 1, width: 250 }}>
                        <InputLabel
                          id="squad-multiple-checkbox-label"
                          style={{ width: "70%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            Employess Region
                          </div>
                        </InputLabel>
                        <Select
                          labelId="employees-regions-multiple-checkbox-label"
                          id="employees-regions-multiple-checkbox"
                          multiple
                          value={selectedEmployeeRegions}
                          label="Employees Regions"
                          name="employeeRegions"
                          onChange={handleFilterChange}
                          input={
                            <OutlinedInput
                              label="Employee Regions"
                              name="employeeRegionsInput"
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value={"all"}>
                            <Checkbox
                              checked={
                                selectedEmployeeRegions.length ===
                                uniqueEmployeeRegions.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>

                          {uniqueEmployeeRegions.map((region) => (
                            <MenuItem key={region} value={region}>
                              <Checkbox
                                checked={
                                  selectedEmployeeRegions.indexOf(region) > -1
                                }
                              />
                              <ListItemText primary={region} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "20px" }}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      Total Timesheet Hours
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          {filteredData
                            ? parseInt(
                                filteredData.reduce(
                                  (acc, cur) => acc + cur["timesheet_hours"],
                                  0
                                )
                              ).toLocaleString()
                            : 0}{" "}
                          Hours
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginBottom: "20px" }}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      Net Client Charges
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          ${" "}
                          {filteredData
                            ? parseFloat(
                                filteredData.reduce(
                                  (acc, cur) =>
                                    acc + cur["net_client_charges_total"],
                                  0
                                )
                              )
                                // .toFixed(2)
                                .toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 19 }}
                      color="text.secondary"
                      gutterBottom
                      variant="h6"
                    >
                      # Employees Charging
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography variant="h4" component="div">
                          {filteredData
                            ? filteredData
                                .reduce(
                                  (acc, cur) => acc.add(cur["employee_id_tb"]),
                                  new Set()
                                )
                                .size.toLocaleString()
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Card
                variant="outlined"
                style={{ width: "100%", height: "100%" }}
              >
                <CardContent style={{ width: "100%", height: "100%" }}>
                  <ResponsiveContainer width="100%" height="100%">
                    {tsHoursChargesChartData?.length > 0 ? (
                      <LineChart
                        width={500}
                        height={300}
                        data={tsHoursChargesChartData}
                        margin={{
                          top: 50,
                          right: 30,
                          left: 50,
                          bottom: -30,
                        }}
                      >
                        <text
                          // x={(window.innerWidth * 9) / 12 / 3}
                          y={20}
                          fill="black"
                        >
                          <tspan
                            fontSize="18"
                            style={{
                              fill: "grey",
                            }}
                          >
                            Timesheet Hours vs Charges Across Time
                          </tspan>
                        </text>

                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          // make the text vertical
                          angle={-15}
                          textAnchor="end"
                        />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Line
                          yAxisId="left"
                          type="monotone"
                          dataKey="net_client_charges_total"
                          name="NCC"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          yAxisId="right"
                          type="monotone"
                          name="Timesheet Hours"
                          dataKey="timesheet_hours"
                          stroke="#82ca9d"
                        />
                      </LineChart>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                        }}
                      >
                        No finance records found.
                      </div>
                    )}
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card
                variant="outlined"
                style={{ width: "100%", height: "100%" }}
              >
                <CardContent style={{ width: "100%", height: "100%" }}>
                  <TimesheetChart chartData={chartData} />
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default KpiIndicator;
