import React, { useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Container, Typography, Button } from "@mui/material";

// ----------------------------------------------------------------------
import { useSelector, useDispatch } from "react-redux";
import { withOktaAuth } from "@okta/okta-react";

import { ReactComponent as OktaIcon } from "../../assets/okta.svg";
import backgroundImage from "../../assets/BCG_X.jpg";
import Avatar from "@mui/material/Avatar";

import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default withOktaAuth(function Login(props) {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {"Copyright © "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://d3fapc3a0zkaxf.cloudfront.net">
          By Boston Consulting Group.
        </Link>{" "}
        All rights reserved
        {"."}
      </Typography>
    );
  }
  const theme = createTheme();

  const onSignIn = () => {
    props.oktaAuth.signInWithRedirect();
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 700,
          }}
        >
          <Box component="img" sx={{ marginTop: 2, borderRadius: 4 }} my={4} alt={"BCG"} src={backgroundImage} />

          <Button type="submit" fullWidth onClick={onSignIn} variant="contained" sx={{ mt: 3, mb: 2 }}>
            Login
          </Button>
        </Box>

        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
});
