import React from "react";
import { Router } from "./router";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";

const port = (window.location.port && `:${window.location.port}`) || "";

const config = {
  issuer: process.env.REACT_APP_OAUTH_URL,
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

const OktaLayer = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    await navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div style={{ backgroundColor: "rgb(246,247,248)" }}>
        <Router />
      </div>
    </Security>
  );
};

export default OktaLayer;
