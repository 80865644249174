import {
    CaseStatusChartDataReducer,
    OpportunityReducer,
    ProjectWorkingWeeksDataReducer,
    CreateOpportunityReducer,
    AvgWeeklyRunRateReducer,
    StallOpportunitiesReducer,
    FunnelChartOpportunityReducer,
    OpportunityDetailsReducer,
    TotalDurationInWeeksReducer,
    GetOpportunityMetricsReducer,
    capabilitiesReducer,
    editOpportunityReducer,
} from "./OpportunityReducer";
import { ContactReducer, CreateContactReducer, UpdateContactReducer } from "./ContactsReducer";
import { GetUserReducer, LoginReducer, LogoutReducer } from "./AuthenticationReducer";

import { AssignUserAdminReducer, DismissUserAdminReducer, PeopleApiReducer } from "./UserReducer";
import { SearchFilterReducer } from "./SearchFilterReducer";

import { GetFinanceReducer, ImportFinanceReducer, FinanceNccTableReducer, WipeImportFinanceReducer } from "./FinancesReducer";
import { GetEmployeesReducer, ImportEmployeesReducer, PrepareImportEmployeesReducer } from "./EmployeeReducer";

import { UpdateEmployeeExclusionReducer } from "./EmployeeExclusionReducer";

const RoutReducer = {
    OpportunityReducer,
    ContactReducer,
    LoginReducer,
    CaseStatusChartDataReducer,
    ProjectWorkingWeeksDataReducer,
    LogoutReducer,
    GetUserReducer,
    CreateOpportunityReducer,
    CreateContactReducer,
    UpdateContactReducer,

    AvgWeeklyRunRateReducer,
    TotalDurationInWeeksReducer,
    GetOpportunityMetricsReducer,
    StallOpportunitiesReducer,
    FunnelChartOpportunityReducer,
    OpportunityDetailsReducer,
    capabilitiesReducer,
    editOpportunityReducer,

    GetFinanceReducer,
    ImportFinanceReducer,
    WipeImportFinanceReducer,
    FinanceNccTableReducer,

    AssignUserAdminReducer,
    DismissUserAdminReducer,
    PeopleApiReducer,

    SearchFilterReducer,

    GetEmployeesReducer,
    ImportEmployeesReducer,
    PrepareImportEmployeesReducer,

    UpdateEmployeeExclusionReducer,
};

export default RoutReducer;
