import {
    SEARCH_OPPORTUNITIES_ACTIONS,
    CASE_STATUS_CHART_ACTIONS,
    PROJECTS_WORKING_WEEKS_ACTIONS,
    CREATE_OPPORTUNITY_ACTIONS,
    DOWNLOAD_OPPORTUNITIES_EXCEL,
    UPDATE_OPPORTUNITY_ACTIONS,
    AVG_WEEKLY_RATE_ACTIONS,
    STALL_OPPORTUNITIES_ACTIONS,
    GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS,
    GRT_OPPORTUNITY_DETAILS_ACTIONS,
    TOTAL_DURATION_IN_WEEKS,
    GET_CAPABILITIESY_CHART_ACTIONS,
    GET_OPPORTUNITY_METRICS,
    EDIT_OPPORTUNITY_ACTIONS,
} from "../ActionsTypes";
import { get, post, downloadFile } from "../../http_methods/api";
import { BACKEDN_URL } from "../../baseURL";
import TokenStorage from "../../http_methods/TokenStorage";

export const opportunitiesList = (parameters) => async (dispatch) => {
    dispatch({ type: SEARCH_OPPORTUNITIES_ACTIONS.REQUEST });

    const filteredParams = Object.fromEntries(Object.entries(parameters).filter(([_, value]) => value !== null && value !== "" && value.length > 0));

    try {
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/search`, filteredParams);
        dispatch({ type: SEARCH_OPPORTUNITIES_ACTIONS.SUCCESS, payload: result });
    } catch (err) {
        dispatch({ type: SEARCH_OPPORTUNITIES_ACTIONS.FAILED, payload: err });
    }
};

export const caseStatusChartData = (parameters) => async (dispatch) => {
    dispatch({ type: CASE_STATUS_CHART_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();

        const params = { ...parameters, email: email };
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/case_status_chart`, params);
        dispatch({ type: CASE_STATUS_CHART_ACTIONS.SUCCESS, payload: result });
    } catch (err) {
        dispatch({ type: CASE_STATUS_CHART_ACTIONS.FAILED, payload: err });
    }
};
export const projectWorkingWeeks = (parameters) => async (dispatch) => {
    dispatch({ type: PROJECTS_WORKING_WEEKS_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { ...parameters, email: email };
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/projects/charts`, params);
        dispatch({
            type: PROJECTS_WORKING_WEEKS_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: PROJECTS_WORKING_WEEKS_ACTIONS.FAILED, payload: err });
    }
};

export const createOpportunityAction = (opportunityData) => async (dispatch) => {
    dispatch({ type: CREATE_OPPORTUNITY_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { email: email };
        const data = await post(`${BACKEDN_URL}/api/v1/opportunities/create`, opportunityData, params);
        // @ts-ignore

        if (data.status < 300) {
            dispatch({ type: CREATE_OPPORTUNITY_ACTIONS.SUCCESS, payload: true });
        } else {
            dispatch({ type: CREATE_OPPORTUNITY_ACTIONS.FAILED });
        }
    } catch (error) {
        dispatch({ type: CREATE_OPPORTUNITY_ACTIONS.FAILED, payload: error });
    }
};

export const updateOpportunityAction = (id_opportunity, opportunityUpdatedObject) => async (dispatch) => {
    dispatch({ type: UPDATE_OPPORTUNITY_ACTIONS.REQUEST });
    try {
        const params = {
            opportunity_data: opportunityUpdatedObject,
        };
        const result = await post(`${BACKEDN_URL}/api/v1/opportunities/edit/${id_opportunity}`, params);
        // @ts-ignore
        if (result.status === 200) {
            dispatch({ type: UPDATE_OPPORTUNITY_ACTIONS.SUCCESS, payload: true });
        } else {
            dispatch({ type: UPDATE_OPPORTUNITY_ACTIONS.FAILED });
        }
    } catch (error) {}
};

export const editOpportunityAction = (id_opportunity, opportunityUpdatedObject) => async (dispatch) => {
    dispatch({ type: EDIT_OPPORTUNITY_ACTIONS.REQUEST });
    try {
        const params = {
            opportunity_data: opportunityUpdatedObject,
        };
        const result = await post(`${BACKEDN_URL}/api/v1/opportunities/edit/${id_opportunity}`, params);

        // @ts-ignore
        if (result.status === 200) {
            dispatch({
                type: EDIT_OPPORTUNITY_ACTIONS.SUCCESS,
                payload: result.data,
            });
        } else {
            dispatch({ type: EDIT_OPPORTUNITY_ACTIONS.FAILED });
        }
    } catch (error) {}
};

export const avgWeeklyRunRateAction = (parameters) => async (dispatch) => {
    dispatch({ type: AVG_WEEKLY_RATE_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { email: email };
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/avg_weekly_run_rate`, { ...parameters, ...params });
        dispatch({
            type: AVG_WEEKLY_RATE_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: AVG_WEEKLY_RATE_ACTIONS.FAILED, payload: err });
    }
};

export const totalDurationInWeeksAction = (parameters) => async (dispatch) => {
    dispatch({ type: TOTAL_DURATION_IN_WEEKS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { email: email };
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/dashboard/total_time`, { ...parameters, ...params });
        dispatch({
            type: TOTAL_DURATION_IN_WEEKS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: TOTAL_DURATION_IN_WEEKS.FAILED, payload: err });
    }
};

export const stallOpportunitiesAction = (parameters) => async (dispatch) => {
    dispatch({ type: STALL_OPPORTUNITIES_ACTIONS.REQUEST });
    const email = TokenStorage.getEmail();
    const queryParams = {
        ...parameters,
        email,
        from_period_date: parameters?.from_period_date?.toString(),
        to_period_date: parameters?.to_period_date?.toString(),
        locations: parameters?.locations,
        billing_codes: parameters?.billing_codes,
        letters: parameters?.letters,
        users: parameters?.users,
    };
    try {
        const result = await post(`${BACKEDN_URL}/api/v1/opportunities/stall_opportunities`, queryParams);
        dispatch({
            type: STALL_OPPORTUNITIES_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: STALL_OPPORTUNITIES_ACTIONS.FAILED, payload: err });
    }
};

export const getFunnelChartDataAction = (parameters) => async (dispatch) => {
    dispatch({ type: GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { email: email };

        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/dashboard/opp_status_breakdown`, { ...parameters, ...params });

        dispatch({
            type: GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({
            type: GET_FUNNEL_OPPORTUNITY_CHART_ACTIONS.FAILED,
            payload: err,
        });
    }
};

export const getOpportunityDetails = (id) => async (dispatch) => {
    dispatch({ type: GRT_OPPORTUNITY_DETAILS_ACTIONS.REQUEST });
    try {
        const email = TokenStorage.getEmail();
        const params = { email: email };
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/details/${id}`, { ...params });
        dispatch({
            type: GRT_OPPORTUNITY_DETAILS_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: GRT_OPPORTUNITY_DETAILS_ACTIONS.FAILED, payload: err });
    }
};

export const get_capabilities = (parameters) => async (dispatch) => {
    dispatch({ type: GET_CAPABILITIESY_CHART_ACTIONS.REQUEST });
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    try {
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/capabilities`, params);
        dispatch({
            type: GET_CAPABILITIESY_CHART_ACTIONS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: GET_CAPABILITIESY_CHART_ACTIONS.FAILED, payload: err });
    }
};

export const get_metrics = (parameters) => async (dispatch) => {
    dispatch({ type: GET_OPPORTUNITY_METRICS.REQUEST });
    const email = TokenStorage.getEmail();
    const params = { ...parameters, email: email };
    try {
        const result = await get(`${BACKEDN_URL}/api/v1/opportunities/dashboard/metrics`, params);
        dispatch({
            type: GET_OPPORTUNITY_METRICS.SUCCESS,
            payload: result,
        });
    } catch (err) {
        dispatch({ type: GET_OPPORTUNITY_METRICS.FAILED, payload: err });
    }
};

//----------------Download opportunities--as csv----------------------//
export const downloadOpportunitiesAction = () => async (dispatch) => {
    try {
        const email = TokenStorage.getEmail();
        const data = await downloadFile(`${BACKEDN_URL}/api/v1/opportunities/download`, "opportunities");
        dispatch({ type: DOWNLOAD_OPPORTUNITIES_EXCEL.SUCCESS });
    } catch (error) {
        dispatch({ type: DOWNLOAD_OPPORTUNITIES_EXCEL.FAILED });
    }
};
